import React, {
  useRef, useEffect,
  useState,
} from 'react';
import { AiOutlineClockCircle, AiFillDelete } from 'react-icons/ai';
import { format } from 'date-fns';
// import { useDispatch } from 'react-redux';
import { useDispatch } from 'react-redux';
import Modal from '../../../../lib/ModalComponents/Modal';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import pen from '../../../../assets/images/icons/pen.svg';
import {
  generateKey, getHourFromDate, isImage, isValidDate,
} from '../../../../utils/utils';

import styles from './Guest.module.scss';
import HotelModal from './HotelModal/HotelModal';
import { API_URL } from '../../../../actions';
import MealModal from './MealModal/MealModal';
import QuizModal from './QuizModal/QuizModal';
// import { InputText } from '../../../../lib/HooksFormFields';
// import InputFile from '../../../InputFile/InputFile';
import { deleteFileAction } from '../../../../actions/filesActions';
import InputFile from '../../../InputFile/InputFile';

export default function Guest({
  watch,
  setValue,
  activeForm,
  submit,
  // control,
}) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [editHotel, setEditHotel] = useState();
  const [editMeal, setEditMeal] = useState();
  const [editQuiz, setEditQuiz] = useState();
  const hotels = watch('accommodation.hotels');
  const meals = watch('accommodation.meals');
  // const quiz = watch('quiz');
  const guests = watch('guestsFile');
  const guestsRoomingList = watch('guestsRoomingList');

  function deleteGuestsFile(id) {
    deleteFileAction(dispatch, id);
    setValue('guestsFile', null);
    submit({
      ...watch(),
      guests: null,
    });
  }

  function deleteGuestsRoomingListFile(id) {
    deleteFileAction(dispatch, id);
    setValue('guestsRoomingList', null);
    submit({
      ...watch(),
      guestsRoomingList: null,
    });
  }

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <BoxPanel label={'INVITés'}>
            <section>
            <div className={`${styles['file-section']}`}>
                <label>Invités</label>
                <div className={`${styles.list}`}>
                  {guests?._id
                    ? <div className={styles.file}>
                      <a href={`${API_URL}/${ guests.path}`} target='_blank' rel="noreferrer">
                        {guests?.name || 'fichier'}
                      </a>
                      <button
                        type="button"
                        onClick={() => deleteGuestsFile(guests._id)}
                        >
                        <AiFillDelete size={20} />
                      </button>
                    </div>
                    : <div className={styles['input-file']}>
                      <InputFile
                        activeFile={null}
                        label="Ajouter un excel invité"
                        handleChange={(fileId) => submit({
                          ...watch(),
                          guestsFile: fileId,
                        })}
                        name={'guestsFile'}
                        />
                    </div>
                  }
                </div>
              </div>
              <div className={`${styles['file-section']}`}>
                <label>Rooming liste</label>
                <div className={`${styles.list}`}>
                  {guestsRoomingList?._id
                    ? <div className={styles.file}>
                      <a href={`${API_URL}/${ guestsRoomingList.path}`}
                        target='_blank' rel="noreferrer">
                        {guestsRoomingList?.name || 'fichier'}
                      </a>
                      <button
                        type="button"
                        onClick={() => deleteGuestsRoomingListFile(guestsRoomingList._id)}
                        >
                        <AiFillDelete size={20} />
                      </button>
                    </div>
                    : <div className={styles['input-file']}>
                      <InputFile
                        activeFile={null}
                        label="Ajouter la rooming liste"
                        handleChange={(fileId) => submit({
                          ...watch(),
                          guestsRoomingList: fileId,
                        })}
                        name={'guestsRoomingList'}
                        />
                    </div>
                  }
                </div>
              </div>
              {/* <div className={styles['input-block']}>
                <label>Lien vers Atrium</label>
                <div className={styles.input}>
                  <InputText
                    name='atrium'
                    control={control}
                    className={'primary'}
                    rules={{ }}
                    placeholder={'Url de l’évènement'}
                  />
                </div>
              </div> */}
            </section>
          </BoxPanel>
          <BoxPanel label={'HOTEL'}>
            <div className={styles.list}>
              {hotels?.map((h, i) => (
                <div key={generateKey('hotel', i)} className={styles.card}>
                  <div className={styles.content}>
                    <p><b>{h.name}</b></p>
                    <div className={styles.address}>
                      <label>{h.address}</label>
                    </div>
                    <div className={styles.hour}>
                      <label><AiOutlineClockCircle />Horaire checkin</label> <b>{h.checkin}</b>
                    </div>
                    <div className={styles.hour}>
                      <label><AiOutlineClockCircle />Horaire checkout</label> <b>{h.checkout}</b>
                    </div>
                    <button
                      type="button"
                      onClick={() => setEditHotel({ ...h, index: i })}
                    >
                      <img src={pen} alt="edit" />
                    </button>
                  </div>
                  {isImage(h?.plan?.name)
                    && <div className={styles.plan}>
                        <a href={[API_URL, '/', h?.plan?.path].join('')} target='_blank' rel="noreferrer">
                        <img src={[API_URL, '/', h?.plan?.path].join('')} alt={h.plan?.name} />
                      </a>
                    </div>
                  }
                </div>
              ))}
            </div>
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setEditHotel({})}
            >
              Ajouter un hotel
            </button>
          </BoxPanel>
          <BoxPanel label={'REPAS'}>
            <div className={styles.meals}>
              {meals?.map((m, i) => (
                <div
                  key={generateKey('repas', i)}
                  className={styles.item}
                  onClick={() => setEditMeal({ ...m, index: i })}
                >
                  <p>{m.type}</p>
                  <p>{isValidDate(m.date) && format(new Date(m.date), 'dd/MM/yyyy')} {getHourFromDate(m.date)}</p>
                  <p>{m.name}</p>
                </div>
              ))}
            </div>
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setEditMeal({})}
            >
              Ajouter un repas
            </button>
          </BoxPanel>
          {/* <BoxPanel label={'QUESTIONNAIRE'}>
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setEditQuiz(quiz || [])}
            >
              {quiz?.length > 0 ? 'Modifier le questionnaire' : 'Créer un questionnaire' }
            </button>
          </BoxPanel> */}
        </div>
      </div>
      <Modal toggle={() => setEditHotel(false)} open={editHotel}>
        {editHotel
          && <HotelModal
            hotel={editHotel}
            hotels={hotels}
            close={() => setEditHotel(false)}
            submit={(array) => submit({
              ...watch(),
              accommodation: {
                ...watch('accommodation'),
                hotels: array,
              },
            })}
            changeValue={(array) => setValue('accommodation.hotels', array)}
          />
        }
      </Modal>
      <Modal toggle={() => setEditMeal(false)} open={editMeal}>
        {editMeal
          && <MealModal
            meal={editMeal}
            meals={meals}
            close={() => setEditMeal(false)}
            changeValue={(array) => setValue('accommodation.meals', array)}
          />
        }
      </Modal>
      <Modal toggle={() => setEditQuiz(false)} open={editQuiz}>
        {editQuiz
          && <QuizModal
            quiz={editQuiz}
            close={() => setEditQuiz(false)}
            changeValue={(array) => submit({
              ...watch(),
              quiz: array,
            })}
          />
        }
      </Modal>
    </div>
  );
}
