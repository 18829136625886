import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronBack, IoSave } from 'react-icons/io5';
import { BiLink } from 'react-icons/bi';

import {
  getTherapeutiAreaAction,
  putTherapeuticAreaAction,
  postTherapeuticAreaAction,
} from '../../../actions/therapeuticAreasActions';

import {
  Textarea,
  InputText,
} from '../../../lib/HooksFormFields';
import Button from '../../../lib/HooksFormFields/Button';

import pen from '../../../assets/images/icons/pen-white.svg';
import styles from './EditTherapeuticArea.module.scss';

export default function EditTherapeuticArea({
  isCreate = false,
}) {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    handleSubmit,
  } = useForm();
  const { therapeuticArea } = useSelector((state) => state.therapeuticAreas);

  const [isEdit, setIsEdit] = useState(false);

  const getTherapeuticArea = useCallback(() => {
    getTherapeutiAreaAction(dispatch, id);
  }, [dispatch, id]);

  function submitTherapeuticArea(values) {
    if (therapeuticArea?._id) {
      putTherapeuticAreaAction(dispatch, therapeuticArea._id, values);
    } else {
      postTherapeuticAreaAction(dispatch, values);
    }
  }

  useEffect(() => {
    if (isCreate) {
      setIsEdit(true);
    }
    if (id) {
      getTherapeuticArea();
    }
  }, []);

  useEffect(() => {
    if (therapeuticArea?.name) {
      setValue('name', therapeuticArea.name);
    }
    if (therapeuticArea?.description) {
      setValue('description', therapeuticArea.description);
    }
  }, [therapeuticArea]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{therapeuticArea?.name
            ? <span>AIRES THÉRAPEUTIQUES / {therapeuticArea.name}</span>
            : <span>NOUVEL AIRE THERAPEUTIQUE</span>
            }
          </h2>
          <Link to={'/therapeuticarea'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          {isEdit
            ? <Button
              handleClick={handleSubmit(submitTherapeuticArea)}
              className={styles.edit}>
              <span>Enregistrer la fiche</span>
              <IoSave size={28} />
            </Button>

            : <Button
              handleClick={() => setIsEdit(!isEdit)}
              className={styles.edit}>
              <span>EDITER LA FICHE</span>
              <img src={pen} alt="edit-user"/>
            </Button>
          }
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={`${styles.col}`}>
          <section>
            <h3>Information</h3>
            <div className={styles['input-list']}>

              <div className={styles.block}>
                <label>Nom</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='name'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {therapeuticArea?.name}
                    </p>
                    || <span>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Description</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <Textarea
                          name='description'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {therapeuticArea?.description}
                    </p>
                    || <span>non complété</span>
                  }
                </div>
              </div>

            </div>
          </section>
          {therapeuticArea?.studies?.length > 0
            && <section className={styles.border}>
            <label>Études cliniques</label>
            <div className={`${styles.list} ${styles.links}`}>
              {therapeuticArea?.studies?.map((p) => {
                // eslint-disable-next-line prefer-template
                const url = process.env.REACT_APP_CLINOCTA_URL + '/edit-study/' + p._id;
                return (
                  <a key={p._id} href={url} target='_blank' rel="noreferrer" className={styles.link}>
                    <span>{p.name}</span><BiLink size={18}/>
                  </a>
                );
              })}
            </div>
          </section>
          }
          {therapeuticArea?.studies?.length > 0
            && <section className={styles.border}>
            <label>Produits</label>
            <div className={`${styles.list} ${styles.links}`}>
              {therapeuticArea?.drugs?.map((d) => {
                // eslint-disable-next-line prefer-template
                const url = '/product/' + d._id;
                return (
                  <a key={d._id} href={url} className={styles.link}>
                    <span>{d.name}</span><BiLink size={18}/>
                  </a>
                );
              })}
            </div>
          </section>
          }
        </div>

      </main>
    </form>
  );
}
