import React, { useRef, useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { Textarea } from '../../../../lib/HooksFormFields';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import FormList from '../../../FormList/FormList';

import styles from './Pharmaco.module.scss';

export default function Pharmaco({
  control,
  activeForm,
  setValue,
  watch,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <BoxPanel
          label={'Propriétés pharmacodynamiques'}
          >
          <div className={styles.row}>
            <div className={styles.text}>
              <div className={styles['form-group']}>
                <label>Description</label>
                <div className={styles.value}>
                  <Textarea
                    name='property.dynamic.description'
                    control={control}
                    className={'primary'}
                  />
                </div>
              </div>
            </div>
          </div>
        </BoxPanel>
        <BoxPanel
          label={'propriétés pharmacocinétiques'}
          >
          <div className={styles.list}>
            {watch('property.kinetic.halfLife')?.map((d) => <div
              key={d._id}
              className={styles.item}
              >
              <p className={styles.bold}>{d?.type}</p>
              <p>{d.method}</p>
              <p>{d?.value} {d?.unit}</p>
              <button
                type="button"
                onClick={() => setValue('property.kinetic.halfLife', watch('property.kinetic.halfLife').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('property.kinetic.halfLife') || []}
            handleChange={(arr) => setValue('property.kinetic.halfLife', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'Type',
                key: 'type',
                type: 'text',
              },
              {
                label: 'Méthode',
                key: 'method',
                type: 'text',
              },
              {
                label: 'Valeur',
                key: 'value',
                type: 'number',
              },
              {
                label: 'Unité',
                key: 'unit',
                type: 'text',
              },
            ]}
            />

          <label className={styles.bold}>Récupération</label>
          <div className={styles.list}>
            {watch('property.kinetic.recuperation')?.map((d) => <div
              key={d._id}
              className={styles.item}
              >
              <p className={styles.bold}>{d?.type}</p>
              <p>{d.method}</p>
              <p>{d?.value} {d?.unit}</p>
              <button
                type="button"
                onClick={() => setValue('property.kinetic.recuperation', watch('property.kinetic.recuperation').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          {watch('property.kinetic.recuperation')?.length === 0
            && <FormList
              list={watch('property.kinetic.recuperation') || []}
              handleChange={(arr) => setValue('property.kinetic.recuperation', arr)}
              btn="Ajouter"
              model={[
                {
                  label: 'Type',
                  key: 'type',
                  type: 'text',
                },
                {
                  label: 'Méthode',
                  key: 'method',
                  type: 'text',
                },
                {
                  label: 'Valeur',
                  key: 'value',
                  type: 'number',
                },
                {
                  label: 'Unité',
                  key: 'unit',
                  type: 'text',
                },
              ]}
              />
          }
        </BoxPanel>
      </main>
    </div>
  );
}
