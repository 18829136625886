import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
} from '../../../../../lib/HooksFormFields';
import Form from '../Form/Form';
import styles from './QuizModal.module.scss';

export default function QuizModal({
  quiz, changeValue, close,
}) {
  const {
    watch,
    setValue,
  } = useForm({
    defaultValues: { quiz },
  });

  const form = watch('quiz');

  function handleChange() {
    changeValue(form);
    close();
  }

  function deleteQuizz() {
    changeValue([]);
    setValue('quiz', []);
    close();
  }

  return (
    <div className={styles.container}>
      <h3>{quiz ? 'Modifier le questionnaire' : 'Nouveau questionnaire'}</h3>
      <Form
        form={form}
        submit={(array) => setValue('quiz', array)}
      />
      <div className={styles.btns}>
        <Button
          className={styles.edit}
          type='button'
          handleClick={() => handleChange()}
        >
          Valider
        </Button>
        <button
          type="button"
          className={styles.delete}
          onClick={() => deleteQuizz()}
        >
          Supprimer
        </button>
      </div>
    </div>
  );
}
