import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button, InputDate, ErrorField, InputText, StyledSelect,
} from '../../../../../lib/HooksFormFields';
import { isValidDate } from '../../../../../utils/utils';
import styles from './MealModal.module.scss';

export default function MealModal({
  meal, meals, changeValue, close,
}) {
  const date = isValidDate(meal?.date) ? new Date(meal.date) : new Date();
  const time = date ? `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}` : '';
  const {
    control,
    setValue,
    formState: {
      errors,
    },
    watch,
  } = useForm({
    defaultValues: {
      ...meal,
      date,
      time,
    },
  });

  const form = watch();
  const options = ['Diner', 'Déjeuner', 'Petit-déjeuner'].map((v) => ({ value: v, label: v }));

  useEffect(() => {
    const newTime = form?.time?.split(':');
    if (form?.time) {
      const newDate = new Date(form.date);
      newDate.setHours(newTime[0]);
      newDate.setMinutes(newTime[1]);
      console.log(new Date());
      setValue('date', newDate);
    }
  }, [form?.time]);

  function handleChange() {
    const updatedmeals = [...meals];
    if (meal?.index >= 0) {
      updatedmeals[meal?.index] = form;
    } else {
      updatedmeals.push(form);
    }
    changeValue(updatedmeals);
    close();
  }

  function deleteMeal() {
    changeValue(meals.filter((h, i) => i !== meal?.index));
    close();
  }

  return (
    <div className={styles.container}>
      <h3>{meal?.name || 'Ajouter un repas'}</h3>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Nom</label>
          <div className={styles.input}>
            <InputText
              name='name'
              control={control}
              className={'primary'}
              rules={{ required: 'Le nom est requis' }}
              placeholder={'Nom'}
            />
            {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
          </div>
        </div>
        <div className={styles['input-block']}>
          <label>Adresse</label>
          <div className={styles.input}>
            <InputText
              name='address'
              control={control}
              className={'primary'}
              rules={{ required: 'Le nom est requis' }}
              placeholder={'Nom'}
            />
            {errors?.address?.message && <ErrorField message={errors?.address?.message} />}
          </div>
        </div>
      </div>
      <div className={`${styles.row} ${styles['col-3']}`}>
        <div className={styles['input-block']}>
          <label>Repas</label>
          <div className={styles.input}>
            <StyledSelect
              name="type"
              control={control}
              value={options.find((o) => o.value === watch('type'))}
              onChange={(opt) => setValue('type', opt.value)}
              placeholder="Séléctionner un repas..."
              options={options}
            />
          </div>
        </div>
        <div className={styles['input-block']}>
          <label>Date</label>
          <div className={styles.input}>
            <InputDate
              name="date"
              control={control}
              type="datetime-local"
              placeholder="Selectionner une date..."
            />
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          className={styles.edit}
          type='button'
          handleClick={() => handleChange()}
        >
          Valider
        </Button>
        {meal?.index >= 0
        && <button
          type="button"
          className={styles.delete}
          onClick={() => deleteMeal(meals.filter((h, i) => i !== meal?.index))}
        >
          Supprimer
        </button>
        }
      </div>
    </div>
  );
}
