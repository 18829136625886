import {
  getData, putData, postData,
} from './index';

import {
  ERROR_TOOL,
  GET_TOOLS,
  GET_TOOL,
  PUT_TOOL,
  POST_TOOL,
  SET_TOAST,
  GET_TOOLS_DIVISIONS,
  GET_TOOLS_TYPES,
} from './types';

export async function getToolsAction(dispatch) {
  const url = '/tool';
  let tools;

  await getData(ERROR_TOOL, url, dispatch, true).then((response) => {
    tools = response.tools;
  });

  dispatch({
    type: GET_TOOLS,
    payload: tools,
  });
}

export async function getToolsDivisionsAction(dispatch) {
  const url = '/tool/list/division';
  let division;

  await getData(ERROR_TOOL, url, dispatch, true).then((response) => {
    division = response.divisions;
  });

  dispatch({
    type: GET_TOOLS_DIVISIONS,
    payload: division,
  });
}

export async function getToolsTypesAction(dispatch) {
  const url = '/tool/list/type';
  let type;

  await getData(ERROR_TOOL, url, dispatch, true).then((response) => {
    type = response;
  });

  dispatch({
    type: GET_TOOLS_TYPES,
    payload: type,
  });
}

export async function getToolAction(dispatch, id) {
  const url = `/tool/${id}`;
  let tool;

  await getData(ERROR_TOOL, url, dispatch, true).then((response) => {
    tool = response.tool;
  });

  dispatch({
    type: GET_TOOL,
    payload: tool,
  });
}

export async function postToolAction(dispatch, data) {
  const url = '/tool';
  let tool;

  await postData(POST_TOOL, ERROR_TOOL, url, dispatch, data, true).then((response) => {
    tool = response.tool;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'outil a été creé",
    },
  });

  dispatch({
    type: POST_TOOL,
    payload: tool,
  });
}

export async function putToolAction(dispatch, id, data) {
  const url = `/tool/${id}`;
  let tool;

  await putData(PUT_TOOL, ERROR_TOOL, url, dispatch, data, true).then((response) => {
    tool = response.tool;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'outil a été modifié",
    },
  });

  dispatch({
    type: PUT_TOOL,
    payload: tool,
  });
}
