import React, {
  useRef, useEffect,
  useState,
  useCallback,
} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useForm } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { StyledSelect } from '../../../../../lib/HooksFormFields';
import { generateKey } from '../../../../../utils/utils';

import styles from './Form.module.scss';

const FormField = ({ field, deleteField }) => {
  const {
    control,
  } = useForm();
  if (field.type !== 'list') {
    return (
      <div className={styles.row}>
        <div className={`${styles.block}`}>
          <label>{field.label}</label>
          <div className={styles.value}>
            <input className={styles.input} type={field.type} />
          </div>
        </div>
        <div className={styles.actions}>
          <button
            type="button"
            onClick={() => deleteField()}
          >
            <AiFillDelete />
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.row}>
      <div className={`${styles.block}`}>
        <label>{field.label}</label>
        <div className={styles.value}>
          <StyledSelect
            control={control}
            name={field.label}
            options={field.options || []}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={() => deleteField()}
        >
          <AiFillDelete />
        </button>
      </div>
    </div>
  );
};

const Option = ({
  option, index, handleChange, deleteOption,
}) => (
    <div className={`${styles.row}`}>
      <div className={styles.block}>
        <label>Element {index}</label>
        <div className={styles.value}>
          <input className={styles.input} type="text" value={option.value} onChange={(e) => handleChange(e.target.value)} />
        </div>
      </div>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={() => deleteOption()}
        >
          <AiFillDelete />
        </button>
      </div>
    </div>
);

export default function Form({
  submit, form = [],
}) {
  const containerRef = useRef();
  const fieldTypes = [
    { value: 'text', label: 'Champs texte' },
    { value: 'number', label: 'Champs chiffre' },
    { value: 'date', label: 'Date' },
    { value: 'list', label: 'Liste' },
  ];

  const [field, setField] = useState({
    label: '',
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      form,
      result.source.index,
      result.destination.index,
    );

    submit(items.map((f, i) => ({ ...f, position: i })));
  }

  function handleChangeOption(value, i) {
    const { options } = field;
    options[i] = { value, label: value };
    setField({ ...field, options });
  }

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (form) {
      setField({ label: '' });
    }
  }, [form]);

  const grid = 8;
  const radius = 8;

  const getListStyle = useCallback((isDraggingOver) => ({
    background: isDraggingOver ? '#E6E6FD' : 'transparent',
    padding: grid,
    borderRadius: radius,
    width: '100%',
  }), [grid, radius]);

  const getItemStyle = useCallback((isDragging, draggableStyle) => {
    const backgroundColor = isDragging ? '#BAB1EA' : null;
    return {
      ...draggableStyle,
      userSelect: 'none',
      padding: `${grid}px`,
      borderRadius: radius,
      left: draggableStyle.offsetLeft,
      top: draggableStyle.offsetTop,
      backgroundColor,
    };
  }, [grid, radius]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.box}>
              <section>
                <h3>Formulaire</h3>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        ignoreContainerClipping={true}
                        className={styles['form-list']}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {form?.length > 0 && form?.map((f, i) => ({ ...f, id: generateKey('field', i) })).map((f, index) => (
                          <Draggable key={f.id} draggableId={f.id} index={index}>
                            {(providedItem, snapshotItem) => (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                className={styles.drag}
                                style={getItemStyle(
                                  snapshotItem.isDragging,
                                  providedItem.draggableProps.style,
                                )}
                              >
                                <FormField
                                  field={f}
                                  deleteField={() => submit(
                                    form.filter((item) => item.label !== f.label),
                                  )}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </section>
              <div className={styles['input-list']}>
                <div className={`${styles['tags-list']}`}>
                  {fieldTypes.map((f) => (
                    <button
                      type='button'
                      onClick={() => setField({
                        ...field,
                        type: f.value,
                        label: '',
                        position: form.length + 1,
                        options: f.value !== 'list' ? null : [{ value: '', label: '' }],
                      })}
                      className={field.type === f.value ? styles.selected : ''}
                      key={f.value}
                    >
                      {f.label}
                    </button>
                  ))}
                </div>
              </div>
              {field.type
              && <div className={styles['create-field']}>
                <div className={styles['input-list']}>
                  <div className={`${styles.block}`}>
                    <label>{field.type === 'list' ? 'Nom de la liste' : 'Nom du champs'}</label>
                    <div className={styles.value}>
                      <input className={styles.input} type="text" value={field.label} onChange={(e) => setField({ ...field, label: e.target.value })} />
                    </div>
                  </div>
                  {field.type === 'list'
                    && field?.options?.map((opt, i) => <Option
                      key={i}
                      index={i + 1}
                      option={opt}
                      handleChange={(value) => handleChangeOption(value, i)}
                      deleteOption={() => setField({
                        ...field,
                        options: field.options.filter((o, index) => index !== i),
                      })}
                    />)
                  }
                  {field.type === 'list'
                    && <button
                      type='button'
                      onClick={() => setField({ ...field, options: [...field.options, { value: '', label: '' }] })}
                      className={`${styles.add}`}
                    >
                      + Ajouter un élément
                    </button>
                  }
                </div>
                <div className={styles['btn-container']}>
                  <button
                    type='button'
                    onClick={() => setField({ ...field, type: null, options: null })}
                    className={`${styles.btn}`}
                  >
                    Annuler
                  </button>
                  <button
                    type='button'
                    onClick={() => submit([...form, field])}
                    className={`${styles.btn} ${styles.valid}`}
                  >
                    valider
                  </button>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
    </div>
  );
}
