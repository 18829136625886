import React from 'react';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function TherapeuticRow({
  styles,
  therapeutic,
  index,
}) {
  return (
    <Link to={`/therapeuticarea/${therapeutic._id}`} className={styles.row} style={{ animationDelay: `${0.08 * index}s` }}>
      <div className={styles.col}>
        <p className={styles.bold}>{therapeutic?.name}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
