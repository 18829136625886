import {
  getData, postData, putData,
} from './index';

import {
  ERROR_PROJECT,
  GET_PROJECTS,
  GET_PROJECT,
  POST_PROJECT,
  PUT_PROJECT,
  SET_TOAST,
  GET_PROJECTS_LISTS,
} from './types';

export async function getProjectsAction(dispatch) {
  const url = '/project';
  let projects;

  await getData(ERROR_PROJECT, url, dispatch, true).then((response) => {
    projects = response.projects.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  });

  dispatch({
    type: GET_PROJECTS,
    payload: projects,
  });
}

export async function getProjectAction(dispatch, id) {
  const url = `/project/${id}`;
  let project;

  await getData(ERROR_PROJECT, url, dispatch, true).then((response) => {
    project = response.project;
  });

  dispatch({
    type: GET_PROJECT,
    payload: project,
  });
}

export async function getProjectListsAction(dispatch) {
  const url = '/project/list';
  let lists;

  await getData(ERROR_PROJECT, url, dispatch, true).then((response) => {
    lists = response.lists;
  });

  function getUserLabel(p) {
    return `${p.firstName} ${p.lastName}`;
  }

  const tasksUsers = lists?.users.map((u) => ({ value: u._id, label: getUserLabel(u.profile) }));

  lists.tasksUsers = tasksUsers;

  dispatch({
    type: GET_PROJECTS_LISTS,
    payload: lists,
  });
}

export async function postProjectAction(dispatch, data) {
  const url = '/project';
  let project;

  await postData(POST_PROJECT, ERROR_PROJECT, url, dispatch, data, true).then((response) => {
    project = response.project;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le projet a été creé',
    },
  });

  dispatch({
    type: POST_PROJECT,
    payload: project,
  });
}

export async function putProjectAction(dispatch, id, data) {
  const url = `/project/${id}`;
  let project;

  await putData(PUT_PROJECT, ERROR_PROJECT, url, dispatch, data, true).then((response) => {
    project = response.project;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le projet a été modifié',
    },
  });

  dispatch({
    type: PUT_PROJECT,
    payload: project,
  });
}
