import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { SET_USERS_FILTERS } from '../../../actions/types';
import { rolesOptions, typesOptions } from '../../../constants/constants';
import TagsList from '../../TagsList/TagsList';
import styles from './UsersFilters.module.scss';

export default function UsersFilters({
  close,
  isActive,
}) {
  const { filters, products } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  function handleChangeFilters(key, value) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        page: 1,
        [key]: value,
      },
    });
  }

  return (
    <div className={`${styles.container} ${isActive ? styles['is-active'] : ''}`}>
      <div className={styles.title}>
        <h2>Filtres</h2>
        <button
          onClick={() => close()}
          className={styles.close}>
          <IoMdClose size={30} />
        </button>
      </div>
      <div className={styles.filters}>
        <label>Recherche</label>
        <div className={styles.search}>
          <input
            value={search}
            placeholder={'Votre recherche'}
            onChange={(e) => setSearch(e.target.value)}
            />
          <button
            className={`${search ? styles['is-search'] : ''}`}
            onClick={() => handleChangeFilters('search', search)}
            >
            <IoSearch size={20} />
          </button>
        </div>
        <div className={styles.tags}>
          <TagsList
            label={'Rôles'}
            options={rolesOptions}
            selected={filters.roles}
            isFilter
            handleChange={(array) => handleChangeFilters('roles', array)}
            isEdit
            />
        </div>
        <div className={styles.tags}>
          <TagsList
            label={'Typologies'}
            options={typesOptions}
            selected={filters.types}
            isFilter
            handleChange={(array) => handleChangeFilters('types', array)}
            isEdit
            />
        </div>
        <div className={styles.tags}>
          <TagsList
            label={'Produits numériques'}
            options={products?.map((p) => ({ value: p._id, label: p.title }))}
            selected={filters.tools}
            isFilter
            handleChange={(array) => handleChangeFilters('tools', array)}
            isEdit
            />
        </div>

      </div>
    </div>
  );
}
