import React, {
  useRef, useEffect,
} from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import {
  StyledSelect,
} from '../../../../lib/HooksFormFields';
import { concatName } from '../../../../utils/utils';
import FormList from '../../../FormList/FormList';

import styles from './Actors.module.scss';

export default function Actors({
  control, watch, submit, activeForm, setValue, isOctapharma,
}) {
  const containerRef = useRef();
  const { octapharmaUsers, allUsers } = useSelector((state) => state.users);
  const { lists } = useSelector((state) => state.projects);

  const doctorsOptions = lists?.doctors.map((d) => ({
    value: d._id,
    label: concatName(d.profile.firstName, d.profile.lastName),
  }));

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>PILOTAGE</h3>
              <div className={styles['input-block']}>
                <label>Piloté par</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="coordinator"
                    control={control}
                    value={allUsers?.find((o) => o.label === watch('coordinator'))}
                    onChange={(opt) => setValue('coordinator', opt.label)}
                    placeholder="Selectionner un coordinateur..."
                    options={allUsers}
                  />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Chef de projet</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="manager"
                    control={control}
                    value={allUsers?.find((o) => o.label === watch('manager'))}
                    onChange={(opt) => setValue('manager', opt.label)}
                    placeholder="Selectionner un chef de projet..."
                    options={allUsers}
                  />
                </div>
              </div>
              {isOctapharma
                && <div className={styles['input-block']}>
                  <label>Chef de projet interne</label>
                  <div className={styles.input}>
                    <StyledSelect
                      name="internManager"
                      control={control}
                      value={octapharmaUsers?.find((o) => o.value === watch('internManager') || o.value === watch('internManager')?._id)}
                      onChange={(opt) => setValue('internManager', opt.value)}
                      placeholder="Selectionner un chef de projet..."
                      options={octapharmaUsers}
                    />
                  </div>
                </div>
              }
            </section>
            <section>
              <h3>Comité de pilotage</h3>
              <div className={styles.list}>
                {watch('steeringCommittee')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div>
                    <p>{c?.member}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ steeringCommittee: watch('steeringCommittee').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              {!isOctapharma
                && <FormList
                  list={watch('steeringCommittee') || []}
                  handleChange={(arr) => submit({ steeringCommittee: arr })}
                  btn="Ajouter"
                  model={[
                    {
                      label: 'Membre',
                      key: 'member',
                      type: 'text',
                    },
                  ]}
                  />
              }
              {isOctapharma
                && <div className={styles.members}>
                  <StyledSelect
                    control={control}
                    onChange={(opt) => submit(
                      { steeringCommittee: [...watch('steeringCommittee'), { member: opt.label }] },
                    )}
                    placeholder={'Ajouter un membre'}
                    options={octapharmaUsers}
                />
                </div>
              }
            </section>
            <section>
              <h3>Conseil Scientifique</h3>
              <div className={styles.list}>
                {watch('scientificCouncil')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div>
                    <p>{c?.profile?.firstName} {c?.profile?.lastName}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ scientificCouncil: watch('scientificCouncil').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              <div className={styles.members}>
                <StyledSelect
                  control={control}
                  onChange={(opt) => submit(
                    { scientificCouncil: [...watch('scientificCouncil').map((s) => s._id || s), opt.value] },
                  )}
                  placeholder={'Ajouter un médecin'}
                  options={doctorsOptions}
                />
               </div>
            </section>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>Prestataires</h3>
              <div className={styles.list}>
                {watch('providers')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div className={styles.col2}>
                    <p>{c?.name}</p>
                    <p>{c?.contact}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ providers: watch('providers').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              <FormList
                list={watch('providers') || []}
                handleChange={(arr) => submit({ providers: arr })}
                btn="Ajouter"
                model={[
                  {
                    label: 'Nom du partenaire',
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: 'Nom du contact',
                    key: 'contact',
                    type: 'text',
                  },
                ]}
                />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
