/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';

import styles from './Legal.module.scss';

export default function Transparency() {
  const history = useHistory();
  return (
    <div className={`${styles.container}`}>
      <div
        onClick={() => history.goBack()}
        className={styles.back}>
        <BsArrowLeftShort size={24} />
        Retour
      </div>
      <div className={styles.content}>
        <h1>Transparence des liens d’intérêt</h1>

        <p>
          Les entreprises du médicament développent et mettent à disposition des professionnels de santé et des patients des médicaments afin de lutter contre la maladie et améliorer la vie des générations actuelles et futures.
        </p>

        <p>
          Octapharma, entreprise du médicament, a la responsabilité de conduire l’ensemble de ses activités dans le respect de principes d’éthique et d’intégrité.
        </p>

        <p>
          Sa mission est de développer des médicaments qui répondent à des besoins thérapeutiques majeurs.
        </p>

        <p>
          Les relations avec les professionnels de santé sont indispensables pour faire avancer notre recherche, pour le bien des patients. «Les relations entre professionnels de santé et industries sont indispensables au progrès médical, leur connaissance est un instrument de confiance entre les citoyens et le système de santé».
          <i>[21 mai 2013. Communiqué de presse du ministère de la Santé]</i>.
        </p>

        <p>
          Ces relations de travail constituent des liens d’intérêt.
        </p>

        <p>
          Dans le cadre de son engagement pour la transparence de ses pratiques, Octapharma se conforme à la règlementation relative à la transparence des liens entre industries et professionnels de santé/autres acteurs de la santé
          <i>(Loi Bertrand du 29 décembre 2011 renforcée par la loi Touraine du 26 janvier 2016).</i>
        </p>

        <p>
          Depuis le 1er octobre 2013, en application de ces lois, octapharma publie sur le site unique de la Direction Générale de la
          Santé <a href="https://www.transparence.sante.gouv.fr/flow/main;jsessionid=585E8DACE23511B5F2EA2C40D60E7A45?execution=e1s1" rel="noreferrer" target="_blank">Transparence Santé</a> ses
          liens d’intérêts (conventions, avantages sans contrepartie, rémunérations, dons) avec les professionnels de santé et autres acteurs de la santé.
        </p>

        <p>
          Pour plus d’informations concernant le dispositif de transparence des liens d’intérêts,
          <br/><a href="https://www.leem.org/publication/professionnels-de-sante-la-transparence-des-liens-dinterets-un-dispositif-de-prevention" rel="noreferrer" target="_blank">consultez le site du LEEM.</a>
        </p>

        <h3>Droit d&apos;accès et de rectification</h3>

        <p>
          Conformément à la loi «Informatique et Libertés» du 6/01/1978 modifiée, et au décret n°2005-1309 du 20 octobre 2005, les personnes concernées par la Transparence des liens disposent d’un droit d’accès et de rectification aux informations les concernant.
        </p>

        <p>
          Vous pouvez exercer ce droit en vous connectant directement sur le site de la Direction Générale de la Santé <a href="https://www.transparence.sante.gouv.fr/flow/main;jsessionid=14EC8093BCE7193EC5484038AAF4316E?execution=e1s1" rel="noreferrer" target="_blank">Transparence.Santé.gouv.fr</a>.
        </p>

        <p>
          Après avoir cliqué sur la ligne vous concernant nécessitant une rectification, cliquer sur détail puis en bas de page sur « faire une demande de rectification ».
        </p>

        <p>
          Vous pouvez également demander cette rectification en écrivant à : <br/>
          <a href="mailto:FR2Transparence@octapharma.com" >FR2Transparence@octapharma.com</a>.
        </p>

        <p>
          Il n&apos;est en revanche pas possible de s’opposer à la publication des liens dans la mesure où il s&apos;agit d&apos;une obligation légale des entreprises de santé.
        </p>

        <div className={styles.end}>
        </div>

      </div>
    </div>
  );
}
