import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
// import { SET_USERS_FILTERS } from '../../../actions/types';

import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';
import styles from './NewsList.module.scss';
import { GET_NEW } from '../../../actions/types';
import { getNewsAction } from '../../../actions/newsActions';
import NewsRow from '../../../components/News/NewsRow/NewRow';
import NewsFilters from '../../../components/News/NewsFilters/NewsFilters';

export default function NewsList() {
  const { newsList, filters } = useSelector((state) => state.news);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    title: true,
    status: true,
    name: true,
    date: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getNewsAction(dispatch);
    dispatch({
      type: GET_NEW,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'title':
        list = list.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'name':
        list = list.sort((a, b) => {
          if (a?.author?.profile?.lastName < b?.author?.profile?.lastName) return -1;
          if (a?.author?.profile?.lastName > b?.author?.profile?.lastName) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'date':
        list = list.sort((a, b) => {
          if (new Date(a.date) < new Date(b.date)) return -1;
          if (new Date(a.date) > new Date(b.date)) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'status':
        list = list.sort((a, b) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      default:
        break;
    }
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    return val;
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    let list = newsList;
    if (filters.search) {
      list = list.filter((t) => {
        if (t.title.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [newsList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>ACTUALITÉS</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/news/create')}>
              NOUVELLE ACTUALITÉ
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Titre'}
              handleClick={() => sortList('title', !sortByReverse.title)}
              state={sortByReverse.title}
              className={`${styles.col}`}/>
            <SortButton
              name={'Date'}
              handleClick={() => sortList('date', !sortByReverse.date)}
              state={sortByReverse.date}
              className={`${styles.col}`}/>
            <SortButton
              name={'Auteur'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <SortButton
              name={'Statut'}
              handleClick={() => sortList('status', !sortByReverse.status)}
              state={sortByReverse.status}
              className={`${styles.col} ${styles.desktop}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          className={styles.list}
          >
          {!isLoading && filteredList.length > 0 && filteredList?.map((t, i) => <NewsRow
            key={t._id}
            news={t}
            index={i}
            styles={styles}
          />)}
        </div>
      </div>
      <NewsFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
