import uniqid from 'uniqid';
import DIcon from '../assets/images/icons/dashboard.svg';
import datavisionIcon from '../assets/images/icons/datavision.svg';
import octadminIcon from '../assets/images/icons/octadmin-icon.svg';
import genealovizIcon from '../assets/images/icons/genealoviz-icon.svg';
import clinoctaIcon from '../assets/images/icons/clinocta-icon.svg';
import octasmartIcon from '../assets/images/icons/octasmart-icon.svg';
import medoctaIcon from '../assets/images/icons/medocta-icon.svg';
import videoIcon from '../assets/images/icons/video-icon.svg';

import genealoviz from '../assets/images/products-images/genealoviz.jpg';
import clinocta from '../assets/images/products-images/clinocta.jpg';
import dash from '../assets/images/products-images/dash.jpg';
import datavis from '../assets/images/products-images/datavis.jpg';
import octadmin from '../assets/images/products-images/octadmin.jpg';
import octasmart from '../assets/images/products-images/octasmart.jpg';
import medocta from '../assets/images/products-images/medocta.jpg';
import video from '../assets/images/video.png';

export const icons = {
  Dashboard: {
    icon: DIcon,
    img: dash,
    color: '#60C3E7',
  },
  Datavision: {
    icon: datavisionIcon,
    img: datavis,
    color: '#3DCFD2',
  },
  Octadmin: {
    icon: octadminIcon,
    img: octadmin,
    color: '#0254B5',
  },
  Clinocta: {
    icon: clinoctaIcon,
    img: clinocta,
    color: '#5B44EB',
  },
  Genealoviz: {
    icon: genealovizIcon,
    img: genealoviz,
    color: '#192243',
  },
  Octasmart: {
    icon: octasmartIcon,
    img: octasmart,
    color: '#0EAF9C',
  },
  Medocta: {
    icon: medoctaIcon,
    img: medocta,
    color: '#FFA56D',
  },
  Hemostase: {
    icon: videoIcon,
    img: video,
    color: '#611736',
  },
};

export const cguDate = new Date(2021, 1, 13);

export const rolesOptions = [
  { value: 'Member', label: 'Membre' },
  { value: 'Owner', label: 'Contributeur' },
  { value: 'Admin', label: 'Administateur' },
];

export const typesOptions = [
  { value: 'Octapharma', label: 'Octapharma' },
  { value: 'Pharmacist', label: 'Pharmacien' },
  { value: 'Doctor', label: 'Médecin' },
  { value: 'Provider', label: 'Fournisseur' },
  { value: 'IDE', label: 'IDE' },
];

export const projectsTypesOptions = [
  { value: 'medical', label: 'Médical' },
  { value: 'marketing', label: 'Marketing' },
];

export const tasksStatusOptions = [
  {
    label: 'A faire',
    value: 'todo',
  },
  {
    label: 'En cours',
    value: 'doing',
  },
  {
    label: 'Fait',
    value: 'done',
  },
];

export const defaultPathologiesType = {
  id: uniqid(),
  name: 'Nouveau type',
  epidemiology: {
    isDisplayed: false,
    value: '',
  },
  etiology: {
    isDisplayed: false,
    value: '',
  },
  mechanisms: {
    isDisplayed: false,
    value: '',
  },
  clinic: {
    isDisplayed: false,
    value: '',
  },
  diagnostic: {
    isDisplayed: false,
    value: '',
  },
  treatment: {
    isDisplayed: false,
    value: '',
  },
  other: {
    isDisplayed: false,
    value: '',
  },
  mutationsDescription: '',
  symptomsDescription: '',
  symptoms: [],
  subtypes: [],
};

export const defaultSubtype = {
  id: uniqid(),
  name: 'Nouveau sous-type',
  epidemiology: {
    isDisplayed: false,
    value: '',
  },
  etiology: {
    isDisplayed: false,
    value: '',
  },
  mechanisms: {
    isDisplayed: false,
    value: '',
  },
  clinic: {
    isDisplayed: false,
    value: '',
  },
  diagnostic: {
    isDisplayed: false,
    value: '',
  },
  treatment: {
    isDisplayed: false,
    value: '',
  },
  other: {
    isDisplayed: false,
    value: '',
  },
  mutationsDescription: '',
  symptomsDescription: '',
  symptoms: [],
};

export const formKeys = [
  { value: 'clinic', label: 'Clinique' },
  { value: 'diagnostic', label: 'Diagnostic' },
  { value: 'epidemiology', label: 'Epidemiologie' },
  { value: 'etiology', label: 'Etiologie' },
  { value: 'mechanisms', label: 'Mecanismes' },
  { value: 'treatment', label: 'Traitement' },
  { value: 'other', label: 'Autres' },
];
