import React from 'react';
import uniqid from 'uniqid';
import styles from './TagsList.module.scss';

export default function TagsList({
  isFilter,
  isEdit,
  label,
  options = [],
  selected = [],
  isUnique = false,
  isBoolean = false,
  handleChange,
}) {
  function handleChangeList(value) {
    if (isUnique) {
      return handleChange(value);
    }
    if (selected.find((s) => s === value)) {
      return handleChange(selected.filter((s) => s !== value));
    }
    return handleChange([...selected, value]);
  }

  function isSelected(val) {
    const isSelect = selected?.filter((s) => s === val);
    if (isSelect.length) {
      return true;
    }
    return false;
  }

  function getClassName(isActive, isTrue) {
    return `${isActive && isTrue ? styles.true : ''}`;
  }

  return (
    <div className={`${styles.container}  ${isBoolean ? styles.boolean : ''} ${!isEdit ? styles['not-edit'] : ''} ${isFilter ? styles['is-filter'] : ''}`}>
      {label && <label>{label}</label>}
      <div className={styles.list}>
        {isEdit
          ? options && options?.map((o) => {
            const isActive = isSelected(o.value);
            return <button
              type='button'
              onClick={() => handleChangeList(o?.value)}
              className={isActive ? styles.selected : ''}
              key={uniqid()}
              >
              {o.label}
            </button>;
          })
          : options && options.filter((o) => isSelected(o?.value)).map((o) => {
            const isActive = isSelected(o.value);
            const isTrue = o.label === 'Oui';
            const className = getClassName(isActive, isTrue);
            return <button
              type='button'
              key={uniqid()}
              className={className}
              >
                {o.label}
            </button>;
          })
        }
      </div>
    </div>
  );
}
