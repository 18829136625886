import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { FiCalendar, FiClock } from 'react-icons/fi';
import styles from './Contract.module.scss';
import { API_URL } from '../../../../actions';

export default function Contract({ project }) {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h2>CONTRATS</h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && contentRef?.current?.clientHeight > 0 ? contentRef.current.clientHeight + 20 : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          {project.contracts.map((c) => (
            <div key={c._id} className={styles.row}>
              {c?.provider
                && <div className={`${styles.col}`}>
                  <p>
                    <label>Etablissements/prestataire : </label>
                    <span className={styles.bold}>
                     {c.provider}
                    </span>
                  </p>
                </div>
              }
              {c?.signatureDate && c.endDate
                && <div className={`${styles.col} ${styles.icon}`}>
                  <FiCalendar size={20}/>
                  <p>
                    <span className={styles.bold}>
                      du {c?.signatureDate && format(new Date(c?.signatureDate), 'dd/MM/yy')} au {c?.endDate && format(new Date(c?.endDate), 'dd/MM/yy')}
                    </span>
                  </p>
                </div>
              }
              {c.weekNumber
                && <div className={`${styles.col} ${styles.icon}`}>
                  <FiClock size={20}/>
                  <p>
                    <span className={styles.bold}>{c.weekNumber} semaines</span>
                  </p>
                </div>
              }
            </div>
          ))}
          {project?.financeFile && <div className={styles.border}>
            <h2>FICHE PROJET POUR FINANCE</h2>
            <div className={styles.list}>
              <a href={`${API_URL}/${project?.financeFile.path}`} target='_blank' rel="noreferrer">
                {project?.financeFile.name}
              </a>
            </div>
          </div>
          }
          {project?.costs?.length > 0 && <div className={styles.border}>
            <h2>COÛT DU PROJET</h2>
            <div className={styles.list}>
              {project.costs.map((c) => <div className={styles.cost} key={c._id} >
                <p>{c.ref}</p>
                <p>{c.amount} €</p>
              </div>)}
            </div>
          </div>
          }
        </div>
      </div>
    </section>
  );
}
