// import { format } from 'date-fns';
import React, {
  useRef, useEffect,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
// import { AiOutlineClockCircle } from 'react-icons/ai';
// import { FiCalendar } from 'react-icons/fi';
import { getOctapharamaUsers } from '../../../../actions/usersActions';
import BoxPanel from '../../../BoxPanel/BoxPanel';

import styles from './People.module.scss';
// import FilesPreview from '../../../FilesPreview/FilesPreview';

export default function People({
  congress,
  activeForm,
}) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const octapeoples = congress?.octapeoples;
  const guests = congress?.guests;
  // const trips = congress?.trips;
  // const guestsTrips = congress?.guestsTrips;
  const getData = useCallback(() => {
    getOctapharamaUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <BoxPanel label="Octapeople">
            <div className={styles.octapeoples}>
              <div
                className={`${styles.row} ${styles.label}`}
              >
                <div className={styles.name}>
                  Prénom Nom
                </div>
                <div className={styles.col}>Secteur</div>
                <div className={styles.col}>Badge</div>
              </div>
              {octapeoples?.map((o) => <div
                key={o?._id}
                className={styles.row}
              >
                <div className={styles.name}>
                  {o?.user?.profile?.firstName}
                  {' '}
                  {o?.user?.profile?.lastName}
                </div>
                <div className={styles.col}>{o.division}</div>
                <div className={styles.col}>{o.badge}</div>
              </div>)}
            </div>
          </BoxPanel>
          <BoxPanel label="Invités">
            <div className={styles.guests}>
              <div
                className={`${styles.row} ${styles.label}`}
              >
                <div className={styles.name}>
                  Prénom Nom
                </div>
                <div className={styles.col}>Email</div>
                <div className={styles.col}>Téléphone</div>
                <div className={styles.col}>Présence</div>
              </div>
              {guests?.map((o) => <div
                key={o?._id}
                className={styles.row}
              >
                <div className={styles.name}>
                  {o.firstName}
                  {' '}
                  {o?.lastName}
                </div>
                <div className={styles.col}>{o.email}</div>
                <div className={styles.col}>{o.tel}</div>
                <div className={styles.col}>{o.presence ? 'OUI' : 'NON'}</div>
              </div>)}
            </div>
          </BoxPanel>
        </div>
      </div>
      {/* {(trips?.length > 0 || guestsTrips?._id)
        && <div className={styles.row}>
          <div className={styles.col}>
            <BoxPanel label="Trajet">
              <section>
                {trips?.length > 0
                && <div className={styles.trips}>
                  <div
                    className={`${styles.row} ${styles.label}`}
                  >
                    <div className={styles.col}>
                      Prénom Nom
                    </div>
                    <div className={styles.col}> Aller</div>
                    <div className={styles.col}>Retour</div>
                  </div>
                  {trips?.map((t) => <div
                    key={t?._id}
                    className={styles.row}
                  >
                    <div className={styles.col}>
                      <p>
                      {t?.firstName}
                      {' '}
                      {t?.lastName}
                      </p>
                    </div>
                    <div className={styles.col}>
                      <p>{t.forward
                        ? <>
                        <FiCalendar />
                        {format(new Date(t.forward), 'dd/MM/yyyy')}
                        <AiOutlineClockCircle />
                        {format(new Date(t.forward), 'hh:mm')}
                        </>
                        : <span className>Pas de trajet aller</span>}
                      </p>
                    </div>
                    <div className={styles.col}>
                    <p>{t.return
                      ? <>
                        <FiCalendar />
                        {format(new Date(t.return), 'dd/MM/yyyy')}
                        <AiOutlineClockCircle />
                        {format(new Date(t.return), 'HH:mm')}
                        </>
                      : <span className>Pas de trajet retour</span>}
                      </p>
                    </div>
                  </div>)}
                </div>
                }
                {congress.guestsTrips?._id
                  && <>
                      <label>Fichier trajet</label>
                      {congress.guestsTrips
                        && <FilesPreview key="dotation" files={[congress.guestsTrips]} />
                      }
                  </>
                }
                </section>
              </BoxPanel>
          </div>
        </div>
      } */}
    </div>
  );
}
