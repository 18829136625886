import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';

import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';

import styles from './TherapeuticAreasList.module.scss';
import { getTherapeuticAreasListAction } from '../../../actions/therapeuticAreasActions';
import { GET_THERAPEUTIC_AREA } from '../../../actions/types';

import TherapeuticFilters from '../../../components/Therapeutic/TherapeuticFilters/TherapeuticFilters';
import TherapeuticRow from '../../../components/Therapeutic/TherapeuticRow/TherapeuticRow';

export default function TherapeuticAreasList() {
  const { therapeuticAreasList, filters } = useSelector((state) => state.therapeuticAreas);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    name: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getTherapeuticAreasListAction(dispatch);
    dispatch({
      type: GET_THERAPEUTIC_AREA,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      default:
        break;
    }
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    return val;
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    let list = therapeuticAreasList;
    if (filters.search) {
      list = list.filter((t) => {
        if (t.name.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        if (t.description.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [therapeuticAreasList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>Aires thérapeutiques</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/therapeuticarea/create')}>
              NOUVELLE AIRE THERAPEUTIQUE
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          className={styles.list}
          >
          {!isLoading && filteredList.length > 0 && filteredList?.map((t, i) => <TherapeuticRow
            key={t._id}
            therapeutic={t}
            index={i}
            styles={styles}
          />)}
        </div>
      </div>
      <TherapeuticFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
