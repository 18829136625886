import {
  ERROR_CONGRESS,
  // GET_CONGRESSES_LISTS,
  GET_CONGRESSES,
  GET_CONGRESS,
  PUT_CONGRESS,
  POST_CONGRESS,
  SET_CONGRESSES_FILTERS,
} from '../actions/types';

const initialState = {
  error: null,
  congressesList: [],
  congress: null,
  lists: null,
  filters: {
    search: null,
    types: [],
  },
};

export default function congressesReducer(state = initialState, action) {
  const congressesList = state?.congressesList;
  const index = state?.congressesList?.findIndex(
    (congress) => congress?._id === action?.payload?._id,
  );

  switch (action.type) {
    case POST_CONGRESS:
      return {
        ...state,
        congressesList: [...congressesList, action.payload],
        congress: action.payload,
      };

    case PUT_CONGRESS:
      congressesList[index] = action.payload;
      return {
        ...state,
        congressesList,
        congress: action.payload,
      };

    case SET_CONGRESSES_FILTERS:
      return { ...state, filters: action.payload };

      // case GET_CONGRESSES_LISTS:
      //   return { ...state, lists: action.payload };

    case GET_CONGRESS:
      return { ...state, congress: action.payload };

    case GET_CONGRESSES:
      return { ...state, congressesList: action.payload };

      // case DELETE_TOOL:
      //   congressesList = state.congressesList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, congressesList, message: 'user removed' };

    case ERROR_CONGRESS:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
