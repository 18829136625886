import {
  ERROR_NEW,
  MESSAGE_NEW,
  SET_NEWS_FILTERS,
  GET_NEWS,
  GET_NEW,
  PUT_NEW,
  POST_NEW,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  newsList: [],
  news: null,
  filters: {
    search: null,
  },
};

export default function newsReducer(state = initialState, action) {
  const newsList = state?.newsList;
  const index = state?.newsList?.findIndex((user) => user?._id === action?.payload?.user?._id);

  switch (action.type) {
    case POST_NEW:
      return {
        ...state,
        newsList: [...newsList, action.payload],
        news: action.payload,
      };

    case PUT_NEW:
      newsList[index] = action.payload;
      return {
        ...state,
        newsList,
        news: action.payload,
      };

    case SET_NEWS_FILTERS:
      return { ...state, filters: action.payload };

    case GET_NEW:
      return { ...state, news: action.payload };

    case GET_NEWS:
      return { ...state, newsList: action.payload };

      // case DELETE_news:
      //   newsList = state.newsList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, newsList, message: 'user removed' };

    case MESSAGE_NEW:
      return { ...state, message: action.payload };

    case ERROR_NEW:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
