/* eslint-disable prefer-template */
import React, {
  useCallback, useEffect,
} from 'react';
import {
  Link, useParams,
} from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronBack } from 'react-icons/io5';
import { GoPencil } from 'react-icons/go';
import { HiLink } from 'react-icons/hi';
import { FiCalendar } from 'react-icons/fi';

import { getDrugAction } from '../../../actions/drugsActions';

import Button from '../../../lib/HooksFormFields/Button';
import BoxPanel from '../../../components/BoxPanel/BoxPanel';

// import logo from '../../../assets/images/logo.svg';
import iconS from '../../../assets/images/icons/icon-s.png';
import styles from './DrugView.module.scss';
import { generateKey, isValidDate } from '../../../utils/utils';
import { API_URL } from '../../../actions';

export default function DrugView() {
  const id = useParams()?.id;
  const { drug, variations } = useSelector((state) => state.drugs);
  const { companiesList } = useSelector((state) => state.companies);
  const { therapeuticAreasList } = useSelector((state) => state.therapeuticAreas);

  const joStyles = `${styles.row} ${styles['date-row']}`;
  const variationUrl = (variationId) => `${process.env.REACT_APP_VARIOCTA_URL}/variation/${variationId}`;
  const dispatch = useDispatch();

  const getDrug = useCallback(() => {
    getDrugAction(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getDrug();
    }
  }, []);

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{drug?.name
              && <span>{drug.name}</span>
            }
          </h2>
          <Link to={'/product'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <Link to={`/product/edit/${id}?form=0`} className={styles.link}>
            <Button
              className={styles.edit}>
              <span>Editer la fiche </span>
              <GoPencil size={20}/>
            </Button>
          </Link>
        </div>
      </header>
      {drug?._id
        && <div className={`${styles.content}`} >
          <main>

            <div className={styles.infos} >
              <h2>INFORMATIONS</h2>
              <div className={styles.row}>
                <div className={styles['col-2']}>
                  <div className={styles.img}>
                   {drug?.logoDrug?.path
                      && <img src={`${API_URL}/${drug.logoDrug?.path}`} alt={drug.logoDrug?.alt} />
                    }
                  </div>
                  <div>
                    <label>Nom du produit</label>
                    <p>{drug?.name}</p>
                  </div>
                </div>
                {drug?.company
                  && <div className={styles['col-2']}>
                    <div className={styles.img}>
                      {drug?.logoCompany?.path
                        && <img src={`${API_URL}/${drug.logoCompany?.path}`} alt={drug.logoCompany?.alt} />
                      }
                    </div>
                    <div>
                      <label>Laboratoire</label>
                      <p>{companiesList?.find((c) => c._id === drug?.company)?.name}</p>
                    </div>
                  </div>
                }
              </div>
              {drug?.therapeuticAreas.length > 0
                && <div className={styles.areas}>
                  <label className={styles.bold}>Aire thérapeutique</label>
                  <div className={styles['tags-list']}>
                    {drug?.therapeuticAreas?.map(
                      (t, i) => (<p key={generateKey('therapeuticArea', i)} className={styles.tag}>{therapeuticAreasList.find((opt) => opt._id === t)?.name}</p>),
                    )}
                  </div>
                </div>
              }
            </div>

            <BoxPanel label={'Process de fabrication'}>
              <div className={styles.process}>
                <div className={styles.row}>
                  <div className={styles['col-2']}>
                    <label className={styles.bold}>Etapes de fabrication</label>
                    <ul>
                      {drug?.fabricationProcess?.map((f, i) => <li key={'process' + i}>{f}</li>)}
                    </ul>
                  </div>
                  <div className={styles['col-2']}>
                    <label className={styles.bold}>Etapes de sécurisation</label>
                    <ul>
                      {drug?.viralSecurity?.map((f, i) => <li key={'process' + i}>{f}</li>)}
                    </ul>

                  </div>
                </div>
              </div>
            </BoxPanel>
            <BoxPanel label={'COMPOSITION QUALITATIVE ET QUANTITATIVE'}>
              <div className={styles.composition}>
                <div className={styles.list}>
                <div className={styles.labels}>
                  <p className={styles.excipient}>Concentration</p>
                  <p className={styles.excipient}>Dosage</p>
                  <p className={styles.excipient}>Volume</p>
                </div>
                {drug?.composition?.map((d, i) => <div
                  key={d._id || i}
                  className={styles.item}
                  >
                    <p className={styles['compo-item']}>{d?.concentration}</p>
                    <p className={styles['compo-item']}>{d?.dosage}</p>
                    <p className={styles['compo-item']}>{d?.volume}</p>
                  </div>)}
                </div>
                <div className={styles.border}>
                  <h2>FORME PHARMACEUTIQUE</h2>
                  <div className={styles.row}>
                    {drug?.form && <div className={styles.form}>
                      {drug.form}
                    </div>
                    }
                    <p className={styles['form-description']}>
                      {drug?.formDescription}
                    </p>
                  </div>
                </div>
              </div>
            </BoxPanel>
            <BoxPanel label={'Excipients'}>
              <div className={styles.excipients}>
                <div className={styles.list}>
                {drug?.excipient?.map((e) => <div
                  key={e}
                  className={styles.item}
                  >
                    <p>{e}</p>
                  </div>)}
                </div>
              </div>
            </BoxPanel>
            <div className={styles.pharmaco}>
              <BoxPanel label={'propriétés pharmacoLOGIQUEs'}>
              <BoxPanel label={'Propriétés pharmacodynamiques'} isNested>
                <div className={styles.row}>
                  <div className={styles['col-1']}>
                    <p className={styles.text}>{drug?.property?.dynamic?.description}</p>
                  </div>
                </div>
              </BoxPanel>
              <BoxPanel label={'Propriétés pharmacocinétiques'} isNested>
                <div className={styles.list}>
                  {drug?.property.kinetic.halfLife?.map((d) => <div
                    key={d._id}
                    className={styles.item}
                    >
                    <p className={styles.bold}>{d?.type}</p>
                    <p>{d.method}</p>
                    <p>{d?.value} {d?.unit}</p>
                  </div>)}
                </div>
                <label className={styles.bold}>Récupération</label>
                <div className={styles.list}>
                  {drug?.property.kinetic.recuperation?.map((d) => <div
                    key={d._id}
                    className={styles.item}
                    >
                    <p className={styles.bold}>{d?.type}</p>
                    <p>{d.method}</p>
                    <p>{d?.value} {d?.unit}</p>
                  </div>)}
                </div>
              </BoxPanel>
            </BoxPanel>
            </div>

            <BoxPanel label={'Données pharmaceutiques'}>
              <div className={styles.data}>
                <label className={styles.bold}>Incompatibilités</label>
                <div className={styles.list}>
                  {drug?.data?.incompatibilities?.map((inc, i) => <div key={'incompatibility' + i} className={styles.item}>
                    <span className={styles.bold}>{inc}</span>
                  </div>)}
                </div>
              </div>
              <div className={styles.data}>
                <label className={styles.bold}>Conservation</label>
                <div className={styles.list}>
                  {drug?.data?.conservation?.map((c, i) => <div key={'conservation' + i} className={styles.item}>
                    <p className={styles.bold}>{c.situation}</p>
                    <p>{c?.duration} {c?.durationUnit}</p>
                    <p>{c?.temperature} {c?.temperatureUnit}</p>
                  </div>)}
                </div>
              </div>
            </BoxPanel>

            <BoxPanel label={'RECONSTITUTION'}>
              <div className={styles.reconstitution}>
                <label>Description</label>
                <p>{drug?.reconstitution?.description}</p>
                {drug?.reconstitution?.file?.path
                  && <div className={styles.media}>
                  {drug?.reconstitution?.file?.mimetype === 'video/mp4'
                    ? <video controls>
                        <source
                          src={`${API_URL}/${drug?.reconstitution?.file?.path}`}
                          type={drug?.reconstitution?.file.mimetype}>
                        </source>
                      </video>
                    : <img src={`${API_URL}/${drug?.reconstitution?.file?.path}`} alt={drug?.reconstitution?.file.alt}/>
                    }
                  </div>
                }
              </div>
            </BoxPanel>

            <BoxPanel label={'ÉTUDES'}>
              <div className={styles.study}>
                <label className={styles.bold}>Études PIVOT</label>
                <div className={styles.list}>
                  {drug?.studies?.pivotal?.map((a, i) => <a
                    key={'pivotal' + i}
                    href={a.url}
                    target='_blank'
                    rel="noreferrer"
                    className={styles.link}>
                    <span className={styles.bold}>{a.name}</span><HiLink size={18} />
                  </a>)}
                </div>
              </div>
              <div className={styles.study}>
                <label className={styles.bold}>Articles</label>
                <div className={styles.list}>
                  {drug?.studies?.articles?.map((a, i) => <a
                    key={'article' + i}
                    href={a.url}
                    target='_blank'
                    rel="noreferrer"
                    className={styles.link}>
                    <span className={styles.bold}>{a.name}</span><HiLink size={18} />
                  </a>)}
                </div>
              </div>
              <div className={styles.study}>
                <label className={styles.bold}>Études</label>
                <div className={styles.list}>
                  {drug?.studies?.studies?.map((a, i) => <a
                    key={'study' + i}
                    href={a.url}
                    target='_blank'
                    rel="noreferrer"
                    className={styles.link}>
                    <span className={styles.bold}>{a.name}</span><HiLink size={18} />
                  </a>)}
                </div>
              </div>
              <div className={styles.study}>
                <label className={styles.bold}>Liens</label>
                <div className={styles.list}>
                  {drug?.studies?.web?.map((a, i) => <a
                    key={'study' + i}
                    href={a.url}
                    target='_blank'
                    rel="noreferrer"
                    className={styles.link}>
                    <span className={styles.bold}>{a.name}</span><HiLink size={18} />
                  </a>)}
                </div>
              </div>
            </BoxPanel>

            <BoxPanel label={'RÉGLEMENTATION'}>
              <div className={styles.regulations}>
                <label className={styles.bold}>DCI</label>
                <p>{drug?.regulations?.dci}</p>
              </div>

              <div className={styles.border}>
                <label className={styles.bold}>Date AMM</label>

                {drug?.regulations?.amm?.date
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{format(new Date(drug?.regulations?.amm?.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {drug?.regulations?.amm?.url
                      && <div className={styles.url}>
                      <a
                        href={drug?.regulations?.amm?.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {drug?.regulations?.amm?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>
                }
                {drug?.regulations?.amm?.dateRenew
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{format(new Date(drug?.regulations?.amm?.dateRenew), 'dd/MM/yyyy')}</span>
                    </div>
                  </div>
                }

              </div>

              <div className={styles.border}>
                <label className={styles.bold}>Sunset Clause</label>
                {drug?.regulations?.sunset?.date
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{format(new Date(drug?.regulations?.sunset?.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {drug?.regulations?.sunset?.url
                      && <div className={styles.url}>
                      <a
                        href={drug?.regulations?.sunset?.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {drug?.regulations?.sunset?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>
                }
              </div>

              <div className={styles.border}>
                <label className={styles.bold}>Sunset Clause prévisionelle</label>
                {drug?.regulations?.forecastSunset?.date
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{format(new Date(drug?.regulations?.forecastSunset?.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {drug?.regulations?.forecastSunset?.url
                      && <div className={styles.url}>
                      <a
                        href={drug?.regulations?.forecastSunset?.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {drug?.regulations?.forecastSunset?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>
                }
              </div>

            </BoxPanel>

            <BoxPanel
              label={'CIP'}
            >
              <div className={styles.cips}>
                {drug?.regulations.cip?.length > 0 && drug?.regulations.cip?.map((c, i) => <div
                  key={generateKey('cip', i)}
                  className={styles.cip}
                >
                <div className={styles.row}>
                  <div className={styles.col}>
                    N° {c.label}
                  </div>
                  <div className={styles.col}>
                    Libellé : {c.number}
                  </div>
                  <div className={styles.col}>
                    Date 1er commerce : {isValidDate(c?.firstDate) && format(new Date(c?.firstDate), 'dd/MM/yyyy')}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    Date arrêt : {isValidDate(c?.stopDate) && format(new Date(c?.stopDate), 'dd/MM/yyyy')}
                  </div>
                  <div className={styles.url}>
                    {c?.url && <a href={c.url} target="_blank" rel="noreferrer">{c.url}</a>}
                  </div>
                </div>
              </div>)}
            </div>
            </BoxPanel>

            <BoxPanel label={'procédures'}>
              {drug?.regulations?.procedure?.registration
                && <div className={styles.regulations}>
                  <label className={styles.bold}>Enregistrement</label>
                  <p className={styles.tag}>{drug?.regulations?.procedure?.registration}</p>
                </div>
              }

              <div className={styles.border}>
                <label className={styles.bold}>RCP en vigueur</label>
                {drug?.regulations?.procedure?.rcp
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{format(new Date(drug?.regulations?.procedure?.rcp?.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {drug?.regulations?.procedure?.rcp?.url
                      && <div className={styles.url}>
                      <a
                        href={drug?.regulations?.procedure?.rcp?.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {drug?.regulations?.procedure?.rcp?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>
                }
              </div>
              <div className={styles.border}>
                <label className={styles.bold}>Avis de transparence en vigueur</label>
                {drug?.regulations?.procedure?.rcpNotice
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{drug?.regulations?.procedure?.rcpNotice?.date && format(new Date(drug?.regulations?.procedure?.rcpNotice?.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {drug?.regulations?.procedure?.rcpNotice?.url
                      && <div className={styles.url}>
                      <a
                        href={drug?.regulations?.procedure?.rcpNotice?.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {drug?.regulations?.procedure?.rcpNotice?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>
                }
              </div>

              {drug?.regulations?.procedure?.jo
              && <div className={styles.border}>
                <label className={styles.bold}>JO en vigueur </label>
                {drug?.regulations?.procedure?.jo?.map(
                  (jo) => <div key={jo._id} className={joStyles}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{jo?.date && format(new Date(jo.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {jo?.url
                      && <div className={styles.url}>
                      <a
                        href={jo.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {jo?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>,
                )}
              </div>
            }

              {drug?.regulations?.procedure?.tr
                && <div className={`${styles.regulations} ${styles.border}`}>
                  <label className={styles.bold}>TR</label>
                  <div className={styles.list}>
                    {drug?.regulations?.procedure?.tr?.map((tr) => <div
                      key={tr._id}
                      className={styles.item}>
                      <p>{tr.name}</p> <p>{tr.value} €</p>
                    </div>)}
                  </div>
                </div>
              }

              <div className={`${styles.regulations} ${styles.border}`}>
                <label className={styles.bold}>MIT</label>
                <p className={styles.tag}>{drug?.regulations?.procedure?.mitm ? 'OUI' : 'NON'}</p>
              </div>

              <div className={`${styles.pgp} ${styles.border}`}>
                <label className={styles.bold}>PGP</label>
                <p className={styles.tag}>{drug?.regulations?.procedure?.pgp?.isPgp ? 'OUI' : 'NON'}</p>
                {drug?.regulations?.procedure?.pgp
                  && <div className={`${styles.row} ${styles['date-row']}`}>
                    <div className={styles.date}>
                      <FiCalendar size={20} />
                      <span>{drug?.regulations?.procedure?.pgp?.date && format(new Date(drug?.regulations?.procedure?.pgp?.date), 'dd/MM/yyyy')}</span>
                    </div>
                    {drug?.regulations?.procedure?.pgp?.url
                      && <div className={styles.url}>
                      <a
                        href={drug?.regulations?.procedure?.pgp?.url}
                        target='_blank'
                        rel="noreferrer">
                        <p>
                          {drug?.regulations?.procedure?.pgp?.url}
                        </p>
                        <img src={iconS} alt="s"/>
                      </a>
                    </div>
                    }
                  </div>
                }
              </div>

            </BoxPanel>

          </main>
          <aside>
            <BoxPanel label={'Données cliniques'}>
              <BoxPanel label={'Indications'} isNested>
                <div className={styles.list}>
                  {drug?.clinicalData?.indication?.map((e, i) => <div key={'indication' + i} className={styles.item}>
                    <span className={styles.bold}>{e}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Posologie'} isNested>
                {drug?.clinicalData?.dosageDescription
                  && <p className={styles.bold}>{drug?.clinicalData?.dosageDescription}</p>
                }
                <div className={styles.list}>
                  {drug?.clinicalData?.dosage?.map(
                    (d, i) => <div key={'dosage' + i} className={styles.item}>
                      <p className={styles.bold}>{d.situation}</p>
                      <p>{d.speed}</p>
                    </div>,
                  )}
                </div>
              </BoxPanel>
              {drug?.administration
                && <BoxPanel label={'Mode d’administration'} isNested>
                  <div className={styles.list}>
                    <div className={styles.item}>
                      <p className={styles.bold}>{drug?.administration}</p>
                    </div>
                  </div>
                </BoxPanel>
              }
              <BoxPanel label={'Contre-indications'} isNested>
                <div className={styles.list}>
                  {drug?.clinicalData?.contraindication?.map((c, i) => <div key={'contre-indication' + i} className={styles.item}>
                    <span className={styles.bold}>{c}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Mise en garde'} isNested>
                <div className={styles.list}>
                  {drug?.clinicalData?.warning?.map((c, i) => <div key={'warning' + i} className={styles.item}>
                    <span className={styles.bold}>{c}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Interactions'} isNested>
                <div className={styles.list}>
                  {drug?.clinicalData?.interactions?.map((c, i) => <div key={'interactions' + i} className={styles.item}>
                    <span className={styles.bold}>{c}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Grossesse'} isNested>
                <div className={styles.list}>
                  {drug?.clinicalData?.pregnancy?.map((c, i) => <div key={'pregnancy' + i} className={styles.item}>
                    <span className={styles.bold}>{c}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Effets indésirables'} isNested>
                <div className={styles.list}>
                  {drug?.clinicalData?.undesirableEffect?.map((u, i) => <div key={'undesirableEffect' + i} className={styles.item}>
                    <span className={styles.bold}>{u.name}</span>
                    <span>{u.frequency}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Surdosage'} isNested>
                <p>
                  {drug?.clinicalData?.overdose}
                </p>
              </BoxPanel>
            </BoxPanel>

            <BoxPanel label={'CONDITIONNEMENT'}>
              <BoxPanel label={'Flacon'} isNested>
                <div className={styles.list}>
                  {drug?.conditioning?.bottles?.map((u, i) => <div key={'bottles' + i} className={styles.item}>
                    <span className={styles.bold}>{u.name}</span>
                    <span>{u.dose}</span>
                  </div>)}
                </div>
              </BoxPanel>
              <BoxPanel label={'Contenu boîte'} isNested>
                <div className={styles.list}>
                  {drug?.conditioning?.boxes?.map((c, i) => <div key={'boxes' + i} className={styles.item}>
                    <span className={styles.bold}>{c}</span>
                  </div>)}
                </div>
              </BoxPanel>
            </BoxPanel>

            <BoxPanel label={'SITE'}>
              {drug?.regulations?.locations?.fabrication && <div className={styles.site}>
                <label className={styles.bold}>Site de fabrication</label>
                <div className={styles['tags-list']}>
                  {drug?.regulations?.locations?.fabrication?.map(
                    (f, i) => (<p key={generateKey('fabrication', i)} className={styles.tag}>{f}</p>),
                  )}
                </div>
              </div>}
              {/* {drug?.regulations?.locations?.control && <div className={styles.site}>
                <label className={styles.bold}>Site de controle echantillonthèque</label>
                <div className={styles['tags-list']}>
                  {drug?.regulations?.locations?.control?.map(
                    (f, i) => (<p key={generateKey('control', i)} className={styles.tag}>{f}</p>),
                  )}
                </div>
              </div>}
              {drug?.regulations?.locations?.release && <div className={styles.site}>
                <label className={styles.bold}>Site de libération</label>
                <div className={styles['tags-list']}>
                  {drug?.regulations?.locations?.release?.map(
                    (f, i) => (<p key={generateKey('release', i)} className={styles.tag}>{f}</p>),
                  )}
                </div>
              </div>} */}
            </BoxPanel>

            <BoxPanel label={'VARIATIONS par conditionnement'}>
              <div className={`${styles.list} ${styles.variations}`}>
                {drug?.regulations?.variations?.map((v, i) => <div key={'variation' + i} className={styles.item}>
                  <span className={styles.bold}>{v}</span>
                </div>)}
              </div>
            </BoxPanel>

            {variations.length > 0
              && <BoxPanel label={'VARIATIONS'}>
              <div className={`${styles.list} ${styles.variations}`}>
                {variations?.map((v) => (
                  <a
                  key={'variation-link' + v._id}
                  href={variationUrl(v._id)}
                  target='_blank'
                  rel="noreferrer"
                  className={styles.link}>
                  <span className={styles.bold}>{v.number}</span><HiLink size={18} />
                </a>
                ))}
              </div>
              </BoxPanel>
              }

          </aside>
        </div>
      }
    </div>
  );
}
