import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronBack, IoSave } from 'react-icons/io5';
import { HiOutlineMail } from 'react-icons/hi';

import { format } from 'date-fns';
import { typesOptions, rolesOptions } from '../../../constants/constants';
import {
  getDivisionsAction,
  getSpecialitiesAction,
  getUserAction,
  getUsersProductsAction,
  inviteUserAction,
  postUserAction,
  putUserAction,
} from '../../../actions/usersActions';
import pen from '../../../assets/images/icons/pen-white.svg';
import Button from '../../../lib/HooksFormFields/Button';
import TagsList from '../../../components/TagsList/TagsList';
import ProductRole from '../../../components/ProductRole/ProductRole';

import styles from './EditUser.module.scss';
import {
  Checkbox,
  ErrorField, InputEmail, InputPassword, InputText, StyledSelect,
} from '../../../lib/HooksFormFields';
import { getToolsAction } from '../../../actions/toolsActions';
import { getCentersAction } from '../../../actions/centersActions';

export default function EditUser({ isCreate = false }) {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const {
    user, divisions, specialities, products,
  } = useSelector((state) => state.users);
  const {
    centersList,
  } = useSelector((state) => state.centers);

  const centersOptions = centersList.map((c) => ({ label: c.name, value: c._id }));
  const selectedCenter = centersList.find((o) => o._id === watch('center'));
  const servicesOptions = selectedCenter?.services && [{ value: '', label: 'pas de service' }, ...selectedCenter.services.map((s) => ({ value: s._id, label: s.name }))];

  const [isEdit, setIsEdit] = useState(false);

  const getUser = useCallback(() => {
    getUserAction(dispatch, id);
  }, [dispatch, id]);

  const getLists = useCallback(() => {
    getToolsAction(dispatch);
    getUsersProductsAction(dispatch);
    getDivisionsAction(dispatch);
    getSpecialitiesAction(dispatch);
    getCentersAction(dispatch);
  }, [dispatch]);

  function submitUser(values) {
    if (user?._id) {
      putUserAction(dispatch, user?._id, values);
    } else {
      postUserAction(dispatch, values);
    }
  }

  useEffect(() => {
    getLists();
    if (isCreate) {
      setIsEdit(true);
    }
    if (id !== 'create') {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      const propreties = [
        'profile',
        'email',
        'type',
        'role',
        'titre',
        'isActive',
        'division',
        'specialities',
        'tools',
        'code',
        'center',
        'service',
      ];
      propreties.forEach((p) => {
        if (user[p] || user[p] === false) {
          setValue(p, user[p]);
        }
      });
    }
  }, [user]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{user?.profile
            ? <span>{user.profile?.firstName} {user.profile?.lastName}</span>
            : <span>Nouvel utilisateur</span>
            }
          </h2>
          <Link to={'/user'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          {isEdit
            ? <Button
              handleClick={handleSubmit(submitUser)}
              className={styles.edit}>
              <span>Enregistrer la fiche</span>
              <IoSave size={28} />
            </Button>

            : <Button
              handleClick={() => setIsEdit(!isEdit)}
              className={styles.edit}>
              <span>EDITER LA FICHE</span>
              <img src={pen} alt="edit-user"/>
            </Button>
          }
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={`${styles.col}`}>
          <section>
            <h3>Information</h3>
            <div className={styles['input-list']}>

              <div className={styles.block}>
                <label>Nom</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='profile.lastName'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.profile?.lastName || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Prénom</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='profile.firstName'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.profile?.firstName || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Titre</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='profile.title'
                          control={control}
                          className={'primary'}
                          placeholder={'Titre'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.profile?.title || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Structure</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='profile.structure'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.profile?.structure || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Ville</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='profile.city'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.profile?.city || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Email</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputEmail
                          name='email'
                          control={control}
                          required
                          className={'primary'}
                          placeholder={'mail@mail.com'}
                        />
                        {errors?.email
                          && <ErrorField message={errors?.email?.message} />
                        }
                      </>
                    : <p className={styles.bold}>{user?.email || <span>non complété</span>}</p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Département</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='profile.dept'
                          control={control}
                          className={'primary'}
                          placeholder={'Département'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.profile?.dept || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>
            {watch('type') === 'Pharmacist'
              && <div className={styles.block}>
                <label>Code</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='code'
                          control={control}
                          className={'primary'}
                          placeholder={'Code'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {user?.code || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>
            }

            <div className={`${styles.block} ${styles.check}`}>
              <div className={styles.value}>
                <Checkbox
                  name='isActive'
                  control={control}
                  className={'primary'}
                  label={'Utilisateur actif'}
                />
              </div>
            </div>

            <div className={`${styles.block}`}>
              <label>Consentement CNIL</label>
              <div className={styles.value}>
                <p className={styles.bold}>
                  {user?.consentDate ? format(new Date(user?.consentDate), 'dd/MM/yyyy') : <span>non validé</span>}
                </p>
              </div>
            </div>

          </div>

         {user?._id
          && <button
              type="button"
              onClick={() => inviteUserAction(dispatch, user._id)}
              className={styles['btn-invit']}>
              <HiOutlineMail size={20} /> {"Inviter l'utilisateur"}
            </button>
          }

          </section>

          {!user?._id
            && <section>
              <h3>Mot de passe</h3>
              <div className={styles['input-list']}>
                <div className={styles.block}>
                  <label>Mot de passe</label>
                  <div className={styles.value}>
                    {isEdit
                      && <>
                          <InputPassword
                            name='password'
                            control={control}
                            rules={{ required: 'le mot de passe est requis' }}
                            className={'primary'}
                            placeholder={'Nom'}
                          />
                          {errors?.password
                            && <ErrorField message={errors?.password?.message} />
                          }
                        </>
                    }
                  </div>
                </div>
              </div>
            </section>
          }

          <section>
            <h3>Type</h3>
            <div className={styles.select}>
              {isEdit
                ? <>
                    <StyledSelect
                      name="type"
                      control={control}
                      value={typesOptions.find((o) => o.value === watch('type'))}
                      rules={{ required: 'le type est requis' }}
                      onChange={(opt) => setValue('type', opt.value)}
                      placeholder="Séléctionner un type..."
                      options={typesOptions}
                    />
                    {errors?.type
                      && <ErrorField message={errors?.type?.message} />
                    }
                  </>
                : <p className={styles['selected-option']}>{typesOptions.find((o) => o.value === watch('type'))?.label}</p> || <span>non complété</span>
              }
            </div>
          </section>

          {watch('type') === 'Doctor'
            && <section>
              <h3>Centre et Service</h3>
              <div className={styles.select}>
                {isEdit
                  && <>
                      <StyledSelect
                        name="center"
                        control={control}
                        value={centersOptions.find((o) => o.value === watch('center'))}
                        onChange={(opt) => setValue('center', opt.value)}
                        placeholder="Séléctionner un centre..."
                        options={centersOptions}
                      />
                      {servicesOptions?.length
                        && <div className={styles.service}>
                          <StyledSelect
                            name="service"
                            control={control}
                            value={servicesOptions.find((o) => o.value === watch('service'))}
                            onChange={(opt) => setValue('service', opt.value)}
                            placeholder="Séléctionner un service..."
                            options={servicesOptions}
                          />
                      </div>
                      }
                    </>
                }
                {!isEdit
                  && <>
                    {centersOptions.find((o) => o.value === watch('center'))?.label
                      ? <p className={styles['selected-option']}>
                        {centersOptions.find((o) => o.value === watch('center'))?.label}
                        {watch('service') ? ` / ${servicesOptions.find((o) => o.value === watch('service'))}` : ''}
                        </p>
                      : <span>non complété</span>
                    }
                  </>
                }
              </div>
            </section>
          }

          <section>
            <h3>Rôle</h3>
            <div className={styles.select}>
              {isEdit
                ? <>
                    <StyledSelect
                      name="role"
                      control={control}
                      value={rolesOptions.find((o) => o.value === watch('role'))}
                      rules={{ required: 'le rôle est requis' }}
                      onChange={(opt) => setValue('role', opt.value)}
                      placeholder="Selectionner un rôle..."
                      options={rolesOptions}
                    />
                    {errors?.role
                      && <ErrorField message={errors?.role?.message} />
                    }
                  </>
                : <p className={styles['selected-option']}>{rolesOptions.find((o) => o.value === watch('role'))?.label}</p> || <span>non complété</span>
              }
            </div>
          </section>

          {divisions && watch('type') === 'Octapharma'
            && <section>
              <h3>Départements</h3>
              <div className={styles.select}>
                <TagsList
                  isEdit={isEdit}
                  options={divisions || []}
                  selected={watch('division') || []}
                  handleChange={(array) => setValue('division', array)}
                  />
              </div>
          </section>
          }

        {watch('type') === 'Doctor'
            && <section>
              <h3>Spécialités</h3>
              <div className={styles.select}>
                <TagsList
                  isEdit={isEdit}
                  options={specialities || []}
                  selected={watch('specialities') || []}
                  handleChange={(array) => setValue('specialities', array)}
                  />
              </div>
          </section>
          }

        </div>
        <div className={`${styles.col} ${styles.product}`}>
          <section>
            <h3>Produits</h3>
            {products?.map((p) => <ProductRole
              key={p._id}
              isEdit={isEdit}
              selectedProducts={watch('tools') || []}
              handleChange={(array) => setValue('tools', array)}
              product={p}/>)}
          </section>
        </div>
      </main>
    </form>
  );
}
