import React, { useRef, useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import FormList from '../../../FormList/FormList';
// import SentenceList from '../../../SentenceList/SentenceList';

import styles from './Studies.module.scss';

export default function Studies({
  setValue,
  watch,
  // control,
  activeForm,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <div className={styles.box}>
          <h3>Études</h3>
          <label className={styles.bold}>Étude pivot</label>
          <div className={styles.list}>
            {watch('studies.pivotal')?.map((s) => <div
                key={s._id}
                className={styles.item}
              >
              <a
                className={styles.bold}
                href={s.url}
                target='_blank'
                rel="noreferrer"
              >
                {s?.name}
              </a>
              <button
                type="button"
                onClick={() => setValue('studies.pivotal', watch('studies.pivotal').filter((sp) => sp?.name !== s.name || sp?._id !== s._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('studies.pivotal') || []}
            handleChange={(arr) => setValue('studies.pivotal', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'Nom',
                key: 'name',
                type: 'text',
              },
              {
                label: 'Url',
                key: 'url',
                type: 'text',
              },
            ]}
          />
          <div className={styles.border}>
            <label className={styles.bold}>Articles</label>
            <div className={styles.list}>
              {watch('studies.articles')?.map((s) => <div
                  key={s._id}
                  className={styles.item}
                >
                <a
                  className={styles.bold}
                  href={s.url}
                  target='_blank'
                  rel="noreferrer"
                >
                  {s?.name}
                </a>
                <button
                  type="button"
                  onClick={() => setValue('studies.articles', watch('studies.articles').filter((sp) => sp?.name !== s.name || sp?._id !== s._id))}
                  >
                  <AiFillDelete size={20} />
                </button>
              </div>)}
            </div>
            <FormList
              list={watch('studies.articles') || []}
              handleChange={(arr) => setValue('studies.articles', arr)}
              btn="Ajouter"
              model={[
                {
                  label: 'Nom',
                  key: 'name',
                  type: 'text',
                },
                {
                  label: 'Url',
                  key: 'url',
                  type: 'text',
                },
              ]}
            />
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>Étude</label>
            <div className={styles.list}>
              {watch('studies.studies')?.map((s) => <div
                  key={s._id}
                  className={styles.item}
                >
                <a
                  className={styles.bold}
                  href={s.url}
                  target='_blank'
                  rel="noreferrer"
                >
                  {s?.name}
                </a>
                <button
                  type="button"
                  onClick={() => setValue('studies.studies', watch('studies.studies').filter((sp) => sp?.name !== s.name || sp?._id !== s._id))}
                  >
                  <AiFillDelete size={20} />
                </button>
              </div>)}
            </div>
            <FormList
              list={watch('studies.studies') || []}
              handleChange={(arr) => setValue('studies.studies', arr)}
              btn="Ajouter"
              model={[
                {
                  label: 'Nom',
                  key: 'name',
                  type: 'text',
                },
                {
                  label: 'Url',
                  key: 'url',
                  type: 'text',
                },
              ]}
            />
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>Liens</label>
            <div className={styles.list}>
              {watch('studies.web')?.map((s) => <div
                  key={s._id}
                  className={styles.item}
                >
                <a
                  className={styles.bold}
                  href={s.url}
                  target='_blank'
                  rel="noreferrer"
                >
                  {s?.name}
                </a>
                <button
                  type="button"
                  onClick={() => setValue('studies.web', watch('studies.web').filter((sp) => sp?.name !== s.name || sp?._id !== s._id))}
                  >
                  <AiFillDelete size={20} />
                </button>
              </div>)}
            </div>
            <FormList
              list={watch('studies.web') || []}
              handleChange={(arr) => setValue('studies.web', arr)}
              btn="Ajouter"
              model={[
                {
                  label: 'Nom',
                  key: 'name',
                  type: 'text',
                },
                {
                  label: 'Url',
                  key: 'url',
                  type: 'text',
                },
              ]}
            />
          </div>
        </div>
      </main>
    </div>
  );
}
