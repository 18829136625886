import {
  getData, putData, postData,
} from './index';

import {
  ERROR_DRUG,
  GET_DRUGS_LIST,
  GET_DRUGS,
  GET_DRUG,
  PUT_DRUG,
  POST_DRUG,
  SET_TOAST,
} from './types';

export async function getDrugsAction(dispatch) {
  const url = '/drug';
  let drugs;

  await getData(ERROR_DRUG, url, dispatch, true).then((response) => {
    drugs = response.drugs;
  });

  dispatch({
    type: GET_DRUGS,
    payload: drugs,
  });
}

export async function getDrugsListAction(dispatch) {
  const url = '/drug/list';
  let list;

  await getData(ERROR_DRUG, url, dispatch, true).then((response) => {
    list = response.lists;
  });

  dispatch({
    type: GET_DRUGS_LIST,
    payload: list,
  });
}

export async function getDrugAction(dispatch, id) {
  const url = `/drug/${id}?withVariations=true`;
  let res;

  await getData(ERROR_DRUG, url, dispatch, true).then((response) => {
    res = response;
  });
  if (res.drug) {
    dispatch({
      type: GET_DRUG,
      payload: res,
    });
  }
}

export async function postDrugAction(dispatch, data) {
  const url = '/drug';
  let drug;

  await postData(POST_DRUG, ERROR_DRUG, url, dispatch, data, true).then((response) => {
    drug = response.drug;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le produit a été creé',
    },
  });

  dispatch({
    type: POST_DRUG,
    payload: drug,
  });
}

export async function putDrugAction(dispatch, id, data) {
  const url = `/drug/${id}`;
  let drug;

  await putData(PUT_DRUG, ERROR_DRUG, url, dispatch, data, true).then((response) => {
    drug = response.drug;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le produit a été modifié',
    },
  });

  dispatch({
    type: PUT_DRUG,
    payload: drug,
  });
}
