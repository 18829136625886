import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, InputDate, InputText } from '../../../../../lib/HooksFormFields';
import { isValidDate } from '../../../../../utils/utils';
import styles from './CipModal.module.scss';

export default function CipModal({
  cip, cips, changeValue, close,
}) {
  const {
    control,
    watch,
  } = useForm({
    defaultValues: {
      ...cip,
      firstDate: isValidDate(cip.firstDate) ? new Date(cip.firstDate) : new Date(),
      stopDate: isValidDate(cip.firstDate) ? new Date(cip.stopDate) : new Date(),
    },
  });

  const form = watch();

  function handleChange() {
    const updatedCips = [...cips];
    if (cip?.index >= 0) {
      updatedCips[cip?.index] = form;
    } else {
      updatedCips.push(form);
    }
    changeValue(updatedCips);
    close();
  }

  function deleteCip() {
    changeValue(cips.filter((h, i) => i !== cip?.index));
    close();
  }

  return (
    <div className={styles.container}>
      <h3>{cip?.name || 'Ajouter un cip'}</h3>
          <div className={styles.row}>
            <div className={`${styles.col}`}>
              <div className={styles['form-group']}>
                <label>N°CIP</label>
                <div className={styles.value}>
                  <InputText
                    name='number'
                    control={control}
                    className={'primary'}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.col}`}>
              <div className={styles['form-group']}>
                <label>Libellé</label>
                <div className={styles.value}>
                  <InputText
                    name='label'
                    control={control}
                    className={'primary'}
                  />
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles['form-group']}>
                <label>Date 1er commerce</label>
                <div className={styles.value}>
                  <InputDate
                    name='firstDate'
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles['form-group']}>
                <label>Date arrêt</label>
                <div className={styles.value}>
                  <InputDate
                    name='stopDate'
                    control={control}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.col} ${styles.url}`}>
              <div className={styles['form-group']}>
                <label>Lien</label>
                <div className={styles.value}>
                  <InputText
                    name='url'
                    control={control}
                    className={'primary'}
                  />
                </div>
              </div>
            </div>
          </div>
      <div className={styles.btns}>
        <Button
          className={styles.edit}
          type='button'
          handleClick={() => handleChange()}
        >
          Valider
        </Button>
        {cip?.index >= 0
        && <button
          type="button"
          className={styles.delete}
          onClick={() => deleteCip(cips.filter((h, i) => i !== cip?.index))}
        >
          Supprimer
        </button>
        }
      </div>
    </div>
  );
}
