import React from 'react';
import FilesPreview from '../../../FilesPreview/FilesPreview';

import styles from './Reports.module.scss';

export default function Reports({
  congress,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
              <section>
                <h3>Compte rendu</h3>
                {congress?.reports?.length > 0
                  && <FilesPreview key='reports' files={congress?.reports} />
                }
              </section>
            </div>
        </div>
      </div>
    </div>
  );
}
