import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import { IoChevronDownOutline } from 'react-icons/io5';
import styles from './Meeting.module.scss';

export default function Meeting({ meetings }) {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   setIsOpen(true);
  // }, []);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h2>RÉTROPLANNING GLOBAL</h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && contentRef?.current?.clientHeight > 0 ? contentRef.current.clientHeight + 20 : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          <div className={styles.list}>
            {meetings.map((m, i) => <div key={m._id}
              className={styles['meeting-row']}
              >
              <div className={styles.name}>
                <p>{m.title || `RÉUNION ${i + 1}`}</p>
              </div>
              <div className={styles.meeting}>
                <div className={styles.row}>
                  {m?.date
                    && <div className={`${styles.col} ${styles.icon}`}>
                      <FiCalendar size={20}/>
                      <p>
                        <label>Date : </label>
                        <span className={styles.bold}>{format(new Date(m.date), 'dd/MM/yyyy') }</span>
                      </p>
                    </div>
                  }
                  {m?.participants.length > 0
                    && <div className={`${styles.col} ${styles.icon} ${styles.user}`}>
                      <AiOutlineUser size={20}/>
                      <p>
                        <label>Participants : </label>
                        {m?.participants.map((p, index) => <span
                          key={p._id}>{p.firstName} {p.lastName}{index + 1 < m?.participants.length && ', '}</span>)}
                      </p>
                    </div>
                  }
                </div>
                <div>
                  <label>Compte-rendu</label>
                  <p>{m.cr}</p>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </section>
  );
}
