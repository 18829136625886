import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { getCentersAction } from '../../../actions/centersActions';
import { GET_CENTER, SET_CENTER_SCROLL } from '../../../actions/types';

// import { SET_USERS_FILTERS } from '../../../actions/types';
import CenterRow from '../../../components/Centers/CenterRow/CenterRow';
import CentersFilters from '../../../components/Centers/CentersFilters/CentersFilters';
import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';
// import ToolsFilters from '../../../components/Tools/ToolsFilters/ToolsFilters';
import styles from './CentersList.module.scss';

export default function CentersList() {
  const { centersList, filters, scrollY } = useSelector((state) => state.centers);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    name: true,
    city: true,
    services: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getCentersAction(dispatch);
    dispatch({
      type: GET_CENTER,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'city':
        list = list.sort((a, b) => {
          if (a?.location?.city < b?.location?.city) return -1;
          if (a?.location?.city > b?.location?.city) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'services':
        list = list.sort((a, b) => {
          if (a?.services.length < b?.services.length) return -1;
          if (a?.services.length > b?.services.length) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      default:
        break;
    }
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    if (filters?.cities?.length) {
      val += filters?.cities?.length;
    }
    return val;
  }

  function handleScroll(e) {
    dispatch({
      type: SET_CENTER_SCROLL,
      payload: e.target.scrollTop,
    });
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    let list = centersList;
    if (filters.search) {
      list = list.filter((t) => {
        if (t.name.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        if (t?.location?.city?.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        return null;
      });
    }
    if (filters.cities.length) {
      list = list.filter((c) => {
        if (filters.cities.find((f) => f?.toLowerCase() === c?.location?.city?.toLowerCase())) {
          return c;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [centersList, filters]);

  useEffect(() => {
    if (scrollY && filteredList) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [scrollY, filteredList]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>{'CENTRES & SERVICES'}</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/center/create?form=0')}>
              NOUVEAU CENTRE
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <SortButton
              name={'Ville'}
              handleClick={() => sortList('city', !sortByReverse.city)}
              state={sortByReverse.city}
              className={`${styles.col}`}/>
            <SortButton
              name={'Services'}
              handleClick={() => sortList('services', !sortByReverse.services)}
              state={sortByReverse.services}
              className={`${styles.col} ${styles.desktop}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          onScroll={(e) => handleScroll(e)}
          className={styles.list}
          >
          {!isLoading && filteredList.length > 0 && filteredList?.map((c, i) => <CenterRow
            key={c._id}
            center={c}
            index={i}
            styles={styles}
          />)}
        </div>
      </div>
      <CentersFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
