import React, {
  useRef, useEffect, useCallback,
  useState,
} from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { generateKey } from '../../../../utils/utils';
import { getDrugsAction } from '../../../../actions/drugsActions';
import {
  InputDate,
  ErrorField, InputText, StyledSelect, Textarea,
} from '../../../../lib/HooksFormFields';

import { getCentersAction } from '../../../../actions/centersActions';

import InputFile from '../../../InputFile/InputFile';
import { API_URL } from '../../../../actions';
import { deleteFileAction } from '../../../../actions/filesActions';

import styles from './Infos.module.scss';

export default function Infos({
  control,
  errors,
  watch,
  setValue,
  submit,
  activeForm,
  therapeuticAreasList,
}) {
  const dispatch = useDispatch();
  const drugs = useSelector((state) => state.drugs);
  const [drugsOptions, setDrugsOptions] = useState([]);
  // const booleanOptions = [{ value: true, label: 'Oui' }, { value: false, label: 'Non' }];
  const containerRef = useRef();

  const getDatas = useCallback(() => {
    getCentersAction(dispatch);
    getDrugsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (drugs.drugsList.length) {
      const options = [];
      drugs.drugsList.forEach((d) => {
        options.push({
          label: d.name,
          value: d._id,
        });
      });
      setDrugsOptions(options);
    }
  }, [drugs.drugsList]);

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  function deleteCoverFile(id) {
    deleteFileAction(dispatch, id);
    setValue('cover', null);
    submit({
      ...watch(),
      cover: null,
    });
  }

  function deleteRoadbookFile(id) {
    deleteFileAction(dispatch, id);
    submit({
      ...watch(),
      roadbooks: watch('roadbooks').map((f) => f._id).filter((f) => f !== id),
    });
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>INFORMATIONS Générales</h3>
              <div className={styles['input-block']}>
                <label>Nom du congrès</label>
                <div className={styles.input}>
                  <InputText
                    name='name'
                    control={control}
                    className={'primary'}
                    rules={{ required: 'Le nom est requis' }}
                    placeholder={'Nom'}
                  />
                  {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Description</label>
                <div className={styles.input}>
                  <Textarea
                    name='description'
                    control={control}
                    className={'primary'}
                    rules={{ required: 'La description est requise' }}
                    placeholder={'description'}
                  />
                  {errors?.description?.message
                  && <ErrorField message={errors?.description?.message} />}
                </div>
              </div>
              <div className={styles['input-row']}>
                <div className={styles['input-block']}>
                  <label>Date de début</label>
                  <div className={styles.input}>
                    <InputDate
                      name="startDate"
                      type="datetime-local"
                      control={control}
                      widthLabel={'30%'}
                      placeholder={''}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['input-row']}>
                <div className={styles['input-block']}>
                  <label>Date de fin</label>
                  <div className={styles.input}>
                    <InputDate
                      name="endDate"
                      type="datetime-local"
                      control={control}
                      widthLabel={'30%'}
                      placeholder={''}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles['input-row']} ${styles.location}`}>
                <div className={styles['input-block']}>
                  <label>Lieu de l’évènement</label>
                  <div className={styles.input}>
                    <InputText
                      name='location'
                      control={control}
                      className={'primary'}
                      rules={{ }}
                      placeholder={'Lieu de l’évènement'}
                    />
                  </div>
                </div>
                <div className={styles['input-block']}>
                  <label>Ville</label>
                  <div className={styles.input}>
                    <InputText
                      name='city'
                      control={control}
                      className={'primary'}
                      rules={{ }}
                      placeholder={'Ville'}
                    />
                  </div>
                </div>
              </div>

              <div className={styles['input-block']}>
                <label>Url</label>
                <div className={styles.input}>
                  <InputText
                    name='url'
                    control={control}
                    className={'primary'}
                    rules={{ }}
                    placeholder={'Url de l’évènement'}
                  />
                </div>
              </div>
              <div className={`${styles['file-section']}`}>
                <label>Image de couverture</label>
                <div className={`${styles.list}`}>
                  {watch('cover')?.path
                    ? <div
                      className={styles.file}
                    >
                      <a href={`${API_URL }/${ watch('cover')?.path}`} target='_blank' rel="noreferrer">
                        {watch('cover')?.name || 'fichier'}
                      </a>
                      <button
                        type="button"
                        onClick={() => deleteCoverFile(watch('cover')._id)}
                        >
                        <AiFillDelete size={20} />
                      </button>
                    </div>
                    : <div className={styles['input-file']}>
                      <InputFile
                        activeFile={null}
                        label={'Ajouter une image de couverture'}
                        handleChange={(fileId) => submit({
                          ...watch(),
                          cover: fileId,
                        })}
                        name={'cover'}
                        />
                    </div>
                  }
                </div>

              </div>
            </section>
          </div>
          <div className={styles.box}>
            <section>
            <h3>PRODUITS</h3>
              <div className={styles['input-block']}>
                <label>Aire thérapeutiques</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="therapeuticAreas"
                    control={control}
                    value={therapeuticAreasList?.filter((o) => watch('therapeuticAreas')?.find((t) => o.value === t || o.value === t._id))}
                    onChange={(array) => setValue('therapeuticAreas', array.map((t) => t.value))}
                    isMulti
                    placeholder="Selectionner un aire thérapeutique..."
                    options={therapeuticAreasList}
                  />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Produits</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="drugs"
                    control={control}
                    value={drugsOptions?.filter((o) => watch('drugs')?.find((t) => o.value === t || o.value === t._id))}
                    onChange={(array) => setValue('drugs', array.map((t) => t.value))}
                    isMulti
                    placeholder="Selectionner un produit..."
                    options={drugsOptions}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className={styles.box}>
            <section>
              <h3>Rationnel de participation</h3>
              <div className={styles['input-block']}>
                <div className={styles.input}>
                  <Textarea
                    name='rational'
                    control={control}
                    className={'primary'}
                    rules={{ }}
                    placeholder={''}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className={styles.box}>
            <section>
              <h3>INSTRUCTIONS LEA / TRANSPARENCE DES LIENS / REMBOURSEMENT DE FRAIS</h3>
              <div className={styles['input-block']}>
                <div className={styles.input}>
                  <Textarea
                    name='dmos'
                    control={control}
                    className={'primary'}
                    rules={{ }}
                    placeholder={''}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className={styles.box}>
            <section>
              <h3>ROAD BOOK</h3>
              <div className={`${styles['file-section']}`}>
                <div className={`${styles.list}`}>
                  {watch('roadbooks')?.map((f, i) => {
                    // eslint-disable-next-line prefer-template
                    const url = API_URL + '/' + f?.path;
                    return <div key={f?._id || generateKey('roadbook', i)} className={styles.file}>
                      <a href={url} target='_blank' rel="noreferrer">
                        {f?.name || 'fichier'}
                      </a>
                      <button
                        type="button"
                        onClick={() => deleteRoadbookFile(f._id)}
                        >
                        <AiFillDelete size={20} />
                      </button>
                    </div>;
                  })}
                </div>
                <div className={styles['input-file']}>
                  <InputFile
                    activeFile={null}
                    handleChange={(fileId) => submit({
                      ...watch(),
                      roadbooks: [...watch('roadbooks').map((f) => f._id), fileId],
                    })}
                    name={'roadbooks'}
                    />
                </div>
              </div>
            </section>
          </div>

        </div>
      </div>
    </div>
  );
}
