import React, { useRef, useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import FormList from '../../../FormList/FormList';
import SentenceList from '../../../SentenceList/SentenceList';

import styles from './Data.module.scss';

export default function Data({
  setValue,
  watch,
  activeForm,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <BoxPanel
          label={'INCOMPATIBILITÉS'}
          >
          <SentenceList
            isEdit={true}
            list={watch('data.incompatibilities') || []}
            handleChange={(arr) => setValue('data.incompatibilities', arr)}
            label='Ajouter incompatibilité'
            btn='Ajouter'
          />
        </BoxPanel>
        <BoxPanel
          label={'CONSERVATION'}
          >
          <div className={styles.list}>
            {watch('data.conservation')?.map((d) => <div
              key={d._id}
              className={styles.item}
              >
              <p className={styles.bold}>{d?.situation}</p>
              <p>{d?.duration} {d?.durationUnit}</p>
              <p>{d?.temperature}</p>
              <button
                type="button"
                onClick={() => setValue('data.conservation', watch('data.conservation').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('data.conservation') || []}
            handleChange={(arr) => setValue('data.conservation', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'Situation',
                key: 'situation',
                type: 'text',
              },
              {
                label: 'Durée',
                key: 'duration',
                type: 'number',
              },
              {
                label: 'Unité de durée',
                key: 'durationUnit',
                type: 'text',
              },
              {
                label: 'Température',
                key: 'temperature',
                type: 'text',
              },
            ]}
            />
        </BoxPanel>
      </main>
    </div>
  );
}
