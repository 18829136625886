import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPathologiesAction } from '../../../../actions/pathologiesActions';
import { Button } from '../../../../lib/HooksFormFields';

import Modal from '../../../../lib/ModalComponents/Modal';
import AddPathology from './AddPathology/AddPathology';
import PathologieForm from './PathologieForm/PathologieForm';

import styles from './Pathologies.module.scss';

export default function Pathologies({
  watch, activeForm, submit, setValue,
}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const { pathologiesList } = useSelector((state) => state.pathologies);
  const [service, setService] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getDatas = useCallback(() => {
    getPathologiesAction(dispatch);
  }, [dispatch]);

  const services = watch('services');

  const servicePathologies = services?.find((s) => s._id === service)?.pathologies;

  function submitService(obj) {
    const list = services;
    const index = services.findIndex((s) => s._id === service);
    const selected = services.find((s) => s._id === service);
    if (index !== -1) {
      list[index] = { ...selected, ...obj };
    }
    submit({ ...watch(), services: list });
  }

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.select}>
          {services?.map((s) => <button
              key={s._id}
              type='button'
              className={service === s._id ? styles.active : ''}
              onClick={() => setService(s._id)}
            >
              {s.name}
            </button>)}
        </div>
        <div className={styles.col}>
          {service
            && <div className={styles.add}>
              <Button
                handleClick={() => setIsModalOpen(true)}
              >
                Ajouter une pathologie
              </Button>
            </div>
          }
          {servicePathologies?.map((p) => <PathologieForm
            key={p.pathology}
            pathology={p}
            pathologies={servicePathologies}
            pathologyName={pathologiesList?.find((a) => a._id === p.pathology)?.name}
            handleChangePathologies={(array) => setValue('pathologies', array)}
          />)}
        </div>
      </div>
      <Modal toggle={() => setIsModalOpen(false)} open={isModalOpen}>
        {isModalOpen && service
          && <AddPathology
            pathologies={services?.find((s) => s._id === service)?.pathologies || [] }
            pathologiesOptions={pathologiesList.filter((p) => p.form.length > 0)}
            submit={(obj) => submitService(obj)}
          />
        }
      </Modal>
    </div>
  );
}
