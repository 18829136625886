import React, { useState } from 'react';
import { StyledSelect } from '../../lib/HooksFormFields';
import SentenceList from '../SentenceList/SentenceList';
import styles from './FormList.module.scss';

export default function FormList({
  list, handleChange, btn, model, options, placeholder, control = null, sentencesList,
}) {
  const [value, setValue] = useState();

  function onChange(val) {
    if (!val) return null;
    setValue();
    return handleChange([...list, val]);
  }

  return (
    <div className={styles.list}>
      <div className={styles['add-item']}>
        {options
          ? <div className={styles['select-container']}>
              <div className={styles.select}>
                <StyledSelect
                  name="type"
                  control={control}
                  value={options.find((o) => o.value === value?.value)}
                  onChange={(opt) => setValue(opt.value)}
                  placeholder={placeholder || ''}
                  options={options}
                />
              </div>
              <button type="button" onClick={() => onChange(value)}>
              {btn}
            </button>
          </div>
          : <>
          <div className={styles['input-container']}>
            {model && model.map((m, i) => {
              const index = `${i}-input-${m.label}`;
              return (
                <div key={index} className={`${styles.input} ${model.length > 1 ? styles[`col-${model.length}`] : ''}`}>
                  <label>{m.label}</label>
                  <input
                    value={(value && value[m.key]) || (value && value[m.key] === 0) ? value[m.key] : ''}
                    type={m.type}
                    onChange={(e) => setValue({ ...value, [m.key]: m.type === 'number' ? parseFloat(e.target.value) : e.target.value })}
                    />
                </div>
              );
            })}
          </div>

            {sentencesList?.map((s) => (
              <div
                key={s.key}
                className={styles['sentence-container']}
              >
                <SentenceList
                  isEdit={true}
                  list={value && value[s.key] ? value[s.key] : []}
                  handleChange={(arr) => setValue({ ...value, [s.key]: arr })}
                  label={s.label}
                  btn={s.btn}
                />
              </div>
            ))}
          <button
            type="button"
            className={styles['add-btn']}
            onClick={() => onChange(value)}
          >
            {btn}
          </button>
        </>
        }

      </div>
    </div>
  );
}
