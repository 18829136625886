// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const SET_TOAST = 'SET_TOAST';
export const POST_SEARCH = 'POST_SEARCH';
// USER ACTIONS
export const ERROR_USERS = 'ERROR_USERS';
export const SET_USERS_FILTERS = 'SET_USERS_FILTERS';
export const GET_FILTERED_USERS = 'GET_FILTERED_USERS';
export const GET_USER = 'GET_USER';
export const PUT_USER = 'PUT_USER';
export const POST_USER = 'POST_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_USER_SCROLL = 'SET_USER_SCROLL';
export const GET_USERS_PRODUCTS = 'GET_USERS_PRODUCTS';
export const MESSAGE_USERS = 'MESSAGE_USERS';
export const GET_DIVISIONS = 'GET_DIVISIONS';
export const GET_SPECIALITIES = 'GET_SPECIALITIES';
export const GET_PROVIDERS_TYPES = 'GET_PROVIDERS_TYPES';
export const GET_OCTA_USER = 'GET_OCTA_USER';
export const GET_ALL_USER = 'GET_ALL_USER';
// TOOLS ACTIONS
export const ERROR_TOOL = 'ERROR_TOOL';
export const GET_TOOLS = 'GET_TOOLS';
export const GET_TOOL = 'GET_TOOL';
export const PUT_TOOL = 'PUT_TOOL';
export const POST_TOOL = 'POST_TOOL';
export const SET_TOOLS_FILTERS = 'SET_TOOLS_FILTERS';
export const GET_TOOLS_DIVISIONS = 'GET_TOOLS_DIVISIONS';
export const GET_TOOLS_TYPES = 'GET_TOOLS_TYPES';
export const MESSAGE_TOOL = 'MESSAGE_TOOL';
// FILE ACTIONS
export const ERROR_FILE = 'ERROR_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const POST_FILE = 'POST_FILE';
// DRUGS ACTIONS
export const ERROR_DRUG = 'ERROR_DRUG';
export const GET_DRUGS = 'GET_DRUGS';
export const GET_DRUGS_LIST = 'GET_DRUGS_LIST';
export const GET_DRUG = 'GET_DRUG';
export const PUT_DRUG = 'PUT_DRUG';
export const POST_DRUG = 'POST_DRUG';
export const SET_DRUG_SCROLL = 'SET_DRUG_SCROLL';
export const SET_DRUGS_FILTERS = 'SET_DRUGS_FILTERS';
// CENTERS ACTIONS
export const ERROR_CENTER = 'ERROR_CENTER';
export const GET_CENTERS = 'GET_CENTERS';
export const GET_CENTER = 'GET_CENTER';
export const SET_CENTER_SCROLL = 'SET_CENTER_SCROLL';
export const PUT_CENTER = 'PUT_CENTER';
export const POST_CENTER = 'POST_CENTER';
export const SET_CENTERS_FILTERS = 'SET_CENTERS_FILTERS';
// PATHOLOGIES ACTIONS
export const ERROR_PATHOLOGY = 'ERROR_PATHOLOGY';
export const GET_PATHOLOGIES = 'GET_PATHOLOGIES';
export const GET_PATHOLOGY = 'GET_PATHOLOGY';
export const PUT_PATHOLOGY = 'PUT_PATHOLOGY';
export const POST_PATHOLOGY = 'POST_PATHOLOGY';
export const SET_PATHOLOGIES_FILTERS = 'SET_PATHOLOGIES_FILTERS';
//  THERAPEUTIC AREA ACTIONS
export const ERROR_THERAPEUTIC_AREA = 'ERROR_THERAPEUTIC_AREA';
export const GET_THERAPEUTIC_AREAS = 'GET_THERAPEUTIC_AREAS';
export const GET_THERAPEUTIC_AREA = 'GET_THERAPEUTIC_AREA';
export const PUT_THERAPEUTIC_AREA = 'PUT_THERAPEUTIC_AREA';
export const POST_THERAPEUTIC_AREA = 'POST_THERAPEUTIC_AREA';
export const SET_THERAPEUTIC_AREA_FILTERS = 'SET_THERAPEUTIC_AREA_FILTERS';
// CONTACT ACTIONS
export const ERROR_CONTACT = 'ERROR_CONTACT';
export const GET_FILTERED_CONTACTS = 'GET_FILTERED_CONTACTS';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACT = 'GET_CONTACT';
export const PUT_CONTACT = 'PUT_CONTACT';
export const POST_CONTACT = 'POST_CONTACT';
export const SET_CONTACT_SCROLL = 'SET_CONTACT_SCROLL';
export const SET_CONTACT_FILTERS = 'SET_CONTACT_FILTERS';
// NEWS ACTIONS
export const ERROR_NEW = 'ERROR_NEW';
export const GET_NEWS = 'GET_NEWS';
export const GET_NEW = 'GET_NEW';
export const PUT_NEW = 'PUT_NEW';
export const POST_NEW = 'POST_NEW';
export const MESSAGE_NEW = 'MESSAGE_NEW';
export const SET_NEWS_FILTERS = 'SET_NEWS_FILTERS';
// PROJECTS ACTIONS
export const ERROR_PROJECT = 'ERROR_PROJECT';
export const GET_PROJECTS_LISTS = 'GET_PROJECTS_LISTS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const PUT_PROJECT = 'PUT_PROJECT';
export const POST_PROJECT = 'POST_PROJECT';
export const SET_PROJECT_SCROLL = 'SET_PROJECT_SCROLL';
export const SET_PROJECTS_FILTERS = 'SET_PROJECTS_FILTERS';
// COMPANIES ACTIONS
export const ERROR_COMPANY = 'ERROR_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const PUT_COMPANY = 'PUT_COMPANY';
export const POST_COMPANY = 'POST_COMPANY';
export const SET_COMPANIES_FILTERS = 'SET_COMPANIES_FILTERS';
// CONGRESS ACTIONS
export const ERROR_CONGRESS = 'ERROR_CONGRESS';
export const GET_CONGRESSES_LISTS = 'GET_CONGRESSES_LISTS';
export const GET_CONGRESSES = 'GET_CONGRESSES';
export const GET_CONGRESS = 'GET_CONGRESS';
export const PUT_CONGRESS = 'PUT_CONGRESS';
export const POST_CONGRESS = 'POST_CONGRESS';
export const SET_CONGRESSES_FILTERS = 'SET_CONGRESSES_FILTERS';
