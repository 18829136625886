import { format } from 'date-fns';
import React from 'react';
import { FiCalendar, FiClock } from 'react-icons/fi';
import { FaFilePdf } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { exportCongressAction } from '../../../../actions/congressesActions';

import { isValidDate } from '../../../../utils/utils';
import FilesPreview from '../../../FilesPreview/FilesPreview';

import styles from './Infos.module.scss';

export default function Infos({
  congress,
}) {
  const dispatch = useDispatch();
  const startDate = isValidDate(congress.startDate) && new Date(congress.startDate);
  const startTime = startDate && `${startDate.getHours()}:${startDate.getMinutes()}`;
  const endDate = isValidDate(congress.endDate) && new Date(congress.endDate);
  const endTime = endDate && `${endDate.getHours()}:${endDate.getMinutes()}`;
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>INFORMATIONS Générales</h3>
              <h1>{congress?.name}</h1>
              {congress?._id && congress?.name
              && <button
                type='button'
                className={styles.export}
                onClick={() => exportCongressAction(dispatch, congress?._id, `${congress.name}.pdf`)}
              >
                Exporter le roadbook <FaFilePdf />
              </button>
              }
              <div className={styles.dates}>
              {congress?.startDate
                && <div className={styles.date}>
                  <FiCalendar size={20} />
                  <p className={styles.label}>Début du congrès</p>
                  <span>{congress?.startDate && format(new Date(startDate), 'dd/MM/yyyy')}</span>
                  <FiClock size={20} />
                  <p>Horaire</p>
                  <span>{startTime}</span>
                </div>
              }
              {congress?.endDate
                && <div className={styles.date}>
                  <FiCalendar size={20} />
                  <p className={styles.label}>Fin du congrès</p>
                  <span>{congress?.endDate && format(new Date(endDate), 'dd/MM/yyyy')}</span>
                  <FiClock size={20} />
                  <p>Horaire</p>
                  <span>{endTime}</span>
                </div>
              }
              </div>
              <label>Description</label>
              <p>{congress.description}</p>
              <label>Lieu</label>
              <p>{congress.location} - {congress.city}</p>
              {congress.url
                && <a href={congress.url} className={styles.link} target="_blank" rel="noreferrer" >{congress.url}</a>
              }
              {congress.cover
                && <div className={styles.cover}>
                  <FilesPreview key="cover" files={[congress.cover]} />
                </div>
              }
            </section>
          </div>
          {(congress?.drugs || congress?.therapeuticAreas)
            && <div className={styles.box}>
              <section>
                <h3>PRODUITS</h3>
                {congress?.drugs.length > 0
                  && <>
                    <label>Produits</label>
                    <div className={styles['drug-list']}>
                      {congress?.drugs?.map((d) => <p key={d._id}>{d.name}</p>)}
                    </div>
                  </>
                }
                {congress?.therapeuticAreas.length > 0
                  && <>
                    <label>Aire thérapeutiques</label>
                    <div className={styles['drug-list']}>
                      {congress?.therapeuticAreas?.map((d) => <p key={d._id}>{d.name}</p>)}
                    </div>
                  </>
                }
              </section>
            </div>
          }
          {congress?.rational
            && <div className={styles.box}>
              <section>
                <h3>Rationnel de participation</h3>
                <p>{congress?.rational}</p>
              </section>
            </div>
          }
          {congress?.dmos
            && <div className={styles.box}>
              <section>
                <h3>INSTRUCTIONS LEA / TRANSPARENCE DES LIENS / REMBOURSEMENT DE FRAIS</h3>
                <p>{congress?.dmos}</p>
              </section>
            </div>
          }
          {congress?.roadbooks?.length > 0
            && <div className={styles.box}>
              <section>
                <h3>ROAD BOOK</h3>
                <FilesPreview key='roadbook' files={congress?.roadbooks} />
              </section>
            </div>
          }
          {congress?.reports?.length > 0
            && <div className={styles.box}>
              <section>
                <h3>Compte rendu</h3>
                <FilesPreview key='reports' files={congress?.reports} />
              </section>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
