import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { SET_CONTACT_FILTERS } from '../../../actions/types';
import styles from './ContactFilters.module.scss';

export default function ContactFilters({
  close,
  isActive,
}) {
  const { filters } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const [search, setSearch] = useState(filters.search || '');

  function handleChangeFilters(key, value) {
    dispatch({
      type: SET_CONTACT_FILTERS,
      payload: {
        ...filters,
        page: 1,
        [key]: value,
      },
    });
  }

  return (
    <div className={`${styles.container} ${isActive ? styles['is-active'] : ''}`}>
      <div className={styles.title}>
        <h2>Filtres</h2>
        <button
          onClick={() => close()}
          className={styles.close}>
          <IoMdClose size={30} />
        </button>
      </div>
      <div className={styles.filters}>
        <label>Recherche</label>
        <div className={styles.search}>
          <input
            value={search}
            placeholder={'Votre recherche'}
            onChange={(e) => setSearch(e.target.value)}
            />
          <button
            className={`${search ? styles['is-search'] : ''}`}
            onClick={() => handleChangeFilters('search', search)}
            >
            <IoSearch size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}
