import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RiDeleteBin2Fill, RiPencilFill } from 'react-icons/ri';
// Contect & actions

import { deleteFileAction, postFileAction } from '../../actions/filesActions';
import { API_URL } from '../../actions/index';

// Styles
import styles from './InputPicture.module.scss';
import { ErrorField } from '../../lib/HooksFormFields';
import { ERROR_FILE, POST_FILE } from '../../actions/types';

function InputPicture({
  name,
  color,
  icon = false,
  isEdit = false,
  isLarge = false,
  picture,
  handleChange,
}) {
  const dispatch = useDispatch();
  const { file, error } = useSelector((state) => state.files);
  const [uploadedFile, setUploadedFile] = useState();
  const [fileError, setFileError] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type: POST_FILE,
      payload: null,
    });
    dispatch({
      type: ERROR_FILE,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    };
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (file?._id && uploadedFile) {
      if (uploadedFile) document.getElementById(name).value = '';
      setUploadedFile();
      setFileError();
      handleChange(file._id);
      dispatch({
        type: POST_FILE,
        payload: null,
      });
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(() => {
    if (error && error.data && error.data.error) {
      if (error.data.error === 'Extension not allowed.') {
        setFileError('Extension not allowed.');
        setUploadedFile();
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData) {
    setUploadedFile(fileData);
    setFileError();
    if (picture?._id) deleteFileAction(dispatch, picture._id);
    if (fileData.name) {
      const formData = new FormData();
      formData.append('file', fileData);
      formData.append('name', fileData.name);
      formData.append('alt', fileData.name.split('.')[0]);
      postFileAction(dispatch, formData);
    }
  }

  function deleteFile() {
    deleteFileAction(dispatch, picture._id);
    handleChange(null);
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.img} ${icon ? styles.icon : ''} ${isLarge && picture?._id ? styles.large : ''}`} style={{ backgroundColor: isLarge && picture?._id ? 'transparent' : color }}>
        {picture?._id
          ? <>
            {picture.mimetype === 'video/mp4'
              ? <video controls>
                <source
                  src={`${API_URL}/${picture.path}`}
                  type={picture.mimetype}>
                </source>
              </video>
              : <img src={`${API_URL}/${picture.path}`} alt={picture.alt}/>
            }
            {isEdit
              && <button
              type="button"
              onClick={() => deleteFile()}
              >
              <RiDeleteBin2Fill size={20} />
            </button>
            }
          </>
          : <>
          {isEdit
            && <div className={styles.input}>
              <label htmlFor={name}>
                <RiPencilFill size={20} color={'#FFFFFF'}/>
              </label>
              <input type="file" name={name} onChange={(e) => handleChangeFile(e.target.files[0]) } id={name} />
          </div>
          }
          </>
        }
      </div>
      {fileError && <ErrorField message={fileError}/>}
    </div>
  );
}

InputPicture.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
};

export default InputPicture;
