import React from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { Link } from 'react-router-dom';
import { typesOptions, rolesOptions } from '../../../constants/constants';
import ProductIcon from '../../ProductIcon/ProductIcon';

import pen from '../../../assets/images/icons/pen.svg';

export default function UsersRow({
  styles,
  user,
}) {
  const { products } = useSelector((state) => state.users);
  return (
    <Link to={`/user/${user._id}`} className={styles.row}
      // style={{ animationDelay: `${0.08 * user.index}s` }}
    >
      <div className={styles.col}>
        {user?.profile?.firstName
          && <p>{user?.profile?.firstName.toUpperCase()} {user?.profile?.lastName}</p>
        }
        {!user?.profile?.firstName && user?.profile?.structure
          && <p>{user?.profile?.structure.toUpperCase()}</p>
        }
      </div>
      <div className={`${styles.col} ${styles.desktop} ${styles.type}`}>
        <p>{rolesOptions.find((item) => item.value === user?.role)?.label}</p>
      </div>
      <div className={`${styles.col} ${styles.type}`}>
        <p>{typesOptions.find((item) => item.value === user?.type)?.label}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop} ${styles.products}`}>
        {products?.filter((p) => (user.tools.find((t) => t.tool === p._id) ? p : null))
          ?.sort((a, b) => (a.title > b.title ? 1 : -1)).map((item) => (
          <ProductIcon key={uniqid()} name={item.title} size={35}/>))}
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
