import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDrugsAction } from '../../../../actions/drugsActions';
import { getProjectsAction } from '../../../../actions/projectsActions';

import { Button } from '../../../../lib/HooksFormFields';

import Service from './Service/Service';

import styles from './Services.module.scss';

export default function Services({
  watch, activeForm, setValue, centerId, submit,
}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const drugs = useSelector((state) => state.drugs);
  const projectsOptions = useSelector((state) => state.projects)?.projectsList?.map(
    (p) => ({
      value: p,
      label: p.name,
      type: p.type,
      centers: p.centers,
    }),
  );
  const services = watch('services') ? watch('services') : [];
  const [drugsOptions, setDrugsOptions] = useState();

  function handleChangeService(service = null) {
    const index = services.findIndex((s) => s._id === service?._id);
    if (index >= 0) {
      services[index] = service;
      setValue('services', services);
    } else {
      submit({ services: [{ name: '', donations: [], drugs: [] }, ...services] });
    }
  }

  const getDatas = useCallback(() => {
    getDrugsAction(dispatch);
    getProjectsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  useEffect(() => {
    if (drugs.drugsList.length) {
      const options = [];
      drugs.drugsList.forEach((d) => {
        options.push({
          label: d.name,
          value: d._id,
        });
      });
      setDrugsOptions(options);
    }
  }, [drugs.drugsList]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.btn}>
      <Button
        handleClick={() => handleChangeService()}
        >
        AJOUTER UN SERVICE
      </Button>
      </div>
      <div className={styles.row}>
        {services?.map((s) => <Service
          key={s._id}
          service={s}
          centerId={centerId}
          drugsOptions={drugsOptions}
          projectsOptions={projectsOptions?.filter(
            (p) => !p?.centers?.find((c) => c.service === s?._id),
          )}
          projects={projectsOptions?.filter(
            (p) => p?.centers?.find((c) => c.service === s?._id),
          )}
          handleChange={(value) => handleChangeService(value)}
        />)}
      </div>
    </div>
  );
}
