import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { IoChevronBack, IoSave } from 'react-icons/io5';
// import { AiFillDelete } from 'react-icons/ai';

import Button from '../../../lib/HooksFormFields/Button';
import Infos from '../../../components/Centers/CenterForm/Infos/Infos';

import styles from './EditCenter.module.scss';
import { putCenterAction, postCenterAction, getCenterAction } from '../../../actions/centersActions';
import Services from '../../../components/Centers/CenterForm/Services/Services';
import Pathologies from '../../../components/Centers/CenterForm/Pathologies/Pathologies';

export default function EditCenter() {
  const id = useParams()?.id;
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const { center } = useSelector((state) => state.centers);
  const navRef = useRef();
  const nav = [
    {
      value: 0,
      label: 'Informations',
    },
    {
      value: 1,
      label: 'Services',
    },
    {
      value: 2,
      label: 'Pathologies',
    },
  ];
  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).form);

  function handleChangeForm(val) {
    setActiveForm(val);
    window.history.replaceState(null, null, `?form=${val}`);
  }

  const getCenter = useCallback(() => {
    getCenterAction(dispatch, id);
  }, [dispatch, id]);

  function submitCenter(values) {
    if (center?._id) {
      putCenterAction(dispatch, center._id, values);
    } else {
      postCenterAction(dispatch, values);
    }
  }

  useEffect(() => {
    if (id) {
      getCenter();
    }
  }, []);

  useEffect(() => {
    if (center) {
      const propreties = [
        'name',
        'code',
        'location',
        'services',
        'donations',
        'msl',
        'kam',
        'rrh',
        'centers',
      ];
      propreties.forEach((p) => {
        if (center[p]) {
          setValue(p, center[p]);
        }
      });
    }
  }, [center]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{center?.name
            ? <span>Centres / {center.name}</span>
            : <span>Nouveau centre</span>
            }
          </h2>
          <Link to={'/center'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(submitCenter)}
            className={styles.edit}>
            <span>Enregistrer la fiche</span>
            <IoSave size={28} />
          </Button>
        </div>
      </header>
      <nav className={styles.nav}>
        <ul ref={navRef}>
          {nav.map((l) => <li
            key={l.label}
            className={`${Number(activeForm) === l.value ? styles['active-form'] : ''}`}
            onClick={() => handleChangeForm(l.value) }>
            {l.label}
          </li>)}
        </ul>
      </nav>
      <main className={`${styles.content}`}>
        <div className={styles['form-container']} style={{ transform: `translateX(-${(Number(activeForm) * 100) / 3}%)` }}>
          <Infos
            activeForm={activeForm}
            control={control}
            errors={errors}
            />
          <Services
            activeForm={activeForm}
            watch={watch}
            submit={submitCenter}
            centerId={center?._id}
            setValue={setValue}
            />
          <Pathologies
            activeForm={activeForm}
            watch={watch}
            submit={submitCenter}
            centerId={center?._id}
            setValue={setValue}
          />
        </div>
      </main>
    </form>
  );
}
