/* eslint-disable react/no-danger */
import { format } from 'date-fns';
import React from 'react';
import { FiCalendar } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import styles from './CongressCard.module.scss';

export default function CongressCard({ card }) {
  return (
    <Link to={`/congress/${card._id}?form=0`} className={styles.container}>
      <div className={styles.infos}>
        <h4>{card?.title || card?.name}</h4>
        {card.description && <p className={styles.text}>{card.description}</p>}
        {card.texte && (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: card.texte }}
        />
        )}
      </div>
      {card?.startDate
        && (
        <div className={styles.dates}>
          <div className={styles.date}>
            <FiCalendar color="#4F3CC8" />
            {card?.startDate && (
            <p>
              Début :
              {' '}
              {format(new Date(card?.startDate), 'dd/MM/yyyy')}
            </p>
            )}
          </div>
          <div className={styles.date}>
            <FiCalendar color="#4F3CC8" />
            {card?.endDate && (
            <p>
              Fin :
              {' '}
              {format(new Date(card?.endDate), 'dd/MM/yyyy')}
            </p>
            )}
          </div>
        </div>
        )}
    </Link>
  );
}
