import {
  getData, putData, postData,
} from './index';

import {
  ERROR_THERAPEUTIC_AREA,
  GET_THERAPEUTIC_AREAS,
  GET_THERAPEUTIC_AREA,
  PUT_THERAPEUTIC_AREA,
  POST_THERAPEUTIC_AREA,
  SET_TOAST,
} from './types';

export async function getTherapeuticAreasListAction(dispatch) {
  const url = '/therapeuticarea';
  let therapeuticareas;

  await getData(ERROR_THERAPEUTIC_AREA, url, dispatch, true).then((response) => {
    therapeuticareas = response.therapeuticAreas;
  });

  dispatch({
    type: GET_THERAPEUTIC_AREAS,
    payload: therapeuticareas,
  });
}

export async function getTherapeutiAreaAction(dispatch, id) {
  const url = `/therapeuticarea/${id}`;
  let therapeuticArea;

  await getData(ERROR_THERAPEUTIC_AREA, url, dispatch, true).then((response) => {
    therapeuticArea = response.therapeuticArea;
  });

  dispatch({
    type: GET_THERAPEUTIC_AREA,
    payload: therapeuticArea,
  });
}

export async function postTherapeuticAreaAction(dispatch, data) {
  const url = '/therapeuticarea';
  let therapeuticArea;

  await postData(
    POST_THERAPEUTIC_AREA,
    ERROR_THERAPEUTIC_AREA,
    url,
    dispatch,
    data,
    true,
  ).then((response) => {
    therapeuticArea = response.therapeuticArea;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'aire thérapeutique été creé",
    },
  });

  dispatch({
    type: POST_THERAPEUTIC_AREA,
    payload: therapeuticArea,
  });
}

export async function putTherapeuticAreaAction(dispatch, id, data) {
  const url = `/therapeuticarea/${id}`;
  let therapeuticArea;

  await putData(
    PUT_THERAPEUTIC_AREA,
    ERROR_THERAPEUTIC_AREA,
    url,
    dispatch,
    data,
    true,
  ).then((response) => {
    therapeuticArea = response.therapeuticArea;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'aire thérapeutique a été modifié",
    },
  });

  dispatch({
    type: PUT_THERAPEUTIC_AREA,
    payload: therapeuticArea,
  });
}
