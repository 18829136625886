import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import draftToHtml from 'draftjs-to-html';
import {
  Editor,
  EditorState,
  ContentState,
  convertFromHTML,
  RichUtils,
  convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styles from './text-editor.module.scss';
import InlineStyleControls from './InlineStyleControls';

const TextEditor = ({
  name,
  rules,
  defaultValue = '',
  control,
  placeholder,
}) => {
  const defaultEditorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(defaultValue)),
  );
  const [editorState, setEditorState] = useState(defaultEditorState);
  function onChangeEditor(state) {
    setEditorState(state);
  }

  function toggleInlineStyle(inlineStyle) {
    onChangeEditor(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      ),
    );
  }

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: {
            ref,
            onChange,
          },
        }) => {
          function _onChange(state) {
            const html = draftToHtml(convertToRaw(state.getCurrentContent()));
            onChange(html);
            onChangeEditor(state);
          }

          function toggle(state) {
            toggleInlineStyle(state);
          }

          function changeStyleDone() {
            const raw = convertToRaw(editorState.getCurrentContent());
            const html = draftToHtml(raw);
            onChange(html);
          }

          return (
            <>
              <InlineStyleControls
                editorState={editorState}
                onToggle={(state) => toggle(state)}
                onChangeDone={changeStyleDone}
              />
              <div className={styles['container-text']}>
                <Editor
                  placeholder={placeholder || ''}
                  ref={ref}
                  spellCheck={true}
                  editorState={editorState}
                  onChange={(state) => _onChange(state)}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default TextEditor;
