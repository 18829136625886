import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
import { getToolsAction, getToolsTypesAction } from '../../../actions/toolsActions';
import ToolRow from '../../../components/Tools/ToolRow/ToolRow';
// import { SET_USERS_FILTERS } from '../../../actions/types';

import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';
import styles from './ToolsList.module.scss';
import ToolsFilters from '../../../components/Tools/ToolsFilters/ToolsFilters';
import { GET_TOOL } from '../../../actions/types';

export default function ToolsList() {
  const { toolsList, filters } = useSelector((state) => state.tools);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    title: true,
    type: true,
    division: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getToolsAction(dispatch);
    getToolsTypesAction(dispatch);
    dispatch({
      type: GET_TOOL,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'title':
        list = list.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'type':
        list = list.sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'division':
        list = list.sort((a, b) => {
          if (a.division < b.division) return -1;
          if (a.division > b.division) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      default:
        break;
    }
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    if (filters.divisions.length) {
      val += filters.divisions.length;
    }
    if (filters.types.length) {
      val += filters.types.length;
    }
    return val;
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    let list = toolsList;
    if (filters.search) {
      list = list.filter((t) => {
        if (t.title.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        return null;
      });
    }
    if (filters.types.length) {
      list = list.filter((t) => {
        if (filters.types.find((f) => f === t.type)) {
          return t;
        }
        return null;
      });
    }
    if (filters.divisions.length) {
      list = list.filter((t) => {
        if (filters.divisions.find((f) => t.division.find((d) => d === f))) {
          return t;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [toolsList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>Outils</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/tool/create')}>
              NOUVEL OUTIL
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('title', !sortByReverse.title)}
              state={sortByReverse.title}
              className={`${styles.col}`}/>
            <SortButton
              name={'Type'}
              handleClick={() => sortList('type', !sortByReverse.type)}
              state={sortByReverse.type}
              className={`${styles.col}`}/>
            <SortButton
              name={'Divisions'}
              handleClick={() => sortList('division', !sortByReverse.division)}
              state={sortByReverse.division}
              className={`${styles.col} ${styles.desktop}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          className={styles.list}
          >
          {!isLoading && filteredList.length > 0 && filteredList?.map((t, i) => <ToolRow
            key={t._id}
            tool={t}
            index={i}
            styles={styles}
          />)}
        </div>
      </div>
      <ToolsFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
