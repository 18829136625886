import React, {
  useRef, useEffect,
} from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { generateKey } from '../../../../utils/utils';

import InputFile from '../../../InputFile/InputFile';
import { API_URL } from '../../../../actions';
import { deleteFileAction } from '../../../../actions/filesActions';

import styles from './Reports.module.scss';

export default function Reports({
  watch,
  submit,
  activeForm,
}) {
  const dispatch = useDispatch();
  // const booleanOptions = [{ value: true, label: 'Oui' }, { value: false, label: 'Non' }];
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  function deleteReportFile(id) {
    deleteFileAction(dispatch, id);
    submit({
      ...watch(),
      report: {
        ...watch('report'),
        files: watch('report.files').map((f) => f._id).filter((f) => f !== id),
      },
    });
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>Compte rendu</h3>
              <div className={`${styles['file-section']}`}>
                <div className={`${styles.list}`}>
                  {watch('reports')?.map((f, i) => {
                    // eslint-disable-next-line prefer-template
                    const url = API_URL + '/' + f?.path;
                    return <div key={f?._id || generateKey('report', i)} className={styles.file}>
                      <a href={url} target='_blank' rel="noreferrer">
                        {f?.name || 'fichier'}
                      </a>
                      <button
                        type="button"
                        onClick={() => deleteReportFile(f._id)}
                        >
                        <AiFillDelete size={20} />
                      </button>
                    </div>;
                  })}
                </div>
                <div className={styles['input-file']}>
                  <InputFile
                    activeFile={null}
                    handleChange={(fileId) => submit({
                      ...watch(),
                      reports: [...watch('reports').map((f) => f._id), fileId],
                    })}
                    name={'reports'}
                    />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
