import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';

import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';

import styles from './ContactsList.module.scss';
import { postContactsFilteredAction } from '../../../actions/contactsActions';
import { SET_CONTACT_FILTERS, SET_CONTACT_SCROLL } from '../../../actions/types';
import ContactRow from '../../../components/Contact/ContactRow/ContactRow';
import ContactFilters from '../../../components/Contact/ContactFilters/ContactFilters';
import { getCentersAction } from '../../../actions/centersActions';

export default function ContactsList() {
  const {
    contactsList, filters, count, scrollY,
  } = useSelector((state) => state.contacts);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    firstName: true,
  };

  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getCentersAction(dispatch);
  }, [dispatch]);

  const getContacts = useCallback(() => {
    postContactsFilteredAction(dispatch, filters);
  }, [dispatch, filters]);

  function sortList(type, isReverse) {
    setSortByReverse({ ...initSortState, [type]: isReverse });
    dispatch({
      type: SET_CONTACT_FILTERS,
      payload: {
        ...filters,
        page: 1,
        orderBy: {
          key: type,
          order: isReverse ? 'desc' : 'asc',
        },
      },
    });
  }

  function handleScroll(e) {
    dispatch({
      type: SET_CONTACT_SCROLL,
      payload: e.target.scrollTop,
    });
    if (!isLoading
        && count > contactsList?.length
        && e.target.scrollTop > scrollY
        && scrollRef?.current.getBoundingClientRect().bottom
        <= containerRef?.current.getBoundingClientRect().bottom
    ) {
      dispatch({
        type: SET_CONTACT_FILTERS,
        payload: {
          ...filters,
          page: filters.page + 1,
        },
      });
    }
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    return val;
  }

  useEffect(() => {
    getData();
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getContacts();
  }, [filters.orderBy, filters.search, filters, filters.page]);

  useEffect(() => {
    setIsLoading(false);
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [contactsList, scrollY]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>Contacts médicaux</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/contact/create')}>
              NOUVEAU CONTACT
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('firstName', !sortByReverse.firstName)}
              state={sortByReverse.firstName}
              className={`${styles.col}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          onScroll={(e) => handleScroll(e)}
          className={styles.list}
          >
          <div ref={scrollRef}>
          {contactsList?.map((c) => <ContactRow
            key={c._id}
            contact={c}
            styles={styles}
          />)}
          </div>
        </div>
      </div>
      <ContactFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
