import { format } from 'date-fns';
import React, { useRef, useState, useEffect } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { FiCalendar, FiClock } from 'react-icons/fi';
import styles from './Infos.module.scss';
import { projectsTypesOptions } from '../../../../constants/constants';

export default function Infos({ project, company }) {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <section className={styles.container}>
      <h1>{project?.name}</h1>
      <div className={`${styles.infos} ${styles.border}`}>
        <div className={styles.col}>
          <label>Type de projet</label>
          <p>{projectsTypesOptions.find((t) => t.value === project?.type)?.label}</p>
        </div>
        <div className={styles.col}>
          <label>Entreprise</label>
          <p>{company}</p>
        </div>
      </div>
      <div className={styles.title}>
        <h2>Analyse du besoin</h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${!isOpen ? styles.close : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && contentRef?.current?.clientHeight > 0 ? contentRef.current.clientHeight + 20 : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          <div className={styles.row}>
            {project?.startDate
              && <div className={`${styles.col} ${styles.icon}`}>
                <FiCalendar size={20}/>
                <p>
                  <label>Début du projet : </label>
                  <span className={styles.bold}>{format(new Date(project?.startDate), 'dd/MM/yyyy') }</span>
                </p>
              </div>
            }
            {project?.endDate
              && <div className={`${styles.col} ${styles.icon}`}>
                <FiCalendar size={20}/>
                <p>
                  <label>Fin du projet : </label>
                  <span className={styles.bold}>{format(new Date(project?.endDate), 'dd/MM/yyyy') }</span>
                </p>
              </div>
            }
            {project?.duration
              && <div className={`${styles.col} ${styles.icon}`}>
                <FiClock size={20}/>
                <p>
                  <label>Durée du projet  : </label>
                  <span className={styles.bold}>{project?.duration} mois</span>
                </p>
              </div>
            }
          </div>
          {project?.situation
              && <div className={styles.text}>
              <label>Situation actuelle</label>
              <p>
                {project?.situation}
              </p>
            </div>
          }
          {project?.objective
              && <div className={styles.text}>
              <label>Objectif(s)</label>
              <p>
                {project?.objective}
              </p>
            </div>
          }
          {project?.centers?.length > 0 && <div className={styles.border}>
            <h2>ETABLISSEMENTS ASSOCIés</h2>
            <div className={styles.list}>
              {project?.centers?.map((c) => <p key={c._id} >
                {c.center.name}
                {c.service && c.center?.services?.find((s) => s._id === c.service)?.name
                  && <span> - {c.center?.services?.find((s) => s._id === c.service)?.name}
                  </span>
                }
              </p>)}
            </div>
          </div>
          }
          {project?.drugs?.length > 0 && <div className={styles.border}>
            <h2>PRODUITS ASSOCIés</h2>
            <div className={styles.list}>
              {project.drugs.map((p) => <p key={p._id} >
                {p.name}
              </p>)}
            </div>
          </div>
          }
        </div>
      </div>
    </section>
  );
}
