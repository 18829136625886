import React, {
  useRef, useEffect,
} from 'react';

import {
  ErrorField,
  InputFormatNumber,
  InputText,
} from '../../../../lib/HooksFormFields';

import styles from './Infos.module.scss';

export default function Infos({
  control, errors, activeForm,
}) {
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>Information</h3>
              <div className={styles['input-list']}>
                <div className={styles.block}>
                  <label>Code client</label>
                  <div className={styles.value}>
                    <InputText
                      name='code'
                      control={control}
                      className={'primary'}
                      rules={{ required: 'Le code est requis' }}
                      placeholder={'code'}
                    />
                    <ErrorField message={errors?.code?.message || ''} />
                  </div>
                </div>

                <div className={styles.block}>
                </div>

                <div className={`${styles.block}`}>
                  <label>Nom du centre</label>
                  <div className={styles.value}>
                    <InputText
                      name='name'
                      control={control}
                      className={'primary'}
                      rules={{ required: 'Le nom est requis' }}
                      placeholder={'Nom'}
                    />
                    <ErrorField message={errors?.name?.message} />
                  </div>
                </div>

                <div className={styles.block}>
                  <label>Adresse</label>
                  <div className={styles.value}>
                    <InputText
                      name='location.address'
                      control={control}
                      rules={{ required: "L'adresse est requis" }}
                      className={'primary'}
                      placeholder={'Adresse'}
                    />
                    <ErrorField message={errors?.location?.address?.message} />
                  </div>
                </div>
                <div className={styles['input-row']}>
                  <div className={styles.block}>
                    <label>Ville</label>
                    <div className={styles.value}>
                      <InputText
                        name='location.city'
                        control={control}
                        rules={{ required: 'La ville est requise' }}
                        className={'primary'}
                        placeholder={'Ville'}
                      />
                      <ErrorField message={errors?.location?.city?.message} />
                    </div>
                  </div>
                  <div className={styles.block}>
                    <label>Code postal</label>
                    <div className={styles.value}>
                      <InputText
                        name='location.postcode'
                        control={control}
                        className={'primary'}
                        placeholder={'Code potal'}
                      />
                      <ErrorField message={''} />
                    </div>
                  </div>
                </div>

                <div className={styles['input-row']}>
                  <div className={styles.block}>
                    <label>Latitude</label>
                    <div className={styles.value}>
                      <InputFormatNumber
                        name='location.lat'
                        control={control}
                        rules={{ required: 'La latitude est requise' }}
                        className={'primary'}
                        placeholder={''}
                      />
                      <ErrorField message={errors?.location?.lat?.message} />
                    </div>
                  </div>

                  <div className={styles.block}>
                    <label>Longitude</label>
                    <div className={styles.value}>
                      <InputFormatNumber
                        name='location.lon'
                        control={control}
                        rules={{ required: 'La longitude est requise' }}
                        className={'primary'}
                        placeholder={''}
                      />
                      <ErrorField message={errors?.location?.lon?.message} />
                    </div>
                  </div>
                </div>
              </div>

            </section>
            <section>
              <h3>RESPONSABLES</h3>
              <div className={styles['input-row']}>
                <div className={styles.block}>
                  <label>MSL</label>
                  <div className={styles.value}>
                    <InputText
                      name='msl'
                      control={control}
                      className={'primary'}
                      placeholder={''}
                    />
                    <ErrorField message={''} />
                  </div>
                </div>
                <div className={styles.block}>
                  <label>KAM</label>
                  <div className={styles.value}>
                    <InputText
                      name='kam'
                      control={control}
                      className={'primary'}
                      placeholder={''}
                    />
                    <ErrorField message={''} />
                  </div>
                </div>
              </div>
              <div className={styles['input-row']}>
                <div className={styles.block}>
                  <label>RRH</label>
                  <div className={styles.value}>
                    <InputText
                      name='rrh'
                      control={control}
                      className={'primary'}
                      placeholder={''}
                    />
                    <ErrorField message={''} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
