import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import { IoChevronBack, IoSave } from 'react-icons/io5';
import { useForm } from 'react-hook-form';

import {
  getCongressAction, postCongressAction, putCongressAction,
} from '../../../actions/congressesActions';

import Button from '../../../lib/HooksFormFields/Button';
// import Infos from '../../../components/Projects/ProjectForm/Infos/Infos';
// import Actors from '../../../components/Projects/ProjectForm/Actors/Actors';
// import Com from '../../../components/Projects/ProjectForm/Com/Com';
// import Meetings from '../../../components/Projects/ProjectForm/Meetings/Meetings';
// import Tasks from '../../../components/Projects/ProjectForm/Tasks/Tasks';

import styles from './EditCongress.module.scss';
import { getCompaniesAction } from '../../../actions/companyActions';
import { getAllUsers, getOctapharamaUsers } from '../../../actions/usersActions';
import Infos from '../../../components/Congresses/CongressForm/Infos/Infos';
import People from '../../../components/Congresses/CongressForm/People/People';
import Guest from '../../../components/Congresses/CongressForm/Guest/Guest';
import Octa from '../../../components/Congresses/CongressForm/Octa/Octa';
import Reports from '../../../components/Congresses/CongressForm/Reports/Reports';

export const congressNav = [
  {
    value: 0,
    label: 'Informations générales',
  },
  {
    value: 1,
    label: 'Octapharma',
  },
  {
    value: 2,
    label: 'Participants',
  },
  {
    value: 3,
    label: 'Logistique',
  },
  {
    value: 4,
    label: 'Comptes-rendus',
  },
];

export default function EditCongress() {
  const id = useParams()?.id;
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const { congress } = useSelector((state) => state.congresses);
  const { therapeuticAreasList } = useSelector((state) => state.therapeuticAreas);

  const navRef = useRef();

  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).form);
  // const [companiesOptions, setCompaniesOptions] = useState();

  function handleChangeForm(val) {
    setActiveForm(val);
    window.history.replaceState(null, null, `?form=${val}`);
  }

  const getProject = useCallback(() => {
    getCongressAction(dispatch, id);
  }, [dispatch, id]);

  const getData = useCallback(() => {
    getCompaniesAction(dispatch);
    getOctapharamaUsers(dispatch);
    getAllUsers(dispatch);
  }, [dispatch]);

  function submitCongress(values) {
    const data = { ...values };
    if (congress?._id) {
      putCongressAction(dispatch, congress._id, data);
    } else {
      postCongressAction(dispatch, data);
    }
  }

  useEffect(() => {
    navRef.current.scrollTo({
      left: (120 * activeForm),
      top: 0,
      behavior: 'smooth',
    });
  }, [activeForm]);

  useEffect(() => {
    if (id) {
      getProject();
    }
    getData();
  }, []);

  useEffect(() => {
    if (congress) {
      const propreties = [
        'name',
        'description',
        'location',
        'city',
        'url',
        'dotation',
        'instruction',
        'atrium',
        'dmos',
        'rational',
        'author',
        'drugs',
        'poster',
        'accommodation',
        'guests',
        'guestsFile',
        'guestsTrips',
        'guestsRoomingList',
        'cover',
        'Octa',
        'installation',
        'octapeoples',
        'symposium',
        'therapeuticAreas',
        'roadbooks',
        'reports',
        'quiz',
        'participation',
      ];
      const dates = [
        'startDate',
        'endDate',
      ];
      propreties.forEach((p) => {
        if (congress[p] || congress[p] === false) {
          setValue(p, congress[p]);
        }
      });
      dates.forEach((p) => {
        if (congress[p]) {
          const date = new Date(congress[p]);
          setValue(p, date);
        }
      });
    }
  }, [congress]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{congress?.name
            ? <span>CONGRès / {congress.name}</span>
            : <span>Nouveau congrès</span>
            }
          </h2>
          {congress
            ? <Link to={`/congress/${id}?form=0`} className={styles.link}>
              <IoChevronBack size={20}/> retour à la fiche
            </Link>
            : <Link to={'/congress'} className={styles.link}>
              <IoChevronBack size={20}/> retour à la liste
            </Link>
          }
        </div>
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(submitCongress)}
            className={styles.edit}>
            <span>Enregistrer</span>
            <IoSave size={28} />
          </Button>
        </div>
      </header>
      <nav className={styles.nav}>
        <ul ref={navRef}>
          {congressNav.map((l) => <li
            key={l.label}
            className={`${Number(activeForm) === l.value ? styles['active-form'] : ''}`}
            onClick={() => handleChangeForm(l.value) }>
            {l.label}
          </li>)}
        </ul>
      </nav>
      <main className={`${styles.content}`}>
        <div className={styles['form-container']} style={{ transform: `translateX(-${(Number(activeForm) * 100) / 5}%)` }}>
          <Infos
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitCongress}
            therapeuticAreasList={therapeuticAreasList?.map(
              (t) => ({ label: t.name, value: t._id }),
            )}
            control={control}
            errors={errors}
            />
          <Octa
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitCongress}
            control={control}
            errors={errors}
            />
          <People
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitCongress}
            control={control}
            errors={errors}
          />
          <Guest
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitCongress}
            control={control}
            errors={errors}
          />
          <Reports
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitCongress}
            control={control}
            errors={errors}
          />
        </div>
      </main>
    </form>
  );
}
