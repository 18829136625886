import React, {
  useRef, useEffect,
} from 'react';
import { CgColorPicker } from 'react-icons/cg';
import { defaultPathologiesType } from '../../../../constants/constants';

import {
  ErrorField,
  InputText,
} from '../../../../lib/HooksFormFields';
import TypeForm from '../../TypeForm/TypeForm';

import styles from './Infos.module.scss';

export default function Infos({
  control, errors, activeForm, watch, setValue, submit,
}) {
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  const watchTypes = watch('types');
  return (
    <div ref={containerRef} className={styles.container}>
      {Number(activeForm) === 0
        && <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.box}>
              <section>
                <h3>Informations</h3>
                <div className={styles['input-list']}>
                  <div className={`${styles.block}`}>
                    <label>Nom</label>
                    <div className={styles.value}>
                      <InputText
                        name='name'
                        control={control}
                        className={'primary'}
                        rules={{ required: 'Le nom est requis' }}
                        placeholder={'Nom'}
                      />
                      <ErrorField message={errors?.name?.message} />
                    </div>
                  </div>

                  <div className={`${styles.block}`}>
                    <label>Catégorie</label>
                    <div className={styles.value}>
                      <InputText
                        name='category'
                        control={control}
                        className={'primary'}
                        rules={{ required: 'La catégorie est requis' }}
                        placeholder={'Catégorie'}
                      />
                      <ErrorField message={errors?.category?.message} />
                    </div>
                  </div>
                </div>

                <div className={styles.block}>
                  <label>Couleur</label>
                  <div className={styles.icon} style={{ backgroundColor: watch('color') || '#E3E0F5' }}>
                    <div className={styles.color}>
                      <input
                        type="color"
                        id="picker"
                        onChange={(e) => setValue('color', e.target.value)}
                        name="picker"
                        value={watch('color') || '#E3E0F5'}/>
                      <label htmlFor="picker"><CgColorPicker size={20}/></label>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className={styles.types}>
              <h3>TYPES</h3>
              <button
                type="button"
                onClick={() => submit({ types: [...watchTypes, defaultPathologiesType] })}
              >
                Ajouter un type
              </button>
            </div>
            {watchTypes?.map((t) => <TypeForm
              key={t._id}
              types={watchTypes}
              type={t}
              handleChangePathologie={setValue}
              handleSubmitPathologie={submit}
            />)}
          </div>
        </div>
      }
    </div>
  );
}
