import React, { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { IoChevronUp } from 'react-icons/io5';
// import InputDate from '../../lib/FormFields/InputDate';
import { InputDate } from '../../lib/HooksFormFields';

import FormList from '../FormList/FormList';

import styles from './MeetingForm.module.scss';

export default function MeetingForm({
  meeting,
  meetingsList,
  control,
  handleChange,
  i,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [cr, setCr] = useState(meeting.cr || '');
  const [title, setTitle] = useState(meeting?.title || '');

  function onChange(id, key, value) {
    const list = meetingsList;
    const index = meetingsList.findIndex((m) => m._id === id);
    list[index][key] = value;
    handleChange(list);
  }

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <h3>{meeting.title || `RÉUNION ${i + 1}`}</h3>
        {isOpen
          && <button
            type='button'
            onClick={() => handleChange(meetingsList.filter((m) => m._id !== meeting._id))}
            >
            supprimer
          </button>
        }
      </div>
      <div className={styles.form}>
        <div className={styles.date}>
          <label>Date</label>
          <div className={styles.input}>
            <InputDate
              dateValue={new Date(meeting.date)}
              name="date"
              handleChangeDate={(date) => onChange(meeting._id, 'date', date)}
              control={control}
              widthLabel={'30%'}
              placeholder={''}
            />
          </div>
        </div>
        {!isOpen && <p className={styles.count}>{meeting.participants.length} participant{meeting.participants.length === 1 ? '' : 's' }</p>}
        {isOpen
          && <div className={styles['form-group']}>
              <label>Nom de la réunion</label>
              <div className={styles.value}>
                <input
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  className={styles.title}
                  placeholder={'Nom de la réunion'}
                />
              </div>
              <div className={styles['btn-container']}>
                <button
                  type='button'
                  className={styles.btn}
                  onClick={() => onChange(meeting._id, 'title', title)}
                >
                  Valider le nom
                </button>
              </div>
            </div>
        }
        {isOpen
          && <>
          <div className={styles.list}>
            {meeting.participants.map((p) => <div
              key={p._id}
              className={styles.item}
              >
              <div>
                <p>{p?.firstName} {p?.lastName}</p>
              </div>
              <button
                type="button"
                onClick={() => onChange(meeting._id, 'participants', meeting.participants.filter((pa) => pa?._id !== p._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={meeting.participants || []}
            handleChange={(arr) => onChange(meeting._id, 'participants', arr)}
            btn="Ajouter un participant"
            model={[
              {
                label: 'Nom',
                key: 'firstName',
                type: 'text',
              },
              {
                label: 'Prénom',
                key: 'lastName',
                type: 'text',
              },
            ]}
          />
        </>
      }
      {isOpen
          && <div className={styles['form-group']}>
            <label>CR</label>
            <div className={styles.value}>
              <textarea
                value={cr}
                onChange={(e) => setCr(e.target.value)}
                className={styles['text-area']}
                placeholder={'Compte-rendu'}
              />
            </div>
            <div className={styles['btn-container']}>
              <button
                type='button'
                className={styles.btn}
                onClick={() => onChange(meeting._id, 'cr', cr)}
              >
                Valider le compte-rendu
              </button>
            </div>
          </div>
      }

      </div>
      <button
        className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        >
        <IoChevronUp size={20}/>
      </button>

    </div>
  );
}
