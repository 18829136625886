import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Link, useLocation, useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronBack } from 'react-icons/io5';
import { GoPencil } from 'react-icons/go';
import queryString from 'query-string';

import { getCongressAction } from '../../../actions/congressesActions';

import Button from '../../../lib/HooksFormFields/Button';

import styles from './CongressView.module.scss';
import Infos from '../../../components/Congresses/CongressView/Infos/Infos';
import Guest from '../../../components/Congresses/CongressView/Guest/Guest';
import People from '../../../components/Congresses/CongressView/People/People';
import Octa from '../../../components/Congresses/CongressView/Octa/Octa';
import { congressNav } from '../EditCongress/EditCongress';
import Reports from '../../../components/Congresses/CongressView/Reports/Reports';

export default function CongressView() {
  const id = useParams()?.id;
  const { congress } = useSelector((state) => state.congresses);
  const location = useLocation();

  const dispatch = useDispatch();

  function formatDate(date) {
    const dayOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const hourOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    return `${new Intl.DateTimeFormat('fr', dayOptions).format(new Date(date))} à ${new Intl.DateTimeFormat('fr', hourOptions).format(new Date(date))}`;
  }

  const navRef = useRef();

  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).form);

  function handleChangeForm(val) {
    setActiveForm(val);
    window.history.replaceState(null, null, `?form=${val}`);
  }

  const getCongress = useCallback(() => {
    getCongressAction(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getCongress();
    }
  }, []);

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{congress?.name
              && <span>{congress.name}</span>
            }
          </h2>
          <Link to={'/congress'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <div className={styles['update-date']}>
            {congress?.updatedAt && <p>
              Dernière modification : <span>{formatDate(congress.updatedAt)}</span>
            </p>}
          </div>
          <Link to={`/congress/edit/${id}?form=${activeForm}`} className={styles.link}>
            <Button
              className={styles.edit}>
              <span>Editer la fiche </span>
              <GoPencil size={20} />
            </Button>
          </Link>
        </div>
      </header>
      <nav className={styles.nav}>
        <ul ref={navRef}>
          {congressNav.map((l) => <li
            key={l.label}
            className={`${Number(activeForm) === l.value ? styles['active-form'] : ''}`}
            onClick={() => handleChangeForm(l.value) }>
            {l.label}
          </li>)}
        </ul>
      </nav>
      <main className={`${styles.content}`}>
        <div className={styles['form-container']} style={{ transform: `translateX(-${(Number(activeForm) * 100) / 5}%)` }}>
          {congress?._id
          && <>
            <Infos
              congress={congress}
              activeForm={activeForm}
            />
            <Octa
              congress={congress}
              activeForm={activeForm}
            />
            <People
              congress={congress}
              activeForm={activeForm}
            />
            <Guest
              congress={congress}
              activeForm={activeForm}
            />
            <Reports
              congress={congress}
            />
          </>
          }
        </div>
      </main>
    </div>
  );
}
