import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { SET_TOOLS_FILTERS } from '../../../actions/types';
import TagsList from '../../TagsList/TagsList';
import styles from './ToolsFilters.module.scss';

export default function ToolsFilters({
  close,
  isActive,
}) {
  const {
    filters, types, divisions,
  } = useSelector((state) => state.tools);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  function handleChangeFilters(key, value) {
    dispatch({
      type: SET_TOOLS_FILTERS,
      payload: {
        ...filters,
        [key]: value,
      },
    });
  }

  return (
    <div className={`${styles.container} ${isActive ? styles['is-active'] : ''}`}>
      <div className={styles.title}>
        <h2>Filtres</h2>
        <button
          onClick={() => close()}
          className={styles.close}>
          <IoMdClose size={30} />
        </button>
      </div>
      <div className={styles.filters}>
        <label>Recherche</label>
        <div className={styles.search}>
          <input
            value={search}
            placeholder={'Votre recherche'}
            onChange={(e) => setSearch(e.target.value)}
            />
          <button
            className={`${search ? styles['is-search'] : ''}`}
            onClick={() => handleChangeFilters('search', search)}
            >
            <IoSearch size={20} />
          </button>
        </div>
        <div className={styles.tags}>
          <TagsList
            label={'Types'}
            options={types}
            selected={filters.types}
            isFilter
            handleChange={(array) => handleChangeFilters('types', array)}
            isEdit
            />
        </div>
        <div className={styles.tags}>
          <TagsList
            label={'Divisions'}
            options={divisions}
            selected={filters.divisions}
            isFilter
            handleChange={(array) => handleChangeFilters('divisions', array)}
            isEdit
            />
        </div>

      </div>
    </div>
  );
}
