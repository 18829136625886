import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function DrugRow({
  styles,
  drug,
}) {
  const { companiesList } = useSelector((state) => state.companies);
  return (
    <Link to={`/product/${drug._id}`} className={styles.row}>
      <div className={styles.col}>
        <p className={styles.bold}>{drug?.name}</p>
      </div>
      <div className={styles.col}>
        <p >{companiesList.find((c) => c._id === drug?.company)?.name}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
