import React, { useRef, useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { Textarea } from '../../../../lib/HooksFormFields';
import TagsList from '../../../TagsList/TagsList';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import FormList from '../../../FormList/FormList';

import SentenceList from '../../../SentenceList/SentenceList';
import styles from './ClinicalData.module.scss';

export default function ClinicalData({
  // errors,
  setValue,
  watch,
  control,
  // submit,
  activeForm,
}) {
  const containerRef = useRef();
  const pharmaFormOptions = ['IV', 'SC', 'ORALE', 'IM'].map((opt) => ({ value: opt, label: opt }));
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <BoxPanel
          label={'indications'}
          >
          <SentenceList
            isEdit={true}
            list={watch('clinicalData.indication') || []}
            handleChange={(arr) => setValue('clinicalData.indication', arr)}
            label='Ajouter indication'
            btn='Ajouter'
          />
        </BoxPanel>
        <BoxPanel
          label={'POSOLOGIE'}
          >
          <div className={styles['form-group']}>
            <label>Description</label>
            <div className={styles.value}>
              <Textarea
                name='clinicalData.dosageDescription'
                control={control}
                className={'primary'}
                placeholder={''}
              />
            </div>
          </div>
          <label>Dosage</label>
          <div className={styles.list}>
            {watch('clinicalData.dosage')?.map((d) => <div
              key={d._id}
              className={styles.item}
              >
              <p className={styles.bold}>{d?.situation}</p>
              <p>{d?.speed}</p>
              <button
                type="button"
                onClick={() => setValue('clinicalData.dosage', watch('clinicalData.dosage').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('clinicalData.dosage') || []}
            handleChange={(arr) => setValue('clinicalData.dosage', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'situation',
                key: 'situation',
                type: 'text',
              },
              {
                label: 'Vitesse d’administration',
                key: 'speed',
                type: 'number',
              },
            ]}
          />
          <h3>Mode d’administration</h3>
          {console.log(watch('administration'))}
          <div className={styles['form-group']}>
            <TagsList
              isEdit={true}
              options={pharmaFormOptions || []}
              isUnique={true}
              selected={watch('administration') ? [watch('administration')] : []}
              handleChange={(value) => setValue('administration', value)}
              />
          </div>
        </BoxPanel>
        <BoxPanel
          label={'Contre-indications'}
          >
          <SentenceList
            isEdit={true}
            list={watch('clinicalData.contraindication') || []}
            handleChange={(arr) => setValue('clinicalData.contraindication', arr)}
            label='Ajouter contre indication'
            btn='Ajouter'
          />
        </BoxPanel>
        <BoxPanel
          label={'interactions'}
          >
          <SentenceList
            isEdit={true}
            list={watch('clinicalData.interactions') || []}
            handleChange={(arr) => setValue('clinicalData.interactions', arr)}
            label='Ajouter interaction'
            btn='Ajouter'
          />
        </BoxPanel>
        <BoxPanel
          label={'GROSSESSE'}
          >
          <SentenceList
            isEdit={true}
            list={watch('clinicalData.pregnancy') || []}
            handleChange={(arr) => setValue('clinicalData.pregnancy', arr)}
            label=''
            btn='Ajouter'
          />
        </BoxPanel>
        <BoxPanel
          label={'Effets indésirables'}
          >
          <div className={styles.list}>
            {watch('clinicalData.undesirableEffect')?.map((d) => <div
              key={d._id}
              className={styles.item}
              >
              <p className={styles.bold}>{d?.name}</p>
              <p>{d?.frequency}</p>
              <button
                type="button"
                onClick={() => setValue('clinicalData.undesirableEffect', watch('clinicalData.undesirableEffect').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('clinicalData.undesirableEffect') || []}
            handleChange={(arr) => setValue('clinicalData.undesirableEffect', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'Nom',
                key: 'name',
                type: 'text',
              },
              {
                label: 'Fréquence',
                key: 'frequency',
                type: 'text',
              },
            ]}
          />
        </BoxPanel>
        <BoxPanel
          label={'SURDOSAGE'}
          >
          <div className={styles['form-group']}>
            <label>Description</label>
            <div className={styles.value}>
              <Textarea
                name='clinicalData.overdose'
                control={control}
                className={'primary'}
                placeholder={''}
              />
            </div>
          </div>
        </BoxPanel>
      </main>
    </div>
  );
}
