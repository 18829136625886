import React, { useState, useRef } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { formKeys } from '../../../../constants/constants';
import SentenceList from '../../../SentenceList/SentenceList';
import SubTypeView from '../SubTypeView/SubTypeView';
import styles from './TypeView.module.scss';

export default function TypeView({ type }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {type?.name}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>
          {formKeys.map((k) => {
            const key = type[k.value];
            const id = `${k.value }mutation`;
            if (key && key.isDisplayed) {
              return (
                <div key={id} className={styles.block}>
                  <label>{k.label}</label>
                  <p>{key.value}</p>
                </div>
              );
            }
            return null;
          })}
          {(type.mutationsDescription || type.symptomsDescription || type.symptoms.length > 0)
            && <>
              <h3>Mutations & symptomes</h3>
              {type.mutationsDescription
                && <div className={styles.block}>
                  <label>Description des mutations</label>
                  <p>{type.mutationsDescription}</p>
                </div>
              }
              {type.symptoms.length > 0
                && <div className={styles.block}>
                  <label>Symptomes</label>
                  <SentenceList
                    isEdit={false}
                    list={type.symptoms}
                  />
                </div>
              }
              {type.symptomsDescription
                && <div className={styles.block}>
                  <label>Description des symptoms</label>
                  <p>{type.symptomsDescription}</p>
                </div>
              }
            </>
          }
        </div>
      </div>
     {type.subtypes?.map((t) => <SubTypeView key={t.name} type={t} />)}
    </div>
  );
}
