export function setVhUnit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export function generateKey(string, index) {
  return `${string}-${index}`;
}

export function concatName(string1, string2) {
  return `${string1} ${string2}`;
}

export function isValidDate(string) {
  const date = new Date(string);
  return date instanceof Date && !Number.isNaN(date.valueOf());
}

export function getHourFromDate(string) {
  const date = new Date(string);
  return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
}

export function isImage(filename) {
  const ext = filename?.split('.')?.pop();
  return ext === 'png' || ext === 'jpg' || ext === 'jpeg';
}
