import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { getProjectsAction } from '../../actions/projectsActions';
import { getCompaniesAction } from '../../actions/companyActions';
import { getTherapeuticAreasListAction } from '../../actions/therapeuticAreasActions';
import { getDrugsAction } from '../../actions/drugsActions';
import { getCentersAction } from '../../actions/centersActions';

import logo from '../../assets/images/logo.svg';
import logoutImg from '../../assets/images/logout.svg';

import styles from './Nav.module.scss';

export default function Nav({ logout }) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const { userAuth } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const isActiveNave = pathname === '/login' || pathname === '/' || pathname === '/data-policy' || pathname === '/transparency';
  const isDashboard = pathname === '/dashboard';

  const getDatas = useCallback(() => {
    getProjectsAction(dispatch);
    getCompaniesAction(dispatch);
    getTherapeuticAreasListAction(dispatch);
    getDrugsAction(dispatch);
    getCentersAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDatas();
  }, []);

  const links = [
    {
      label: 'Accueil',
      pathname: '/dashboard',
    },
    {
      label: 'Actualités',
      pathname: '/news',
    },
    {
      label: 'Utilisateurs',
      pathname: '/user',
    },
    {
      label: 'Outils',
      pathname: '/tool',
    },
    {
      label: 'Aires thérapeutiques',
      pathname: '/therapeuticarea',
    },
    {
      label: 'Produits',
      pathname: '/product',
    },
    {
      label: 'Pathologies',
      pathname: '/pathology',
    },
    {
      label: 'Centres & services',
      pathname: '/center',
    },
    {
      label: 'Contacts médicaux',
      pathname: '/contact',
    },
    {
      label: 'Congrès',
      pathname: '/congress',
    },
    {
      label: 'Projets',
      pathname: '/project',
    },
    {
      label: 'Fiches concurrents',
      pathname: '/company',
    },
  ];

  return (
    <div className={`${styles.container} ${isActiveNave ? styles.hide : ''} ${isDashboard ? styles.dashboard : ''} ${isActive ? styles['is-active'] : ''}`}>
      <button
        className={`${styles.menu} ${isActive ? styles.close : ''}`}
        onClick={() => setIsActive(!isActive)}
        >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <Link to={'/dashboard'}>
      <div className={styles.logo}>
        <img src={logo} alt='logo-octadmin'/>
        <h2>OCTADMIN</h2>
      </div>
      </Link>
      <div className={`${styles.nav} ${isActive ? styles['is-active'] : ''}`}>
        <ul
          onClick={() => setIsActive(!isActive)}
          >
          {links.slice(0, 4).map((l) => <li key={l.label}>
            <NavLink
              to={l.pathname}
              activeClassName={styles['active-link']}>
              <div className={styles.puce}></div>
              {l.label}
            </NavLink>
          </li>)}
          <div className={styles.separator}></div>
          {links.slice(4, 7).map((l) => <li key={l.label}>
            <NavLink
              to={l.pathname}
              activeClassName={styles['active-link']}>
              <div className={styles.puce}></div>
              {l.label}
            </NavLink>
          </li>)}
          <div className={styles.separator}></div>
          {links.slice(7, 9).map((l) => <li key={l.label}>
            <NavLink
              to={l.pathname}
              activeClassName={styles['active-link']}>
              <div className={styles.puce}></div>
              {l.label}
            </NavLink>
          </li>)}
          <div className={styles.separator}></div>
          {links.slice(9, links.length).map((l) => <li key={l.label}>
            <NavLink
              to={l.pathname}
              activeClassName={styles['active-link']}>
              <div className={styles.puce}></div>
              {l.label}
            </NavLink>
          </li>)}
        </ul>
        <div className={styles.profile}>
          <div className={styles.name}>
            <p>{userAuth?.firstName} {userAuth?.lastName}</p>
          </div>
          <button
            className={styles.logout}
            onClick={() => logout()}
            >
            Déconnexion
            <img src={logoutImg} alt="logout"/>
          </button>
        </div>
      </div>
    </div>
  );
}
