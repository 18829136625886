const style = {
  container: (provided) => ({ ...provided }),
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: 40,
    borderRadius: state?.selectProps?.noRadius === true ? 3 : 0,
    boxShadow: '0 0 0 1px transparent',
    border: '1px solid rgba(79, 60, 200, 0.3);',
    '&:hover': {
      border: state.isFocused ? '1px solid rgba(79, 60, 200, 0.7);' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    zIndex: 3000,
    backgroundColor: state.isSelected ? '#503CC8' : '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(59, 43, 146, 0.3)',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};

export default style;
