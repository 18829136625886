import React, {
  useRef, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { format } from 'date-fns';
import { deleteFileAction } from '../../../../actions/filesActions';

import {
  Textarea,
} from '../../../../lib/HooksFormFields';
import InputFile from '../../../InputFile/InputFile';
import { API_URL } from '../../../../actions';

import styles from './Com.module.scss';
import FormList from '../../../FormList/FormList';

export default function Com({
  control, watch, submit, activeForm, setValue,
}) {
  const dispatch = useDispatch();
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  function deleteFile(id) {
    deleteFileAction(dispatch, id);
    submit({ files: watch('files').filter((d) => d._id !== id) });
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>Communication projet</h3>
              <div className={styles.list}>
              {watch('comunicationDates')?.map((d) => <div
                key={d._id}
                className={styles.item}
                >
                <p className={styles.bold}>{d?.title}</p>
                <p>{d?.date && format(new Date(d.date), 'dd/MM/yy')}</p>
                <button
                  type="button"
                  onClick={() => setValue('comunicationDates', watch('comunicationDates').filter((cd) => cd?._id !== d._id || cd?.title !== d.title))}
                  >
                  <AiFillDelete size={20} />
                </button>
              </div>)}
            </div>
            <FormList
              list={watch('comunicationDates') || []}
              handleChange={(arr) => setValue('comunicationDates', arr)}
              btn="Ajouter une date de communication"
              model={[
                {
                  label: 'Titre',
                  key: 'title',
                  type: 'text',
                },
                {
                  label: 'Date',
                  key: 'date',
                  type: 'date',
                },
              ]}
            />
            </section>
            <section className={styles['file-section']}>
              <h3>Supports</h3>
              <div className={styles.list}>
                {watch('files')?.map((f) => {
                  // eslint-disable-next-line prefer-template
                  const url = API_URL + '/' + f.path;
                  return <div key={f._id} className={styles.file}>
                    <a href={url} target='_blank' rel="noreferrer">
                      {f.name}
                    </a>
                    <button
                      type="button"
                      onClick={() => deleteFile(f._id)}
                      >
                      <AiFillDelete size={20} />
                    </button>
                  </div>;
                })}
              </div>
              <div>
                <InputFile
                  activeFile={null}
                  handleChange={(fileId) => submit({ files: [...watch('files').map((f) => f._id), fileId] })}
                  name={'financeFile'}
                  />
              </div>
            </section>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>CLôTURE</h3>
              <div className={styles['input-block']}>
                <label>Satisfaction</label>
                <div className={styles.satisfaction}>
                  <button
                    type="button"
                    className={watch('satisfaction') === 'very good' ? styles.active : ''}
                    onClick={() => setValue('satisfaction', 'very good')}
                    >
                    Très bon
                  </button>
                  <button
                    type="button"
                    className={watch('satisfaction') === 'good' ? styles.active : ''}
                    onClick={() => setValue('satisfaction', 'good')}
                    >
                    Bon
                  </button>
                  <button
                    type="button"
                    className={watch('satisfaction') === 'poor' ? styles.active : ''}
                    onClick={() => setValue('satisfaction', 'poor')}
                    >
                    Médiocre
                  </button>
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Rapport</label>
                <div className={styles.input}>
                  <Textarea
                    name='report'
                    control={control}
                    className={'primary'}
                    placeholder={''}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
