import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from './input-text.module.scss';

const InputDate = ({
  name,
  control,
  dateValue = null,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  className = 'primary',
  type = 'date',
  dateFormat = 'yyyy-MM-dd',
  handleChangeDate = null,
}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => {
        let inputValue = dateValue || value;
        inputValue = inputValue ? format(new Date(inputValue), dateFormat) : '';
        if (type !== 'date' && inputValue) {
          inputValue = `${format(new Date(value), 'yyyy-MM-dd')}T${format(new Date(dateValue || value), 'HH:mm')}`;
        }
        function handleChange(v) {
          if (handleChangeDate) {
            handleChangeDate(v);
          }
          onChange(v);
        }
        return (
          <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input']}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <input
              ref={ref}
              value={inputValue}
              type={type}
              onBlur={onBlur}
              onChange={(e) => handleChange(e.target.value)}
              className={styles.input}
              placeholder={placeholder}
            />
          </div>
        );
      }}
    />

);

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputDate;
