import {
  getData, putData, postData, deleteData,
} from './index';

import {
  ERROR_NEW,
  GET_NEWS,
  GET_NEW,
  PUT_NEW,
  POST_NEW,
  SET_TOAST,
} from './types';

export async function getNewsAction(dispatch) {
  const url = '/news';
  let news;

  await getData(ERROR_NEW, url, dispatch, true).then((response) => {
    news = response.news;
  });

  dispatch({
    type: GET_NEWS,
    payload: news,
  });
}

export async function getNewAction(dispatch, id) {
  const url = `/news/${id}`;
  let news;

  await getData(ERROR_NEW, url, dispatch, true).then((response) => {
    news = response.news;
  });

  dispatch({
    type: GET_NEW,
    payload: news,
  });
}

export async function postNewAction(dispatch, data) {
  const url = '/news';
  let news;

  await postData(POST_NEW, ERROR_NEW, url, dispatch, data, true).then((response) => {
    news = response.news;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'actualité a été creée",
    },
  });

  dispatch({
    type: POST_NEW,
    payload: news,
  });
}

export async function putNewAction(dispatch, id, data) {
  const url = `/news/${id}`;
  let news;

  await putData(PUT_NEW, ERROR_NEW, url, dispatch, data, true).then((response) => {
    news = response.news;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'actualité a été modifiée",
    },
  });

  dispatch({
    type: PUT_NEW,
    payload: news,
  });
}

export async function deleteNewsAction(dispatch, id) {
  const url = `/news/${id}`;
  let deleled;

  await deleteData(ERROR_NEW, url, dispatch).then((response) => {
    deleled = response;
  });

  if (deleled) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: "l'actualité a été modifiée",
      },
    });
  }
}
