import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';
import ProductIcon from '../../ProductIcon/ProductIcon';

export default function ToolRow({
  styles,
  tool,
  index,
}) {
  const { types } = useSelector((state) => state.tools);
  return (
    <Link to={`/tool/${tool._id}`}className={styles.row} style={{ animationDelay: `${0.08 * index}s` }}>
      <div className={styles.col}>
        <ProductIcon name={tool.title} size={35} />
        <p>{tool?.title}</p>
      </div>
      <div className={`${styles.col}`}>
      <p>{types?.find((t) => t.value === tool?.type)?.label}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        {tool.division?.sort((a, b) => {
          if (a.title < b.title) { return -1; }
          if (a.title > b.title) { return 1; }
          return 0;
        }).map((d) => <p key={d} className={styles.division}>{d}</p>)}
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
