import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { SET_COMPANIES_FILTERS } from '../../../actions/types';
// import TagsList from '../../TagsList/TagsList';

import styles from './CompaniesFilters.module.scss';

export default function CompaniesFilters({
  close,
  isActive,
}) {
  const { filters } = useSelector((state) => state.companies);

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  function handleChangeFilters(key, value) {
    dispatch({
      type: SET_COMPANIES_FILTERS,
      payload: {
        ...filters,
        [key]: value,
      },
    });
  }

  // useEffect(() => {
  //   if (pathologiesList?.length > 0) {
  //     const opts = [];
  //     pathologiesList.forEach((c) => {
  //       if (c?.location?.city && !opts.find(
  //         (o) => o?.value === c?.location?.city?.toLowerCase(),
  //       )) {
  //         opts.push({
  //           value: c.location.city.toLowerCase(),
  //           label: c.location.city,
  //         });
  //       }
  //     });
  //     setCitiesOptions(opts.sort((a, b) => {
  //       if (a.value < b.value) {
  //         return -1;
  //       }
  //       if (b.value < a.value) {
  //         return 1;
  //       }
  //       return 0;
  //     }));
  //   }
  // }, [pathologiesList]);

  return (
    <div className={`${styles.container} ${isActive ? styles['is-active'] : ''}`}>
      <div className={styles.title}>
        <h2>Filtres</h2>
        <button
          onClick={() => close()}
          className={styles.close}>
          <IoMdClose size={30} />
        </button>
      </div>
      <div className={styles.filters}>
        <label>Recherche</label>
        <div className={styles.search}>
          <input
            value={search}
            placeholder={'Votre recherche'}
            onChange={(e) => setSearch(e.target.value)}
            />
          <button
            className={`${search ? styles['is-search'] : ''}`}
            onClick={() => handleChangeFilters('search', search)}
            >
            <IoSearch size={20} />
          </button>
        </div>
        {/* <div className={styles.tags}>
          <TagsList
            label={'Villes'}
            options={citiesOptions}
            selected={filters.cities}
            isFilter
            handleChange={(array) => handleChangeFilters('cities', array)}
            isEdit
            />
        </div> */}

      </div>
    </div>
  );
}
