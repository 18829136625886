import {
  getData, putData, postData,
} from './index';

import {
  ERROR_CONTACT,
  GET_FILTERED_CONTACTS,
  GET_CONTACTS,
  GET_CONTACT,
  PUT_CONTACT,
  POST_CONTACT,
  SET_TOAST,
} from './types';

export async function getContactsAction(dispatch) {
  const url = '/contact';
  let contacts;

  await getData(ERROR_CONTACT, url, dispatch, true).then((response) => {
    contacts = response.contacts;
  });

  dispatch({
    type: GET_CONTACTS,
    payload: contacts,
  });
}

export async function getContactAction(dispatch, id) {
  const url = `/contact/${id}`;
  let contact;

  await getData(ERROR_CONTACT, url, dispatch, true).then((response) => {
    contact = response.contact;
  });

  dispatch({
    type: GET_CONTACT,
    payload: contact,
  });
}

export async function postContactsFilteredAction(dispatch, data) {
  const url = '/contact/filtered';
  let filtered;

  await postData(
    GET_FILTERED_CONTACTS, ERROR_CONTACT, url, dispatch, data, true,
  ).then((response) => {
    filtered = response;
  });

  dispatch({
    type: GET_FILTERED_CONTACTS,
    payload: filtered,
  });
}

export async function postContactAction(dispatch, data) {
  const url = '/contact';
  let contact;

  await postData(POST_CONTACT, ERROR_CONTACT, url, dispatch, data, true).then((response) => {
    contact = response.contact;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le contact a été creé',
    },
  });

  dispatch({
    type: POST_CONTACT,
    payload: contact,
  });
}

export async function putContactAction(dispatch, id, data) {
  const url = `/contact/${id}`;
  let contact;

  await putData(PUT_CONTACT, ERROR_CONTACT, url, dispatch, data, true).then((response) => {
    contact = response.contact;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le contact a été modifié',
    },
  });

  dispatch({
    type: PUT_CONTACT,
    payload: contact,
  });
}
