import React, { useRef, useEffect } from 'react';

import { InputText, StyledSelect, Textarea } from '../../../../lib/HooksFormFields';
import InputPicture from '../../../InputPicture/InputPicture';
import SentenceList from '../../../SentenceList/SentenceList';
import styles from './Infos.module.scss';

export default function Infos({
  control,
  // errors,
  setValue,
  watch,
  submit,
  activeForm,
  companiesOptions,
  therapeuticAreasList,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <div className={`${styles.box}`}>
          <h3>Détails</h3>
          <div className={styles['form-group']}>
            <label>Dénomination du produit</label>
            <div className={styles.value}>
              <InputText
                name='name'
                control={control}
                className={'primary'}
                placeholder={'Nom'}
              />
            </div>
          </div>
          <div className={styles['form-group']}>
            <label>Laboratoire</label>
            <div className={styles.value}>
              <StyledSelect
                name="company"
                control={control}
                value={companiesOptions?.find((o) => o.value === watch('company'))}
                onChange={(opt) => setValue('company', opt.value)}
                placeholder="Selectionner une entreprise..."
                options={companiesOptions}
              />
            </div>
          </div>
          <div className={styles['form-group']}>
            <label>Aire thérapeutique</label>
            <div className={styles.value}>
              <StyledSelect
                name="therapeuticAreas"
                control={control}
                value={therapeuticAreasList?.filter((o) => watch('therapeuticAreas')?.find((t) => o.value === t))}
                onChange={(array) => setValue('therapeuticAreas', array.map((t) => t.value))}
                isMulti
                placeholder="Selectionner un aire thérapeutique..."
                options={therapeuticAreasList}
              />
            </div>
          </div>
          <div className={styles['form-group']}>
            <label>Description</label>
            <div className={styles.value}>
              <Textarea
                name='description'
                control={control}
                className={'primary'}
                placeholder={'Description du produit'}
              />
            </div>
          </div>
        </div>

        <div className={`${styles.box}`}>
          <h3>Process de fabrication</h3>
          <label className={styles.bold}>Etape de fabrication</label>
          <SentenceList
            isEdit={true}
            list={watch('fabricationProcess') || []}
            handleChange={(arr) => setValue('fabricationProcess', arr)}
            label='Ajouter étape de fabrication'
            btn='Ajouter'
          />
          <label className={styles.bold}>Inactivation virale</label>
          <SentenceList
            isEdit={true}
            list={watch('viralSecurity') || []}
            handleChange={(arr) => setValue('viralSecurity', arr)}
            label='Ajouter sécurisation'
            btn='Ajouter'
          />
        </div>

      </main>
      <aside className={`${styles.box}`}>
        <h3>Logos</h3>

        <div className={styles['form-group']}>
          <label>Logo du produit</label>
          <div className={styles.value}>
            <InputPicture
              color={'#E3E0F5'}
              icon={true}
              picture={watch('logoDrug')}
              isEdit={true}
              handleChange={(fileId) => submit({ logoDrug: fileId })}
              name={'logoDrug'}
              />
          </div>
        </div>

        <div className={styles['form-group']}>
          <label>Logo du laboratoire</label>
          <div className={styles.value}>
            <InputPicture
              color={'#E3E0F5'}
              icon={true}
              picture={watch('logoCompany')}
              isEdit={true}
              handleChange={(fileId) => submit({ logoCompany: fileId })}
              name={'logoCompany'}
              />
          </div>
        </div>

      </aside>
    </div>
  );
}
