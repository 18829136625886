import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import { projectsTypesOptions } from '../../../../constants/constants';
import {
  InputText, ErrorField, StyledSelect, Textarea, InputFormatNumber, InputDate, Checkbox,
} from '../../../../lib/HooksFormFields';
import InputFile from '../../../InputFile/InputFile';
import FormList from '../../../FormList/FormList';

import { getCentersAction } from '../../../../actions/centersActions';
import { deleteFileAction } from '../../../../actions/filesActions';

import { API_URL } from '../../../../actions';

import styles from './Infos.module.scss';
import { getDrugsAction } from '../../../../actions/drugsActions';

export default function Infos({
  control,
  errors,
  setValue,
  watch,
  submit,
  activeForm,
  companiesOptions,
}) {
  const dispatch = useDispatch();
  const centers = useSelector((state) => state.centers);
  const drugs = useSelector((state) => state.drugs);
  const containerRef = useRef();

  const [centersOptions, setCentersOptions] = useState();
  const [drugsOptions, setDrugsOptions] = useState();

  const getDatas = useCallback(() => {
    getCentersAction(dispatch);
    getDrugsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (centers.centersList.length) {
      const options = [];
      centers?.centersList.forEach((c) => {
        options.push({
          label: c.name,
          value: {
            center: c._id,
            service: null,
          },
        });
        c?.services?.forEach((s) => {
          // eslint-disable-next-line prefer-template
          const serviceName = c.name + ' ' + s.name;
          options.push({
            label: serviceName,
            value: {
              center: c._id,
              service: s._id,
            },
          });
        });
      });
      setCentersOptions(options);
    }
    // eslint-disable-next-line
  }, [centers]);

  useEffect(() => {
    if (drugs.drugsList.length) {
      const options = [];
      drugs.drugsList.forEach((d) => {
        options.push({
          label: d.name,
          value: d._id,
        });
      });
      setDrugsOptions(options);
    }
  }, [drugs.drugsList]);

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  function deleteFinancialFile(id) {
    deleteFileAction(dispatch, id);
    setValue('financeFile', null);
    submit({ financeFile: null });
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <div className={styles['input-block']}>
                <label>Titre</label>
                <div className={styles.input}>
                  <InputText
                    name='name'
                    control={control}
                    className={'primary'}
                    rules={{ required: 'Le nom est requis' }}
                    placeholder={'Nom'}
                  />
                  {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Type</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="type"
                    control={control}
                    value={projectsTypesOptions.find((o) => o.value === watch('type'))}
                    onChange={(opt) => setValue('type', opt.value)}
                    placeholder="Selectionner un type..."
                    options={projectsTypesOptions}
                  />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Entreprise</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="company"
                    control={control}
                    value={companiesOptions?.find((o) => o.value === watch('company'))}
                    onChange={(opt) => setValue('company', opt.value)}
                    placeholder="Selectionner une entreprise..."
                    options={companiesOptions}
                  />
                </div>
              </div>
              <div className={styles['input-block']}>
              </div>
              <div className={styles['input-block']}>
                <div className={styles.input}>
                  <Checkbox
                    name='isInstitutional'
                    control={control}
                    className={'primary'}
                    label={'Projet institutionnel'}
                  />
                </div>
              </div>
            </section>
            <section>
              <h3>Analyse du besoin</h3>
              <div className={styles['input-row']}>
                <div className={styles['input-block']}>
                  <label>Durée (en mois)</label>
                  <div className={styles.input}>
                    <InputFormatNumber
                      name='duration'
                      control={control}
                      className={'primary'}
                      placeholder={'Durée (en mois)'}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['input-row']}>
                <div className={styles['input-block']}>
                  <label>Date de démarrage du projet</label>
                  <div className={styles.input}>
                    <InputDate
                      name="startDate"
                      control={control}
                      rules={{ required: 'La date de début est requise' }}
                      widthLabel={'30%'}
                      placeholder={'Date de démarrage'}
                    />
                    {errors?.startDate?.message
                      && <ErrorField message={errors?.startDate?.message} />}
                  </div>
                </div>
                <div className={styles['input-block']}>
                  <label>Fin du projet</label>
                  <div className={styles.input}>
                    <InputDate
                      name="endDate"
                      control={control}
                      rules={{ required: 'La date de fin est requise' }}
                      widthLabel={'30%'}
                      placeholder={'Date de fin'}
                    />
                    {errors?.endDate?.message
                      && <ErrorField message={errors?.endDate?.message} />}
                  </div>
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Situation actuelle</label>
                <div className={styles.input}>
                  <Textarea
                    name='situation'
                    control={control}
                    className={'primary'}
                    placeholder={'Situation actuelle'}
                  />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Objectif(s)</label>
                <div className={styles.input}>
                  <Textarea
                    name='objective'
                    control={control}
                    className={'primary'}
                    placeholder={'Objectif(s)'}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.box}>
            <section>
              <h3>CONTRATS</h3>
              <div className={styles.list}>
                {watch('contracts')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div className={styles.col2}>
                    <p>{c?.provider}</p>
                    <p>{c?.weekNumber} semaines</p>
                    <p className={styles.date}>du {c?.signatureDate && format(new Date(c.signatureDate), 'dd/MM/yy')} au {c?.endDate && format(new Date(c?.endDate), 'dd/MM/yy')}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ contracts: watch('contracts').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              <FormList
                list={watch('contracts') || []}
                handleChange={(arr) => submit({ contracts: arr })}
                btn="Ajouter"
                model={[
                  {
                    label: 'Date de début',
                    key: 'signatureDate',
                    type: 'date',
                  },
                  {
                    label: 'Date de fin',
                    key: 'endDate',
                    type: 'date',
                  },
                  {
                    label: 'Nombre de semaine',
                    key: 'weekNumber',
                    type: 'number',
                  },
                  {
                    label: 'Nom intervenant',
                    key: 'provider',
                    type: 'text',
                  },
                ]}
                />
            </section>
            <section className={styles['file-section']}>
              <h3>FICHE PROJET POUR FINANCE</h3>
              {watch('financeFile')
                ? <div className={styles.file}>
                    <a href={`${API_URL}/${watch('financeFile').path}`} target='_blank' rel="noreferrer">
                      {watch('financeFile').name}
                    </a>
                    <button
                      type="button"
                      onClick={() => deleteFinancialFile(watch('financeFile')._id)}
                      >
                      <AiFillDelete size={20} />
                    </button>
                  </div>
                : <>
                    <InputFile
                    activeFile={null}
                    handleChange={(fileId) => submit({ financeFile: fileId })}
                    name={'financeFile'}
                  />
                </>
              }
            </section>
            <section>
              <h3>Coût du projet</h3>
              <div className={styles.list}>
                {watch('costs')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div className={styles.col2}>
                    <p>{c?.ref}</p>
                    <p>{c?.amount} €</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ costs: watch('costs').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              <FormList
                list={watch('costs') || []}
                handleChange={(arr) => submit({ costs: arr })}
                btn="Ajouter"
                model={[
                  {
                    label: 'Ref du devis',
                    key: 'ref',
                    type: 'text',
                  },
                  {
                    label: 'Montant',
                    key: 'amount',
                    type: 'number',
                  },
                ]}
                />
            </section>
          </div>
          <div className={styles.box}>
            <section>
              <h3>ETABLISSEMENTS ASSOCIés</h3>
              <div className={styles.list}>
                {watch('centers')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div>
                    <p>
                      {c.center.name}
                      {c.service && c.center?.services?.find((s) => s._id === c.service)?.name
                        && <span> - {c.center?.services?.find((s) => s._id === c.service)?.name}
                        </span>
                      }
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ centers: watch('centers').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              <FormList
                list={watch('centers') || []}
                placeholder="Selectionner un centre..."
                control={control}
                handleChange={(arr) => submit({ centers: arr })}
                btn="Ajouter"
                options={centersOptions}
                />
            </section>
          </div>
          <div className={styles.box}>
            <section>
              <h3>Produits ASSOCIés</h3>
              <div className={styles.list}>
                {watch('drugs')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div>
                    <p>{c.name}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => submit({ drugs: watch('drugs').filter((d) => d._id !== c._id) })}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
              <FormList
                list={watch('drugs') || []}
                placeholder="Selectionner un produit..."
                control={control}
                handleChange={(arr) => submit({ drugs: arr })}
                btn="Ajouter"
                options={drugsOptions}
                />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
