import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { getCongressesAction } from '../../../actions/congressesActions';
import { GET_CONGRESS } from '../../../actions/types';

import { Button } from '../../../lib/HooksFormFields';
import SortButton from '../../../lib/SortButton';

// import ProjectsFilters from '../../../components/Projects/ProjectsFilters/ProjectsFilters';
// import ProjectRow from '../../../components/Projects/ProjectRow/ProjectRow';

import styles from './CongressesList.module.scss';
import CongressRow from '../../../components/Congresses/CongressRow/CongressRow';
import CongressFilters from '../../../components/Congresses/CongressFilters/CongressFilters';
import CongressCard from '../../../components/Congresses/CongressCard/CongressCard';

export default function CongressesList() {
  const { congressesList, filters } = useSelector((state) => state.congresses);
  const dispatch = useDispatch();
  const history = useHistory();

  const nextCongresses = congressesList?.filter(
    (c) => c?.startDate && new Date(c.startDate) > new Date(),
  );

  const initSortState = {
    name: true,
    startDate: true,
    endDate: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getCongressesAction(dispatch);
    dispatch({
      type: GET_CONGRESS,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'city':
        list = list.sort((a, b) => {
          if (a.city < b.city) return -1;
          if (a.city > b.city) return 1;
          return 0;
        });
        break;
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        break;
      case 'startDate':
        list = list.sort((a, b) => {
          const aDate = a?.startDate ? new Date(a?.startDate) : new Date();
          const bDate = b?.startDate ? new Date(b?.startDate) : new Date();
          if (aDate > bDate) return -1;
          if (aDate < bDate) return 1;
          return 0;
        });
        break;
      case 'endDate':
        list = list.sort((a, b) => {
          const aDate = a?.endDate ? new Date(a?.endDate) : new Date();
          const bDate = b?.endDate ? new Date(b?.endDate) : new Date();
          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    setFilteredList(isReverse ? list.reverse() : list);
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    if (filters?.cities?.length) {
      val += filters?.cities?.length;
    }
    return val;
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    let list = congressesList;
    if (filters.search) {
      list = list.filter((p) => {
        if (p.name.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        if (p?.situation?.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        if (p?.objective?.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        return null;
      });
    }
    if (filters.types.length) {
      list = list.filter((p) => {
        if (filters.types.find((f) => f?.toLowerCase() === p?.type?.toLowerCase())) {
          return p;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [congressesList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>Congrès</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/congress/create?form=0')}>
              NOUVEAU CONGRÈS
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        {nextCongresses.length > 0
          && <div className={styles.next}>
            <h3>A venir</h3>
            <div className={styles.list}>
             {nextCongresses.map((n) => <CongressCard key={`next-${n._id}`} card={n}/>)}
            </div>
          </div>
        }
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <SortButton
              name={'Ville'}
              handleClick={() => sortList('city', !sortByReverse.city)}
              state={sortByReverse.city}
              className={`${styles.col} ${styles.desktop}`}/>
            <SortButton
              name={'Date début'}
              handleClick={() => sortList('startDate', !sortByReverse.startDate)}
              state={sortByReverse.startDate}
              className={`${styles.col} ${styles.desktop}`}/>
            <SortButton
              name={'Date de fin'}
              handleClick={() => sortList('endDate', !sortByReverse.endDate)}
              state={sortByReverse.endDate}
              className={`${styles.col} ${styles.desktop}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          className={styles.list}
          >
          {!isLoading && filteredList?.length > 0 && filteredList?.map((c, i) => <CongressRow
            key={c._id}
            congress={c}
            index={i}
            styles={styles}
          />)}
        </div>
      </div>
      <CongressFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
