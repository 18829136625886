import React, { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import styles from './SentenceList.module.scss';

export default function SentenceList({
  list, handleChange, objKey, isEdit, label, btn,
}) {
  const [value, setValue] = useState('');

  function onChange(val) {
    setValue('');
    let newList = [...list];
    const found = newList.find((s) => s[objKey] === val || s === val);

    if (found) {
      newList = newList.filter((s) => {
        if (s === found) return null;
        return s;
      });
      handleChange(newList);
    } else {
      const newVal = objKey ? { [objKey]: val } : val;
      handleChange([...newList, newVal]);
    }
  }

  const itemClass = `${styles.item} ${isEdit ? styles.edit : ''}`;

  return (
    <div className={styles.list}>
      {list?.map((c) => <div key={c._id || c[objKey] || c} className={itemClass}>
        <p>{c[objKey] ? c[objKey] : c}</p>
        {isEdit
          && <button
              type='button'
              onClick={() => onChange(c[objKey] || c)}
              >
            <AiFillDelete size={18} />
          </button>
        }
      </div>)}
      {isEdit
        && <div className={styles['add-item']}>
          <div className={styles['input-container']}>
            <label>{label}</label>
            <input className={styles.input}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              />
          </div>
          <button
            type="button"
            onClick={() => onChange(value)}
            disabled={value === ''}
            >
            {btn}
          </button>
        </div>
      }
    </div>
  );
}
