import {
  ERROR_CONTACT,
  GET_CONTACTS,
  GET_CONTACT,
  PUT_CONTACT,
  POST_CONTACT,
  SET_CONTACT_FILTERS,
  GET_FILTERED_CONTACTS,
  SET_CONTACT_SCROLL,
} from '../actions/types';

const initialState = {
  error: null,
  contactsList: [],
  count: 0,
  contact: null,
  scrollY: 0,
  filters: {
    search: null,
    page: 1,
    orderBy: { key: 'firstName', order: 'asc' },
    number: 15,
  },
};

export default function contactsReducer(state = initialState, action) {
  let contactsList = state?.contactsList;
  const index = state?.contactsList?.findIndex((contact) => contact?._id === action?.payload?._id);

  switch (action.type) {
    case POST_CONTACT:
      return {
        ...state,
        contactsList: [...contactsList, action.payload],
        contact: action.payload,
      };

    case PUT_CONTACT:
      contactsList[index] = action.payload;
      return {
        ...state,
        contactsList,
        contact: {
          ...action.payload,
          questions: state.contact.questions,
        },
      };

    case SET_CONTACT_FILTERS:
      return { ...state, filters: action.payload };

    case SET_CONTACT_SCROLL:
      return { ...state, scrollY: action.payload };

    case GET_CONTACT:
      return { ...state, contact: action.payload };

    case GET_CONTACTS:
      return { ...state, contactsList: action.payload };

    case GET_FILTERED_CONTACTS:
      if (state.filters.page === 1) {
        contactsList = action.payload.contacts?.map((u, i) => ({ ...u, index: i }));
      } else {
        contactsList = [
          ...contactsList,
          ...action.payload.contacts?.map((u, i) => ({ ...u, index: i })),
        ];
      }
      return {
        ...state, contactsList, count: action.payload.count, contact: null,
      };

    case ERROR_CONTACT:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
