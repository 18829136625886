import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { IoChevronBack, IoSave } from 'react-icons/io5';
// import { CgColorPicker } from 'react-icons/cg';

import Button from '../../../lib/HooksFormFields/Button';

import styles from './EditPathology.module.scss';
import { getPathologyAction, postPathologyAction, putPathologyAction } from '../../../actions/pathologiesActions';
import Infos from '../../../components/Pathologies/PathologieForm/Infos/Infos';
import Mutations from '../../../components/Pathologies/PathologieForm/Mutations/Mutations';
import Form from '../../../components/Pathologies/PathologieForm/Form/Form';

export default function EditPathology() {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const { pathology } = useSelector((state) => state.pathologies);

  const navRef = useRef();
  const nav = [
    {
      value: 0,
      label: 'Informations',
    },
    {
      value: 1,
      label: 'Formulaire',
    },
    {
      value: 2,
      label: 'Mutations & symptomes',
    },
  ];
  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).form);

  const getPathology = useCallback(() => {
    getPathologyAction(dispatch, id);
  }, [dispatch, id]);

  function handleChangeForm(val) {
    setActiveForm(val);
    window.history.replaceState(null, null, `?form=${val}`);
  }

  function submitPathology(values) {
    if (pathology?._id) {
      putPathologyAction(dispatch, pathology._id, values);
    } else {
      postPathologyAction(dispatch, values);
    }
  }

  useEffect(() => {
    navRef.current.scrollTo({
      left: (120 * activeForm),
      top: 0,
      behavior: 'smooth',
    });
  }, [activeForm]);

  useEffect(() => {
    if (id) {
      getPathology();
    }
  }, []);

  useEffect(() => {
    if (pathology) {
      const propreties = [
        'name',
        'category',
        'color',
        'types',
        'form',
      ];
      propreties.forEach((p) => {
        if (pathology[p]) {
          setValue(p, pathology[p]);
        }
      });
    }
  }, [pathology, pathology?.updatedAt]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{pathology?.name
            ? <span>PATHOLOGIES / {pathology.name}</span>
            : <span>Nouvelle PATHOLOGIE</span>
            }
          </h2>
          <Link to={'/pathology'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(submitPathology)}
            className={styles.edit}>
            <span>Enregistrer la fiche</span>
            <IoSave size={28} />
          </Button>
        </div>
      </header>
      <nav className={styles.nav}>
        <ul ref={navRef}>
          {nav.map((l) => <li
            key={l.label}
            className={`${Number(activeForm) === l.value ? styles['active-form'] : ''}`}
            onClick={() => handleChangeForm(l.value) }>
            {l.label}
          </li>)}
        </ul>
      </nav>
      <main className={`${styles.content}`}>
        <div className={styles['form-container']} style={{ transform: `translateX(-${(Number(activeForm) * 100) / 3}%)` }}>
          <Infos
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            pathology={pathology}
            activeForm={activeForm}
            submit={(obj) => submitPathology({ ...watch(), ...obj })}
          />
          <Form
            pathology={pathology}
            activeForm={activeForm}
            submit={(obj) => submitPathology({ ...watch(), ...obj })}

          />
          <Mutations
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            pathology={pathology}
          />
        </div>
      </main>
    </form>
  );
}
