import fileDownload from 'js-file-download';
import {
  getData, getFile, postData, putData,
} from './index';

import {
  ERROR_CONGRESS,
  GET_CONGRESSES,
  GET_CONGRESS,
  POST_CONGRESS,
  PUT_CONGRESS,
  SET_TOAST,
  // GET_CONGRESSES_LISTS,
} from './types';

export async function getCongressesAction(dispatch) {
  const url = '/congress';
  let congresses;

  await getData(ERROR_CONGRESS, url, dispatch, true).then((response) => {
    congresses = response.congress.sort((a, b) => {
      const aDate = a?.startDate ? new Date(a?.startDate) : new Date();
      const bDate = b?.startDate ? new Date(b?.startDate) : new Date();
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });
  });

  dispatch({
    type: GET_CONGRESSES,
    payload: congresses,
  });
}

export async function getCongressAction(dispatch, id) {
  const url = `/congress/${id}`;
  let congress;

  await getData(ERROR_CONGRESS, url, dispatch, true).then((response) => {
    congress = response.congress;
  });

  dispatch({
    type: GET_CONGRESS,
    payload: congress,
  });
}

export async function exportCongressAction(dispatch, id, name = 'congrès') {
  const url = `/congress/${id}/export`;
  let congress;

  await getFile(ERROR_CONGRESS, url, dispatch, true).then((response) => {
    congress = response;
  });

  if (congress) {
    fileDownload(congress, name);
  }
}

export async function postCongressPeopleFileAction(dispatch, id, formData) {
  const url = `/congress/${id}/upload`;
  let file;

  await postData(null, ERROR_CONGRESS, url, dispatch, formData, true).then((response) => {
    file = response;
  });

  return file;
}

// export async function getCongressesListsAction(dispatch) {
//   const url = '/congress/list';
//   let lists;

//   await getData(ERROR_CONGRESS, url, dispatch, true).then((response) => {
//     lists = response.lists;
//   });

//   dispatch({
//     type: GET_CONGRESSES_LISTS,
//     payload: lists,
//   });
// }

export async function postCongressAction(dispatch, data) {
  const url = '/congress';
  let congress;

  await postData(POST_CONGRESS, ERROR_CONGRESS, url, dispatch, data, true).then((response) => {
    congress = response.congress;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le congrès a été creé',
    },
  });

  dispatch({
    type: POST_CONGRESS,
    payload: congress,
  });
}

export async function putCongressAction(dispatch, id, data) {
  const url = `/congress/${id}`;
  let congress;

  await putData(PUT_CONGRESS, ERROR_CONGRESS, url, dispatch, data, true).then((response) => {
    congress = response.congress;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le congrès a été modifié',
    },
  });

  dispatch({
    type: PUT_CONGRESS,
    payload: congress,
  });
}
