import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import uniqid from 'uniqid';
import { AiFillDelete } from 'react-icons/ai';
import { FiBox } from 'react-icons/fi';
import { IoChevronDownOutline, IoMedkitOutline, IoBriefcaseOutline } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { RiHandCoinLine } from 'react-icons/ri';
import { ErrorField, InputText } from '../../../../../lib/HooksFormFields';
import styles from './Service.module.scss';
import FormList from '../../../../FormList/FormList';
import { putProjectAction } from '../../../../../actions/projectsActions';

export default function Service({
  service,
  handleChange,
  drugsOptions,
  projectsOptions,
  centerId,
  projects,
}) {
  const dispatch = useDispatch();

  const contentRef = useRef();
  const donationRef = useRef();
  const drugRef = useRef();
  const medicalRef = useRef();
  const marketingRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [donationIsOpen, setDonationIsOpen] = useState(false);
  const [drugIsOpen, setDrugIsOpen] = useState(false);
  const [medicalIsOpen, setMedicalIsOpen] = useState(false);
  const [marketingIsOpen, setMarketingIsOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const height = contentRef?.current?.clientHeight || 0;

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: {
      errors,
    },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => service, [service]),
  });

  function handleChangeProject(project) {
    let centers = project.centers || [];
    const index = centers.findIndex((c) => c.service === service._id);
    if (index >= 0) {
      centers = centers.filter((c) => c.service !== service._id);
    } else {
      centers = [...centers, { service: service._id, center: centerId }];
    }
    putProjectAction(dispatch, project._id, { centers });
  }

  useEffect(() => {
    if (isOpen) {
      const values = getValues();
      handleChange(values);
      setIsUpdated(!isUpdated);
    }
  }, [watch('name'), watch('donations'), watch('drugs')]);

  useEffect(() => {
    setIsUpdated(!isUpdated);
  }, [donationIsOpen, drugIsOpen, medicalIsOpen, marketingIsOpen, service, projects]);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h1>{service?.name ? service?.name : 'Nouveau service'}</h1>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''} ${isUpdated ? '' : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && height > 0 ? height : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          <div className={styles.section}>
            <div className={`${styles.block}`}>
            <label>Nom du service</label>
            <div className={styles.value}>
              <InputText
                name='name'
                control={control}
                className={'primary'}
                rules={{ required: 'Le nom est requis' }}
                placeholder={'Nom'}
              />
              {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
            </div>
          </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <h2>
                <span className={styles.icon}><FiBox size={20}/></span>
                PRODUITS ASSOCIéS {watch('drugs')?.length > 0 && `(${watch('drugs')?.length})`}
              </h2>
              <button
                type="button"
                onClick={() => setDrugIsOpen(!drugIsOpen)}
                className={`${styles.toggle} ${drugIsOpen ? styles.open : ''}`}>
                <IoChevronDownOutline size={20}/>
              </button>
            </div>
            <div
              className={styles['section-content']}
              style={{
                display: !drugIsOpen ? 'none' : 'block',
              }}
              >
              <div ref={drugRef}>
                <div className={styles.list}>
                {watch('drugs')?.map((c) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.bold}>
                    <p>{drugsOptions?.find((d) => d.value === c)?.label}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => setValue('drugs', watch('drugs').filter((d) => d !== c))}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
                <FormList
                  list={watch('drugs') || []}
                  handleChange={(arr) => setValue('drugs', arr)}
                  btn="Ajouter"
                  control={control}
                  options={drugsOptions || []}
                  />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <h2>
                <span className={styles.icon}><RiHandCoinLine size={20}/></span>
                Dons {watch('donations')?.length > 0 && `(${watch('donations')?.length})`}
              </h2>
              <button
                type="button"
                onClick={() => setDonationIsOpen(!donationIsOpen)}
                className={`${styles.toggle} ${donationIsOpen ? styles.open : ''}`}>
                <IoChevronDownOutline size={20}/>
              </button>
            </div>
            <div
              className={styles['section-content']}
              style={{
                display: !donationIsOpen ? 'none' : 'block',
              }}
              >
              <div ref={donationRef}>
                <div className={styles.list}>
                {watch('donations')?.map((c) => <div
                  key={c._id}
                  className={styles.item}
                  >
                  <div className={styles.don}>
                    {c?.url
                      ? <a
                        href={c.url}
                        rel="noreferrer"
                        target='_blank' >
                        {c?.name}
                      </a>
                      : <p>{c?.name}</p>
                    }
                    <p>{c?.amount} €</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => setValue('donations', watch('donations').filter((d) => d._id !== c._id))}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
                <FormList
                  list={watch('donations') || []}
                  handleChange={(arr) => setValue('donations', arr)}
                  btn="Ajouter"
                  model={[
                    {
                      label: 'Nom',
                      key: 'name',
                      type: 'text',
                    },
                    {
                      label: 'Montant',
                      key: 'amount',
                      type: 'number',
                    },
                    {
                      label: 'Url',
                      key: 'url',
                      type: 'url',
                    },
                  ]}
                  />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <h2>
                <span className={styles.icon}><IoMedkitOutline size={20}/></span>
                PROJETS MEDICAUX {projects.filter((p) => p?.type === 'medical')?.length > 0 && `(${projects.filter((p) => p?.type === 'medical')?.length})`}
              </h2>
              <button
                type="button"
                onClick={() => setMedicalIsOpen(!medicalIsOpen)}
                className={`${styles.toggle} ${medicalIsOpen ? styles.open : ''}`}>
                <IoChevronDownOutline size={20}/>
              </button>
            </div>
            <div
              className={styles['section-content']}
              style={{
                display: !medicalIsOpen ? 'none' : 'block',
              }}
              >
              <div ref={medicalRef}>
                <div className={styles.list}>
                {projects.filter((p) => p?.type === 'medical')?.map((p) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.bold}>
                    <p>{p.label}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleChangeProject(p.value)}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
                <FormList
                  list={[]}
                  handleChange={(arr) => handleChangeProject(arr[0])}
                  btn="Ajouter"
                  control={control}
                  options={projectsOptions.filter((p) => p?.type === 'medical') || []}
                  />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <h2>
                <span className={styles.icon}><IoBriefcaseOutline size={20}/></span>
                PROJETS MARKETING {projects.filter((p) => p?.type === 'marketing')?.length > 0 && `(${projects.filter((p) => p?.type === 'marketing')?.length})`}
              </h2>
              <button
                type="button"
                onClick={() => setMarketingIsOpen(!marketingIsOpen)}
                className={`${styles.toggle} ${marketingIsOpen ? styles.open : ''}`}>
                <IoChevronDownOutline size={20}/>
              </button>
            </div>
            <div
              className={styles['section-content']}
              style={{
                display: !marketingIsOpen ? 'none' : 'block',
              }}
              >
              <div ref={marketingRef}>
                <div className={styles.list}>
                {projects.filter((p) => p?.type === 'marketing')?.map((p) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.bold}>
                    <p>{p.label}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleChangeProject(p.value)}
                    >
                    <AiFillDelete size={20} />
                  </button>
                </div>)}
              </div>
                <FormList
                  list={[]}
                  handleChange={(arr) => handleChangeProject(arr[0])}
                  btn="Ajouter"
                  control={control}
                  options={projectsOptions.filter((p) => p?.type === 'marketing') || []}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
