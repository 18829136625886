import React, {
  useRef, useEffect,
} from 'react';

import TagsList from '../../../TagsList/TagsList';

import BoxPanel from '../../../BoxPanel/BoxPanel';
import { generateKey } from '../../../../utils/utils';

import styles from './Octa.module.scss';
import FilesPreview from '../../../FilesPreview/FilesPreview';
import { API_URL } from '../../../../actions';

export default function Octa({
  congress,
  activeForm,
}) {
  const booleanOptions = [{ value: true, label: 'Oui' }, { value: false, label: 'Non' }];
  const symposiums = congress?.symposium?.symposiums;
  const containerRef = useRef();

  const getStand = () => {
    let stand = '';
    if (congress?.participation?.stand) stand = 'Stand';
    if (congress?.participation.standCorporate) stand += ' corporate';
    if (congress?.participation.standProvider) return 'Standiste';
    return stand;
  };

  const getSymposium = () => {
    let symposium = '';
    if (congress?.symposium?.symposium) symposium = 'Symposium';
    if (congress?.symposium?.symposiumCorporate) symposium = 'Symposium corporate';
    return symposium;
  };

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <BoxPanel label={'STAND'}>
            {getStand()
              && <div className={styles['input-boolean']} >
                <div className={styles['input-block']}>
                  <div className={styles.input}>
                    {getStand()}
                  </div>
                </div>
              </div>
            }
            {congress?.participation?.stand
            && <div className={styles['input-row']}>
              <div className={styles['input-block']}>
                <label>Emplacement du stand</label>
                <div className={styles.input}>
                <p>{congress?.participation?.location}</p>
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Taille du stand </label>
                <div className={styles.input}>
                  <p>{congress?.participation?.size} m<sup>2</sup></p>
                </div>
              </div>
            </div>
            }
            {congress?.participation.files.length > 0
              && <FilesPreview key="participation" files={congress?.participation.files} />
            }
            <div className={styles['input-boolean']} >
              <div className={styles['input-block']}>
                <label className={styles.bold}>Invités</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={false}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter(
                      (opt) => opt.value === congress?.participation.guests,
                    ).map((o) => o.value)}
                    />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label className={styles.bold}>Participants</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={false}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter(
                      (opt) => opt.value === congress?.participation.participants,
                    ).map((o) => o.value)}
                    />
                </div>
              </div>
              <div className={styles['input-block']}>
              </div>
            </div>
            {congress?.instruction
              && <>
              <label>Instructions pour ouvrir la réserve</label>
              <p>{congress.instruction}</p>
              </>
            }
            {congress?.installation
              && <>
              <label>Installation et démontage du stand</label>
              <p>{congress.installation}</p>
              </>
            }
          </BoxPanel>
          <BoxPanel label={'SYMPOSIUM'}>
            {getSymposium()
              && <div className={styles['input-boolean']} >
              <div className={styles['input-block']}>
                <div className={styles.input}>
                  {getSymposium()}
                </div>
              </div>
            </div>
            }
            <div className={styles['symposium-list']}>
              {symposiums?.map((h, i) => (
                <div key={generateKey('symposium', i)} className={styles.card}>
                  <p><b>{h.name}</b></p>
                  <div className={styles.address}>
                    <label>{h.location}</label>
                  </div>
                  <p>{h?.description}</p>
                  {h?.files.length > 0
                    && <FilesPreview key="symposium" files={h?.files} />
                  }

                </div>
              ))}
            </div>
          </BoxPanel>
          {congress?.poster?.path
            && <BoxPanel label={'PDF'}>
              <div className={styles['file-section']} >
                <div className={styles.file}>
                  <a href={`${API_URL}/${congress?.poster?.path}`} target='_blank' rel="noreferrer">
                    {congress?.poster?.name || 'fichier'}
                  </a>
                </div>
              </div>
            </BoxPanel>
          }
          <BoxPanel label={'Dotation'}>
            <div className={styles['file-section']}>
              {congress.dotation
                && <FilesPreview key="dotation" files={[congress.dotation]} />
              }
            </div>
          </BoxPanel>
        </div>
      </div>
    </div>
  );
}
