import React, { useRef, useEffect } from 'react';
import { Textarea } from '../../../../lib/HooksFormFields';
import InputPicture from '../../../InputPicture/InputPicture';

import styles from './Reconstitution.module.scss';

export default function Reconstitution({
  submit,
  watch,
  control,
  activeForm,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <div className={styles.box}>
          <h3>RECONSTITUTION</h3>
          <div className={styles['form-group']}>
            <label>Description</label>
            <div className={styles.value}>
              <Textarea
                name='reconstitution.description'
                control={control}
                className={'primary'}
              />
            </div>
          </div>
          <div className={styles['form-group']}>
            <label>Média</label>
            <div className={styles.value}>
              <InputPicture
                color={'#E3E0F5'}
                icon={true}
                isLarge
                picture={watch('reconstitution')?.file}
                isEdit={true}
                handleChange={(fileId) => submit({ reconstitution: { ...watch('reconstitution'), file: fileId } })}
                name={'reconstitution-media'}
                />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
