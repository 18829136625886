import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { HiLink } from 'react-icons/hi';
import { IoChevronBack, IoSave } from 'react-icons/io5';

import { getContactAction, postContactAction, putContactAction } from '../../../actions/contactsActions';

import {
  ErrorField,
  InputEmail,
  InputText,
  StyledSelect,
} from '../../../lib/HooksFormFields';
import Button from '../../../lib/HooksFormFields/Button';

import pen from '../../../assets/images/icons/pen-white.svg';
import styles from './EditContact.module.scss';
import { getCentersAction } from '../../../actions/centersActions';
import { getOctapharamaUsers } from '../../../actions/usersActions';

export default function EditContact({
  isCreate = false,
}) {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: {
      errors,
    },
  } = useForm();
  const { contact } = useSelector((state) => state.contacts);
  const { octapharmaUsers } = useSelector((state) => state.users);
  const noUser = { label: "Pas d'utilisateur", value: null };
  const { centersList } = useSelector((state) => state.centers);
  const selectedUser = octapharmaUsers?.find((o) => o.value === watch('user'));
  const centersOptions = centersList.map((c) => ({ label: `${c.name} - ${c.location.city}`, value: c._id }));
  const noCenter = { label: 'Pas de centre', value: null };
  const selectedCenter = centersList.find((o) => o._id === watch('center'));
  const servicesOptions = selectedCenter?.services && [{ value: '', label: 'pas de service' }, ...selectedCenter.services.map((s) => ({ value: s.name, label: s.name }))];
  const noService = { label: 'Pas de service', value: null };

  const [isEdit, setIsEdit] = useState(false);

  const getContact = useCallback(() => {
    getCentersAction(dispatch);
    getOctapharamaUsers(dispatch);
    getContactAction(dispatch, id);
  }, [dispatch, id]);

  function submitcontact(values) {
    if (contact?._id) {
      putContactAction(dispatch, contact._id, values);
    } else {
      postContactAction(dispatch, values);
    }
  }

  useEffect(() => {
    if (isCreate) {
      setIsEdit(true);
    }
    if (id) {
      getContact();
    }
  }, []);

  useEffect(() => {
    if (contact?.firstName) {
      setValue('firstName', contact?.firstName);
    }
    if (contact?.lastName) {
      setValue('lastName', contact?.lastName);
    }
    if (contact?.qualification) {
      setValue('qualification', contact?.qualification);
    }
    if (contact?.email) {
      setValue('email', contact?.email);
    }
    if (contact?.tel) {
      setValue('tel', contact?.tel);
    }
    if (contact?.city) {
      setValue('city', contact?.city);
    }
    if (contact?.user) {
      setValue('user', contact?.user);
    }
    if (contact?.center) {
      setValue('center', contact?.center || null);
    }
    if (contact?.service) {
      setValue('service', contact?.service);
    }
  }, [contact]);

  useEffect(() => {
    if (selectedCenter?.services?.length === 0) {
      setValue('service', '');
    }
  }, [selectedCenter]);

  function questionUrl(questionId) {
    return `${process.env.REACT_APP_MEDOCTA_URL}/question/${questionId}`;
  }

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{contact?.firstName
            ? <span>CONTACT MédiAUX / {contact?.firstName} {contact?.lastName}</span>
            : <span>Nouveau contact</span>
            }
          </h2>
          <Link to={'/contact'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          {isEdit
            ? <Button
              handleClick={handleSubmit(submitcontact)}
              className={styles.edit}>
              <span>Enregistrer la fiche</span>
              <IoSave size={28} />
            </Button>

            : <Button
              handleClick={() => setIsEdit(!isEdit)}
              className={styles.edit}>
              <span>EDITER LA FICHE</span>
              <img src={pen} alt="edit-user"/>
            </Button>
          }
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={`${styles.col}`}>
          <section>
            <h3>Information</h3>
            <div className={styles['input-list']}>

              <div className={styles.block}>
                <label>Nom</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='lastName'
                          control={control}
                          rules={{ required: 'le nom est requis' }}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                        <ErrorField message={errors?.lastName?.message} />
                      </>
                    : <p className={styles.bold}>
                      {contact?.lastName}
                    </p>
                    || <span>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Prénom</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='firstName'
                          control={control}
                          // rules={{ required: 'le prénom est requis' }}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                        {/* <ErrorField message={errors?.firstName?.message} /> */}
                      </>
                    : <p className={styles.bold}>
                      {contact?.firstName}
                    </p>
                    || <span>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Qualification</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='qualification'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {contact?.qualification}
                    </p>
                    || <span>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Email</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputEmail
                          name='email'
                          control={control}
                          rules={{ required: "l'email est requis" }}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                        <ErrorField message={errors?.email?.message} />
                      </>
                    : <p className={styles.bold}>
                      {contact?.email}
                    </p>
                    || <span>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Téléphone</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='tel'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {contact?.tel || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Ville</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='city'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {contact?.city || <span>non complété</span>}
                    </p>
                  }
                </div>
              </div>

            </div>
          </section>

          <section>
            <h3>Centre</h3>
            <div className={styles.select}>
              {isEdit && centersOptions && centersOptions?.length > 0
                ? <>
                    <StyledSelect
                      name="center"
                      control={control}
                      value={centersOptions.find((o) => o.value === watch('center'))}
                      onChange={(opt) => setValue('center', opt.value)}
                      placeholder="Selectionner un centre..."
                      options={[noCenter, ...centersOptions]}
                    />
                    {selectedCenter?.services?.length > 0
                      && <div className={styles.service}>
                          <StyledSelect
                          name="service"
                          control={control}
                          value={servicesOptions.find((o) => o.value === watch('service'))}
                          onChange={(opt) => setValue('service', opt.value)}
                          placeholder="Selectionner un service..."
                          options={[noService, ...servicesOptions]}
                        />
                      </div>
                    }
                  </>
                : <>
                  {selectedCenter?.name
                    ? <p className={styles.bold}>
                        {selectedCenter?.name} - {selectedCenter?.location.city}
                        {servicesOptions?.find((o) => o.value === watch('service'))?.label
                          && <span className={styles['service-label']}> / {servicesOptions.find((o) => o.value === watch('service'))?.label}</span>
                        }
                    </p>
                    : <p>non complété</p>
                  }
                </>
              }
            </div>
          </section>
          <section>
            <h3>Utilisateur</h3>
            <div className={styles.select}>
              {isEdit && octapharmaUsers && octapharmaUsers?.length > 0
                ? <>
                    <StyledSelect
                      name="center"
                      control={control}
                      value={octapharmaUsers.find((o) => o.value === watch('user'))}
                      onChange={(opt) => setValue('user', opt.value)}
                      placeholder="Sélectionner un utilisteur..."
                      options={[noUser, ...octapharmaUsers]}
                    />
                  </>
                : <>
                  {selectedUser?.label
                    ? <p className={styles.bold}>
                        {selectedUser?.label}
                    </p>
                    : <p>non complété</p>
                  }
                </>
              }
            </div>
          </section>
          {contact?.questions?.length > 0
            && <section>
              <h3>Questions</h3>
              <div className={styles.list}>
              {contact?.questions.map((q) => <a
                key={q._id}
                className={styles.question}
                href={questionUrl(q._id)}
                target="_blank" rel="noreferrer">
                  {q.code} - {q.title} <HiLink />
              </a>)}
              </div>
            </section>
          }
        </div>

      </main>
    </form>
  );
}
