import React from 'react';
import { useForm } from 'react-hook-form';

import {
  StyledSelect,
} from '../../../../../lib/HooksFormFields';
import styles from './AddPathology.module.scss';

export default function AddPathology({
  pathologies,
  pathologiesOptions,
  submit,
}) {
  const {
    control,
    setValue,
    watch,
    getValues,
  } = useForm();

  const options = pathologiesOptions.map((p) => ({ value: { pathology: p._id, form: p.form.map((f) => ({ ...f, value: '' })) }, label: p.name }));

  function handleSubmitTask() {
    let list = [...pathologies];
    list = [...list, watch('pathology')];
    submit({ pathologies: list });
  }

  console.log(getValues());
  return (
    <div className={styles.container}>
      <h2>Ajouter une pathologie</h2>
      <div className={styles.row}>
        <div className={styles['input-list']}>
          <label>Pathologie</label>
          <div className={styles.input}>
            <StyledSelect
              name="pathology"
              control={control}
              value={options.find((o) => o.value === watch('pathology'))}
              onChange={(opt) => setValue('pathology', opt.value)}
              placeholder="Séléctionner une pathologie..."
              options={options}
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={() => handleSubmitTask()}
          >
            Ajouter
        </button>
      </div>
    </div>
  );
}
