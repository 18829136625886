import React, {
  useEffect, useCallback, useState, useRef,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';

import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';
// import ToolsFilters from '../../../components/Tools/ToolsFilters/ToolsFilters';
// import { GET_TOOL } from '../../../actions/types';

import styles from './DrugsList.module.scss';
import { getDrugsAction } from '../../../actions/drugsActions';
import { SET_DRUG_SCROLL } from '../../../actions/types';
import DrugRow from '../../../components/Drugs/DrugRow/DrugRow';
import DrugsFilters from '../../../components/Drugs/DrugsFilters/DrugsFilters';

export default function DrugsList() {
  const { drugsList, filters, scrollY } = useSelector((state) => state.drugs);
  const { companiesList } = useSelector((state) => state.companies);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    name: true,
    company: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getDrugsAction(dispatch);
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'company':
        list = list.sort((a, b) => {
          const Acompany = companiesList.find((c) => c._id === a?.company)?.name || '';
          const Bcompany = companiesList.find((c) => c._id === b?.company)?.name || '';
          if (Acompany < Bcompany) return -1;
          if (Acompany > Bcompany) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      default:
        break;
    }
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function handleScroll(e) {
    dispatch({
      type: SET_DRUG_SCROLL,
      payload: e.target.scrollTop,
    });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    return val;
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useLayoutEffect(() => {
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [scrollY, filteredList]);

  useEffect(() => {
    setIsLoading(false);
    let list = drugsList;
    if (filters.search) {
      list = list.filter((t) => {
        if (t.name.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        if (t.description.toLowerCase().includes(filters.search.toLowerCase())) {
          return t;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [drugsList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>Produits</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/product/create?form=0')}>
              NOUVEAU PRODUIT
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <SortButton
              name={'Laboratoire'}
              handleClick={() => sortList('company', !sortByReverse.company)}
              state={sortByReverse.company}
              className={`${styles.col}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          onScroll={(e) => handleScroll(e)}
          className={styles.list}
          >
          <div>
          {!isLoading && filteredList.length > 0 && filteredList?.map((d, i) => <DrugRow
            key={d._id}
            drug={d}
            index={i}
            styles={styles}
          />)}
          </div>
        </div>
      </div>
      <DrugsFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
