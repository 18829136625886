import React from 'react';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function CompanyRow({
  styles,
  company,
  types,
  index,
}) {
  return (
    <Link to={`/company/${company._id}`} className={styles.row} style={{ animationDelay: `${0.08 * index}s` }}>
      <div className={styles.col}>
         <p className={styles.bold}>{company?.name}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{types?.find((o) => o.value === company?.type)?.label}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
