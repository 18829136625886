import {
  getData, putData, postData, deleteData,
} from './index';

import {
  ERROR_USERS,
  GET_FILTERED_USERS,
  GET_USER,
  PUT_USER,
  POST_USER,
  DELETE_USER,
  GET_USERS_PRODUCTS,
  GET_OCTA_USER,
  GET_DIVISIONS,
  GET_SPECIALITIES,
  GET_PROVIDERS_TYPES,
  SET_TOAST,
  GET_ALL_USER,
} from './types';

export async function getUsersProductsAction(dispatch) {
  const url = '/tool';
  let products;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    products = response.tools.filter((t) => t.type === 'product' || t.type === 'marketing')
      .sort((a) => (a.type === 'marketing' ? 1 : -1));
  });

  dispatch({
    type: GET_USERS_PRODUCTS,
    payload: products,
  });
}

export async function inviteUserAction(dispatch, id) {
  const url = `/user/invite/${id}`;
  let invite;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    invite = response;
  });

  if (invite.message) {
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "l'invitation a été envoyée",
        type: 'success',
      },
    });
  }
}

export async function getDivisionsAction(dispatch) {
  const url = '/user/division';
  let divisions;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    divisions = response.divisions;
  });

  dispatch({
    type: GET_DIVISIONS,
    payload: divisions,
  });
}

export async function getSpecialitiesAction(dispatch) {
  const url = '/user/speciality';
  let specialities;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    specialities = response.specialities;
  });

  dispatch({
    type: GET_SPECIALITIES,
    payload: specialities,
  });
}

export async function getProvidersTypesAction(dispatch) {
  const url = '/user/provider';
  let providerType;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    providerType = response.providerType;
  });

  dispatch({
    type: GET_PROVIDERS_TYPES,
    payload: providerType,
  });
}

export async function getUsersFiltredAction(dispatch, data) {
  const url = '/user/filtered';
  let users;

  await postData(GET_FILTERED_USERS, ERROR_USERS, url, dispatch, data, true).then((response) => {
    users = response;
  });

  dispatch({
    type: GET_FILTERED_USERS,
    payload: users,
  });
}

export async function getUserAction(dispatch, id) {
  const url = `/user/${id}`;
  let user;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    user = response;
  });

  dispatch({
    type: GET_USER,
    payload: user,
  });
}

export async function postUserAction(dispatch, data) {
  const url = '/user';
  let user;

  await postData(POST_USER, ERROR_USERS, url, dispatch, data, true).then((response) => {
    user = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'utilisateur a été creé",
    },
  });

  dispatch({
    type: POST_USER,
    payload: user,
  });
}

export async function putUserAction(dispatch, id, data) {
  const url = `/user/${id}`;
  let user;

  await putData(PUT_USER, ERROR_USERS, url, dispatch, data, true).then((response) => {
    user = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'utilisateur a été modifié",
    },
  });

  dispatch({
    type: PUT_USER,
    payload: user,
  });
}

export async function getOctapharamaUsers(dispatch) {
  const url = '/user';
  let user;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    user = response.users.filter((u) => u.type === 'Octapharma').map((u) => ({
      division: u.division,
      value: u._id,
      label: `${u?.profile?.firstName} ${u?.profile?.lastName}`,
    }));
  });

  dispatch({
    type: GET_OCTA_USER,
    payload: user,
  });
}

export async function getAllUsers(dispatch) {
  const url = '/user';
  let user;

  await getData(ERROR_USERS, url, dispatch, true).then((response) => {
    user = response.users.filter((u) => u?.profile?.firstName).map((u) => ({ value: u._id, label: `${u?.profile?.firstName} ${u?.profile?.lastName}` }));
  });

  dispatch({
    type: GET_ALL_USER,
    payload: user,
  });
}

export async function deleteUserAction(dispatch, id) {
  const url = `/user/${id}`;

  await deleteData(ERROR_USERS, url, dispatch, true).then(() => {
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
  });
}
