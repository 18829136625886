import React from 'react';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function CenterRow({
  styles,
  center,
  // index,
}) {
  return (
    <Link to={`/center/${center._id}?form=0`} className={styles.row}
      // style={{ animationDelay: `${0.08 * index}s` }}
    >
      <div className={styles.col}>
        <p className={styles.bold}>{center?.name}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{center?.location?.city}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        <p>{center?.services?.length > 0 && center?.services?.length}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
