import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { API_URL } from '../../../../actions';
import styles from './Com.module.scss';

export default function Com({ project }) {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const satisfactionOptions = [
    { value: 'poor', label: 'Médiocre' },
    { value: 'very good', label: 'Très bon' },
    { value: 'good', label: 'Bon' },
  ];

  // useEffect(() => {
  //   setIsOpen(true);
  // }, []);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h2>COMMUNICATION PROJET</h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && contentRef?.current?.clientHeight > 0 ? contentRef.current.clientHeight + 20 : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          <div className={styles.row}>
            {project?.comunicationDates
              && <div className={`${styles.col} ${styles.list}`}>
                <label>Dates de communication</label>
                {project?.comunicationDates?.map((d) => <div
                key={d._id}
                className={styles.item}
                >
                  <p className={styles.bold}>{d?.title}</p>
                  <p>{d?.date && format(new Date(d.date), 'dd/MM/yy')}</p>
                </div>)}
            </div>
            }
            {project?.satisfaction
              && <div className={`${styles.col} ${styles.satisfaction}`}>
                <label>Satisfaction </label>
                <p>
                  {satisfactionOptions?.find((s) => s.value === project?.satisfaction)?.label}
                </p>
              </div>
            }
          </div>
          {project?.files?.length > 0
            && <div className={styles.list}>
              <label>Support</label>
              {project.files.map((f) => <a key={f._id}
                href={`${API_URL}/${f.path}`} target='_blank' rel="noreferrer">
                {f.name}
              </a>)}
            </div>
          }

          {project?.report
            && <div className={styles.border}>
              <h2>Clôture</h2>
              <div className={styles.text}>
                <label>Rapport</label>
                <p>
                  {project?.report}
                </p>
            </div>
          </div>
          }
        </div>
      </div>
    </section>
  );
}
