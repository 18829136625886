import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';
import { projectsTypesOptions } from '../../../constants/constants';

export default function ProjectRow({
  styles,
  project,
}) {
  return (
    <Link to={`/project/${project._id}`}className={styles.row}>
      <div className={`${styles.col}`}>
         <p className={`${styles.name}`}>{project?.name}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{projectsTypesOptions?.find((o) => o.value === project?.type)?.label}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        {project?.duration && <p>{project?.duration} mois</p>}
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        <p>{project?.startDate && format(new Date(project?.startDate), 'dd/MM/yyyy')}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link>
  );
}
