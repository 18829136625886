import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
import { getUsersFiltredAction, getUsersProductsAction } from '../../../actions/usersActions';
import { getToolsDivisionsAction, getToolsTypesAction, getToolsAction } from '../../../actions/toolsActions';
import { SET_USERS_FILTERS, SET_USER_SCROLL } from '../../../actions/types';

import UsersRow from '../../../components/Users/UsersRow/UsersRow';
import SortButton from '../../../lib/SortButton';

import { Button } from '../../../lib/HooksFormFields';
import styles from './UsersList.module.scss';
import UsersFilters from '../../../components/Users/UsersFilters/UsersFilters';

export default function UsersList() {
  const {
    usersList, filters, count, scrollY,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    'profile.lastName': true,
    role: true,
    type: true,
    product: true,
  };

  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getUsersFiltredAction(dispatch, filters);
  }, [dispatch, filters]);

  const getProducts = useCallback(() => {
    getToolsAction(dispatch);
    getUsersProductsAction(dispatch);
    getToolsDivisionsAction(dispatch);
    getToolsTypesAction(dispatch);
  }, [dispatch]);

  function sortList(type, isReverse) {
    setSortByReverse({ ...initSortState, [type]: isReverse });
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        page: 1,
        orderBy: {
          key: type,
          order: isReverse ? 'desc' : 'asc',
        },
      },
    });
  }

  function handleScroll(e) {
    dispatch({
      type: SET_USER_SCROLL,
      payload: e.target.scrollTop,
    });
    if (!isLoading
        && count > usersList?.length
        // && e.target.scrollTop > scrollY
        && scrollRef?.current.getBoundingClientRect().bottom
        <= containerRef?.current.getBoundingClientRect().bottom
    ) {
      dispatch({
        type: SET_USERS_FILTERS,
        payload: {
          ...filters,
          page: filters.page + 1,
        },
      });
    }
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    if (filters.tools.length) {
      val += filters?.tools.length;
    }
    if (filters.types.length) {
      val += filters?.types.length;
    }
    if (filters.roles.length) {
      val += filters?.roles.length;
    }
    return val;
  }

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [filters.orderBy, filters, filters.page]);

  useEffect(() => {
    if (filters.page === 1) {
      containerRef.current.scrollTop = 0;
    }
  }, [filters.page]);

  useEffect(() => {
    setIsLoading(false);
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [usersList, scrollY]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>{count} Utilisateurs</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/user/create')}>
              NOUVEL UTILISATEUR
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('profile.lastName', !sortByReverse['profile.lastName'])}
              state={sortByReverse['profile.lastName']}
              className={`${styles.col}`}/>
            <SortButton
              name={'Rôle'}
              handleClick={() => sortList('role', !sortByReverse.role)}
              state={sortByReverse.role}
              className={`${styles.col} ${styles.desktop} ${styles.type}`}/>
            <SortButton
              name={'Typologie'}
              handleClick={() => sortList('type', !sortByReverse.type)}
              state={sortByReverse.type}
              className={`${styles.col} ${styles.type}`}/>
            <SortButton
              name={'Produits numériques'}
              handleClick={() => sortList('product', !sortByReverse.product)}
              state={sortByReverse.product}
              className={`${styles.col} ${styles.desktop} ${styles.products}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          onScroll={(e) => handleScroll(e)}
          className={styles.list}
          >
          <div ref={scrollRef}>
          {usersList?.map((u) => <UsersRow
            key={u._id}
            user={u}
            styles={styles}
          />)}
          </div>
        </div>
      </div>
      <UsersFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
