import React from 'react';
import { useForm } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import {
  Button, ErrorField, InputText, Textarea,
} from '../../../../../lib/HooksFormFields';
import InputFile from '../../../../../components/InputFile/InputFile';
import styles from './SymposiumModal.module.scss';
import { API_URL } from '../../../../../actions';
import { generateKey } from '../../../../../utils/utils';
import { deleteFileAction } from '../../../../../actions/filesActions';

export default function SymposiumModal({
  symposium, symposiums, changeValue, close, submit,
}) {
  const {
    control,
    setValue,
    formState: {
      errors,
    },
    watch,
  } = useForm({ defaultValues: symposium });
  const dispatch = useDispatch();
  const form = watch();

  function handleChange() {
    const updatedSymposiums = [...symposiums];
    if (symposium?.index >= 0) {
      updatedSymposiums[symposium?.index] = form;
    } else {
      updatedSymposiums.push(form);
    }
    changeValue(updatedSymposiums);
    close();
  }

  function deleteSymposium() {
    if (form?.files.length) {
      form.files.forEach(async (f) => {
        await deleteFileAction(dispatch, f?._id || f);
      });
    }
    changeValue(symposiums.filter((h, i) => i !== symposium?.index));
    close();
  }

  function handleChangeFiles(file) {
    const updatedSymposiums = [...symposiums];
    let files = form.files?.length ? [...form.files] : [];
    console.log(files, file);
    if (files.find((f) => f._id === file)) {
      files = files.filter((f) => f._id !== file);
    } else {
      files.push(file);
    }
    setValue('files', files);
    const data = { ...form, files };
    if (symposium?.index >= 0) {
      updatedSymposiums[symposium?.index] = data;
    } else {
      updatedSymposiums.push(data);
    }
    submit(updatedSymposiums);
  }

  return (
    <div className={styles.container}>
      <h3>{symposium?.name || 'Ajouter un symposium'}</h3>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Nom</label>
          <div className={styles.input}>
            <InputText
              name='name'
              control={control}
              className={'primary'}
              rules={{ required: 'Le nom est requis' }}
              placeholder={'Nom'}
            />
            {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Adresse</label>
          <div className={styles.input}>
            <InputText
              name='location'
              control={control}
              className={'primary'}
              rules={{ }}
              placeholder={'Adresse'}
            />
            {errors?.location?.message && <ErrorField message={errors?.location?.message} />}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Description</label>
          <div className={styles.input}>
            <Textarea
              name='description'
              control={control}
              className={'primary'}
              rules={{ }}
              placeholder={'description'}
            />
            {errors?.description?.message && <ErrorField message={errors?.description?.message} />}
          </div>
        </div>
      </div>
      <div className={styles.files}>
        <div className={styles['file-section']}>
          {watch('files') && watch('files').map((f, i) => (<div
            key={generateKey('files', i)}
            className={styles.file}>
            <a href={`${API_URL}/${f?.path}`} target='_blank' rel="noreferrer">
              {f?.name || 'fichier'}
            </a>
            <button
              type="button"
              onClick={() => handleChangeFiles(f._id)}
              >
              <AiFillDelete size={20} />
            </button>
          </div>))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.file}>
          <InputFile
            activeFile={null}
            handleChange={(fileId) => handleChangeFiles(fileId)}
            label="Ajouter un ficher"
            name={'symposium.files'}
            />
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          className={styles.edit}
          type='button'
          handleClick={() => handleChange()}
        >
          Valider
        </Button>
        {symposium?.index >= 0
        && <button
          type="button"
          className={styles.delete}
          onClick={() => deleteSymposium(symposiums.filter((h, i) => i !== symposium?.index))}
        >
          Supprimer
        </button>
        }
      </div>
    </div>
  );
}
