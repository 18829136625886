import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronDownOutline } from 'react-icons/io5';
import { AiFillDelete } from 'react-icons/ai';
import {
  InputDate, InputFormatNumber, InputText, StyledSelect,
} from '../../../../../lib/HooksFormFields';
// import { defaultSubtype } from '../../../constants/constants';
import styles from './PathologieForm.module.scss';

const FormField = ({ field, index, control }) => {
  if (field.type === 'list' || field.type === 'listMultiple') {
    return (
      <div className={styles.row}>
        <div className={`${styles.block}`}>
          <label>{field.label}</label>
          <div className={styles.value}>
            <StyledSelect
              control={control}
              name={`form.${index}.value`}
              isMulti={field.type === 'listMultiple'}
              options={field.options || []}
            />
          </div>
        </div>
      </div>
    );
  }
  if (field.type === 'number') {
    return (
      <div className={styles.row}>
        <div className={`${styles.block}`}>
          <InputFormatNumber
            label={field.label}
            name={`form.${index}.value`}
            control={control}
            className={'primary'}
          />
        </div>
      </div>
    );
  }
  if (field.type === 'date') {
    return (
      <div className={styles.row}>
        <div className={`${styles.block}`}>
          <InputDate
            label={field.label}
            name={`form.${index}.value`}
            control={control}
            className={'primary'}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.row}>
      <div className={`${styles.block}`}>
        <InputText
          label={field.label}
          name={`form.${index}.value`}
          control={control}
          className={'primary'}
        />
      </div>
    </div>
  );
};

export default function PathologieForm({
  pathology, pathologies, handleChangePathologies, pathologyName,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const defaultForm = pathology.form.map((f) => {
    const field = { ...f };
    if (f.type === 'date') {
      if (f.value) {
        field.value = new Date(f.value);
      } else {
        field.value = new Date();
      }
    }
    if (f.type === 'list') {
      if (f.value) {
        field.value = field.options.find((opt) => opt.value === field.value);
      }
    }
    return field;
  });
  const {
    control,
    watch,
  } = useForm({
    defaultValues: {
      form: defaultForm,
    },
  });

  const watchForm = watch();

  function handleChangeType(data) {
    const list = pathologies || [];
    const form = data.map((f) => {
      const field = { ...f };
      if (field.type === 'list') {
        field.value = f?.value?.value ? f?.value?.value : f.value;
      }
      return field;
    });
    const index = pathologies?.findIndex((t) => t.pathology === pathology.pathology);
    if (index !== -1) {
      list[index] = { ...list[index], form };
    }
    handleChangePathologies(list);
  }

  useEffect(() => {
    if (JSON.stringify(pathology.form) !== JSON.stringify(watchForm.form)) {
      handleChangeType(watchForm.form);
    }
  }, [
    ...watchForm.form.map((f) => f.value),
  ]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {pathologyName}
        </h2>
        <div className={styles.actions}>
          <button
            type="button"
            onClick={() => handleChangePathologies(
              pathologies.filter((d) => d.pathology !== pathology.pathology),
            )}
            className={styles.delete}>
            <AiFillDelete size={20}/>
          </button>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
            <IoChevronDownOutline size={20}/>
          </button>
        </div>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>
          <div className={styles['input-list']}>
            {pathology?.form?.map((f, i) => <FormField
              key={f.label}
              control={control}
              field={f}
              index={i}
            />)}
          </div>
        </div>
      </div>
    </div>
  );
}
