import React, { useEffect, useState, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { getToolsAction } from '../../actions/toolsActions';
import { getDashboardAction, postSearchAction } from '../../actions/authActions';
import ProductIcon from '../../components/ProductIcon/ProductIcon';
import logo from '../../assets/images/logo-white.svg';
import dashImg from '../../assets/images/dashboard.png';
import styles from './Dashboard.module.scss';
import SearchList from '../../components/SearchList/SearchList';

export default function Dashboard() {
  const dispatch = useDispatch();
  const { dashboard, searchList } = useSelector((state) => state.auth);
  const [search, setSearch] = useState('');

  const getData = useCallback(() => {
    getDashboardAction(dispatch);
    getToolsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  function handleSearch(value) {
    setSearch(value);
    return postSearchAction(dispatch, { search: value });
  }

  return (
    <div className={styles.container}>
        <div className={styles['left-corner']}>
        </div>
      <header>
        <div className={styles.logo}>
          <img src={logo} alt='logo-octadmin'/>
          <h2>OCTADMIN</h2>
        </div>
        <div className={styles.input}>
          <input
            value={search}
            placeholder='Votre recherche'
            onChange={(e) => handleSearch(e.target.value)}
            />
          <div className={styles.icon}>
            <FiSearch size={26}/>
          </div>
        </div>
      </header>
      <main>
        {search === ''
          ? <div className={styles.dashboard}>
          <div className={styles.box}>
            <div className={styles.col}>
              <h2>Dernières entrées</h2>
              <ul>
                {dashboard?.list.map((i) => (
                  <li key={i._id}>
                    <ProductIcon name={i.type} isDashboard={true} size={36} />
                    <p>{i.label}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.img}>
              <img src={dashImg} alt={'illu'} />
            </div>
          </div>
          <div className={styles['count-list']}>
          <div className={styles.count}>
              <h3>{dashboard?.doctorCount}</h3>
              <p>Nombre de médecins inscrits</p>
            </div>
            <div className={styles.count}>
              <h3>{dashboard?.pharmacistCount}</h3>
              <p>Nombre de pharmaciens inscrits</p>
            </div>
            <div className={styles.count}>
              <h3>{dashboard?.userCount}</h3>
              <p>Nombre d’utilisateurs Octapharma</p>
            </div>

          </div>
          </div>
          : <div className={styles.search}>
            <SearchList searchList={searchList}/>
        </div>
      }
      </main>
    </div>
  );
}
