import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import { IoChevronBack, IoSave } from 'react-icons/io5';
import { useForm } from 'react-hook-form';

import {
  getProjectAction,
  getProjectListsAction,
  postProjectAction,
  putProjectAction,
} from '../../../actions/projectsActions';

import Button from '../../../lib/HooksFormFields/Button';
import Infos from '../../../components/Projects/ProjectForm/Infos/Infos';
import Actors from '../../../components/Projects/ProjectForm/Actors/Actors';
import Com from '../../../components/Projects/ProjectForm/Com/Com';
import Meetings from '../../../components/Projects/ProjectForm/Meetings/Meetings';
import Tasks from '../../../components/Projects/ProjectForm/Tasks/Tasks';

import styles from './EditProject.module.scss';
import { getCompaniesAction } from '../../../actions/companyActions';
import { getAllUsers, getOctapharamaUsers } from '../../../actions/usersActions';
import { OCTAPHARMA_COMPANY_ID } from '../../../actions';

export default function EditProject() {
  const id = useParams()?.id;
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const { project } = useSelector((state) => state.projects);
  const { companiesList } = useSelector((state) => state.companies);

  const navRef = useRef();
  const nav = [
    {
      value: 0,
      label: 'Informations',
    },
    {
      value: 1,
      label: 'Intervenants',
    },
    {
      value: 2,
      label: 'Planning',
    },
    {
      value: 3,
      label: 'Tâches',
    },
    {
      value: 4,
      label: 'Communication',
    },
  ];
  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).form);
  const [companiesOptions, setCompaniesOptions] = useState();

  function handleChangeForm(val) {
    setActiveForm(val);
    window.history.replaceState(null, null, `?form=${val}`);
  }

  const getProject = useCallback(() => {
    getProjectAction(dispatch, id);
  }, [dispatch, id]);

  const getData = useCallback(() => {
    getCompaniesAction(dispatch);
    getOctapharamaUsers(dispatch);
    getAllUsers(dispatch);
    getProjectListsAction(dispatch);
  }, [dispatch]);

  function submitProject(values) {
    if (errors?.startDate || errors?.endDate || errors?.name) {
      return handleChangeForm(0);
    }
    if (project?._id) {
      return putProjectAction(dispatch, project._id, values);
    }
    return postProjectAction(dispatch, values);
  }

  useEffect(() => {
    if (id) {
      getProject();
    }
    getData();
  }, []);

  useEffect(() => {
    navRef.current.scrollTo({
      left: (120 * activeForm),
      top: 0,
      behavior: 'smooth',
    });
  }, [activeForm]);

  useEffect(() => {
    if (companiesList.length) {
      const options = [];
      companiesList.forEach((d) => {
        options.push({
          label: d.name,
          value: d._id,
        });
      });
      setCompaniesOptions(options);
    }
  }, [companiesList]);

  useEffect(() => {
    if (project) {
      const propreties = [
        'name',
        'company',
        'type',
        'duration',
        'isInstitutional',
        'situation',
        'objective',
        'contracts',
        'financeFile',
        'costs',
        'centers',
        'drugs',
        'coordinator',
        'manager',
        'internManager',
        'steeringCommittee',
        'scientificCouncil',
        'providers',
        'report',
        'satisfaction',
        'files',
        'tasks',
        'meetings',
      ];
      const dates = [
        'endDate',
        'startDate',
        'comunicationDate',
      ];
      propreties.forEach((p) => {
        if (project[p] || project[p] === false) {
          setValue(p, project[p]);
        }
      });
      dates.forEach((p) => {
        if (project[p]) {
          setValue(p, new Date(project[p]));
        }
      });
    }
  }, [project]);

  useEffect(() => {
    if (errors?.startDate || errors?.endDate || errors?.name) {
      handleChangeForm(0);
    }
  }, [errors, activeForm]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{project?.name
            ? <span>PROJETS / {project.name}</span>
            : <span>Nouveau Projet</span>
            }
          </h2>
          {project
            ? <Link to={`/project/${id}`} className={styles.link}>
              <IoChevronBack size={20}/> retour à la fiche
            </Link>
            : <Link to={'/project'} className={styles.link}>
              <IoChevronBack size={20}/> retour à la liste
            </Link>
          }
        </div>
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(submitProject)}
            className={styles.edit}>
            <span>Enregistrer</span>
            <IoSave size={28} />
          </Button>
        </div>
      </header>
      <nav className={styles.nav}>
        <ul ref={navRef}>
          {nav.map((l) => <li
            key={l.label}
            className={`${Number(activeForm) === l.value ? styles['active-form'] : ''}`}
            onClick={() => handleChangeForm(l.value) }>
            {l.label}
          </li>)}
        </ul>
      </nav>
      <main className={`${styles.content}`}>
        <div className={styles['form-container']} style={{ transform: `translateX(-${(Number(activeForm) * 100) / 5}%)` }}>
          <Infos
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            companiesOptions={companiesOptions}
            submit={submitProject}
            control={control}
            errors={errors}
            />
          <Actors
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            isOctapharma={OCTAPHARMA_COMPANY_ID === watch('company')}
            submit={submitProject}
            control={control}
            />
          <Meetings
            watch={watch}
            activeForm={activeForm}
            control={control}
            setValue={setValue}
            submit={submitProject}
            />
          <Tasks
            watch={watch}
            activeForm={activeForm}
            control={control}
            setValue={setValue}
            submit={submitProject}
            />
          <Com
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitProject}
            control={control}
            errors={errors}
            />
        </div>
      </main>
    </form>
  );
}
