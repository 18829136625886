import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalRouter from './pages/GlobalRouter';

function App() {
  return (
    <div className="app-container">
      <Router>
        <GlobalRouter />
      </Router>
    </div>
  );
}

export default App;
