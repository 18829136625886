import {
  getData, putData, postData,
} from './index';

import {
  ERROR_PATHOLOGY,
  GET_PATHOLOGIES,
  GET_PATHOLOGY,
  PUT_PATHOLOGY,
  POST_PATHOLOGY,
  SET_TOAST,
} from './types';

export async function getPathologiesAction(dispatch) {
  const url = '/pathology';
  let pathologies;

  await getData(ERROR_PATHOLOGY, url, dispatch, true).then((response) => {
    pathologies = response.pathologies;
  });

  dispatch({
    type: GET_PATHOLOGIES,
    payload: pathologies,
  });
}

export async function getPathologyAction(dispatch, id) {
  const url = `/pathology/${id}`;
  let pathology;

  await getData(ERROR_PATHOLOGY, url, dispatch, true).then((response) => {
    pathology = response.pathology;
  });

  dispatch({
    type: GET_PATHOLOGY,
    payload: pathology,
  });
}

export async function postPathologyAction(dispatch, data) {
  const url = '/pathology';
  let pathology;

  await postData(POST_PATHOLOGY, ERROR_PATHOLOGY, url, dispatch, data, true).then((response) => {
    pathology = response.pathology;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la pathologie a été creé',
    },
  });

  dispatch({
    type: POST_PATHOLOGY,
    payload: pathology,
  });
}

export async function putPathologyAction(dispatch, id, data) {
  const url = `/pathology/${id}`;
  let pathology;

  await putData(PUT_PATHOLOGY, ERROR_PATHOLOGY, url, dispatch, data, true).then((response) => {
    pathology = response.pathology;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la pathologie a été modifié',
    },
  });

  dispatch({
    type: PUT_PATHOLOGY,
    payload: pathology,
  });
}
