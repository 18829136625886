import {
  ERROR_PROJECT,
  GET_PROJECTS_LISTS,
  GET_PROJECTS,
  GET_PROJECT,
  PUT_PROJECT,
  POST_PROJECT,
  SET_PROJECTS_FILTERS,
  SET_PROJECT_SCROLL,
} from '../actions/types';

const initialState = {
  error: null,
  projectsList: [],
  project: null,
  lists: null,
  scrollY: 0,
  filters: {
    search: null,
    types: [],
  },
};

export default function projectsReducer(state = initialState, action) {
  const projectsList = state?.projectsList;
  const index = state?.projectsList?.findIndex(
    (project) => project?._id === action?.payload?._id,
  );

  switch (action.type) {
    case POST_PROJECT:
      return {
        ...state,
        projectsList: [...projectsList, action.payload],
        project: action.payload,
      };
    case SET_PROJECT_SCROLL:
      return {
        ...state,
        scrollY: action.payload,
      };

    case PUT_PROJECT:
      projectsList[index] = action.payload;
      return {
        ...state,
        projectsList,
        project: action.payload,
      };

    case SET_PROJECTS_FILTERS:
      return { ...state, filters: action.payload };

    case GET_PROJECTS_LISTS:
      return { ...state, lists: action.payload };

    case GET_PROJECT:
      return { ...state, project: action.payload };

    case GET_PROJECTS:
      return { ...state, projectsList: action.payload };

      // case DELETE_TOOL:
      //   projectsList = state.projectsList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, projectsList, message: 'user removed' };

    case ERROR_PROJECT:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
