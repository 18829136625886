import React, {
  useRef, useEffect,
} from 'react';
import MeetingForm from '../../../MeetingForm/MeetingForm';

import styles from './Meetings.module.scss';

export default function Meetings({
  control,
  watch,
  submit,
  activeForm,
}) {
  const containerRef = useRef();

  const meetings = watch('meetings') || [];

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.col}>
        <div className={styles.header}>
          <h3>Rétroplanning global</h3>
          <button type="button" onClick={() => submit({ meetings: [...meetings, { date: new Date(), participants: [] }] })}>
            Ajouter une réunion
          </button>
        </div>
        {meetings.map((m, i) => <MeetingForm
          key={m._id}
          meeting={m}
          meetingsList={meetings}
          control={control}
          i={i}
          handleChange={(arr) => submit({ meetings: arr })}
        />)}
      </div>
    </div>
  );
}
