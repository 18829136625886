import React, {
  useEffect, useCallback, useState, useRef, useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { getProjectsAction } from '../../../actions/projectsActions';
import { GET_PROJECT, SET_PROJECT_SCROLL } from '../../../actions/types';

import { Button } from '../../../lib/HooksFormFields';
import SortButton from '../../../lib/SortButton';

import ProjectsFilters from '../../../components/Projects/ProjectsFilters/ProjectsFilters';
import ProjectRow from '../../../components/Projects/ProjectRow/ProjectRow';

import styles from './ProjectsList.module.scss';

export default function ProjectsList() {
  const { projectsList, filters, scrollY } = useSelector((state) => state.projects);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    name: true,
    duration: true,
    type: true,
    startDate: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getProjectsAction(dispatch);
    dispatch({
      type: GET_PROJECT,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        break;
      case 'duration':
        list = list.sort((a, b) => {
          if (a?.duration < b?.duration) return -1;
          if (a?.duration > b?.duration) return 1;
          return 0;
        });
        break;
      case 'type':
        list = list.sort((a, b) => {
          if (a?.type < b?.type) return -1;
          if (a?.type > b?.type) return 1;
          return 0;
        });
        break;
      case 'startDate':
        list = list.sort((a, b) => {
          const aDate = a?.startDate ? new Date(a?.startDate) : new Date();
          const bDate = b?.startDate ? new Date(b?.startDate) : new Date();
          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    setFilteredList(isReverse ? list.reverse() : list);
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    if (filters?.cities?.length) {
      val += filters?.cities?.length;
    }
    return val;
  }

  function handleScroll(e) {
    dispatch({
      type: SET_PROJECT_SCROLL,
      payload: e.target.scrollTop,
    });
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useLayoutEffect(() => {
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [scrollY, filteredList]);

  useEffect(() => {
    setIsLoading(false);
    let list = projectsList;
    if (filters.search) {
      list = list.filter((p) => {
        if (p.name.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        if (p?.situation?.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        if (p?.objective?.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        return null;
      });
    }
    if (filters.types.length) {
      list = list.filter((p) => {
        if (filters.types.find((f) => f?.toLowerCase() === p?.type?.toLowerCase())) {
          return p;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [projectsList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>Projets</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/project/create?form=0')}>
              NOUVEAU PROJET
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <SortButton
              name={'Type'}
              handleClick={() => sortList('type', !sortByReverse.type)}
              state={sortByReverse.type}
              className={`${styles.col}`}/>
            <SortButton
              name={'Durée du projet'}
              handleClick={() => sortList('duration', !sortByReverse.duration)}
              state={sortByReverse.duration}
              className={`${styles.col} ${styles.desktop}`}/>
            <SortButton
              name={'Date début'}
              handleClick={() => sortList('startDate', !sortByReverse.startDate)}
              state={sortByReverse.startDate}
              className={`${styles.col} ${styles.desktop}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          onScroll={(e) => handleScroll(e)}
          className={styles.list}
          >
          <div>
          {!isLoading && filteredList?.length > 0 && filteredList?.map((p, i) => <ProjectRow
            key={p._id}
            project={p}
            index={i}
            styles={styles}
          />)}
          </div>
        </div>
      </div>
      <ProjectsFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
