import React from 'react';
import { useSelector } from 'react-redux';

import { API_URL } from '../../actions';

import styles from './ProductIcon.module.scss';

export default function ProductIcon({
  name, size, radius = 6, isDashboard = false,
}) {
  const { toolsList } = useSelector((state) => state.tools);
  const product = toolsList?.find((p) => p.title.toLowerCase() === name.toLowerCase());
  return (
    <>
      {product
        && <div className={`${styles.icon} ${isDashboard ? styles.dashboard : ''}`} style={{
          backgroundColor: product.color || '#CFD0DB',
          width: size,
          maxWidth: size,
          minWidth: size,
          height: size,
          borderRadius: radius,
        }}>
          {product?.icon?.path
            ? <>
            <img src={`${API_URL}/${product.icon.path}`} alt={product.icon.alt}/>
          </>
            : <>
            <span>{name[0]}</span>
          </>
          }
        </div>
      }
    </>
  );
}
