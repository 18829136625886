import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { Button, ErrorField, InputText } from '../../../../../lib/HooksFormFields';
import InputFile from '../../../../../components/InputFile/InputFile';
import styles from './HotelModal.module.scss';
import { API_URL } from '../../../../../actions';
import { deleteFileAction } from '../../../../../actions/filesActions';

export default function HotelModal({
  hotel, hotels, changeValue, close, submit,
}) {
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    reset,
    formState: {
      errors,
    },
    watch,
  } = useForm({ defaultValues: hotel });

  const form = watch();

  function handleChange() {
    const updatedHotels = [...hotels];
    if (hotel?.index >= 0) {
      updatedHotels[hotel?.index] = form;
    } else {
      updatedHotels.push(form);
    }
    changeValue(updatedHotels);
    close();
  }

  function deleteHotel() {
    changeValue(hotels.filter((h, i) => i !== hotel?.index));
    close();
  }

  function handleChangePlan(file) {
    const updatedHotels = [...hotels];
    const plan = watch('plan')?._id === file ? null : file;
    if (!plan) {
      deleteFileAction(dispatch, file);
      setValue('plan', null);
    }
    const data = { ...form, plan };
    if (hotel?.index >= 0) {
      updatedHotels[hotel?.index] = data;
    } else {
      updatedHotels.push(data);
    }
    submit(updatedHotels);
  }

  useEffect(() => {
    if (hotel?.index >= 0) {
      const updatedHotel = hotels[hotel?.index];
      reset(updatedHotel);
    }
  }, [hotels]);

  return (
    <div className={styles.container}>
      <h3>{hotel?.name || 'Ajouter un hotel'}</h3>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Nom</label>
          <div className={styles.input}>
            <InputText
              name='name'
              control={control}
              className={'primary'}
              rules={{ required: 'Le nom est requis' }}
              placeholder={'Nom'}
            />
            {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
          </div>
        </div>
        <div className={styles['input-block']}>
          <label>Adresse</label>
          <div className={styles.input}>
            <InputText
              name='address'
              control={control}
              className={'primary'}
              rules={{ required: 'Le nom est requis' }}
              placeholder={'Nom'}
            />
            {errors?.address?.message && <ErrorField message={errors?.address?.message} />}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Checkin</label>
          <div className={styles.input}>
            <InputText
              name='checkin'
              type="time"
              control={control}
              className={'primary'}
              rules={{}}
              placeholder={'Nom'}
            />
          </div>
        </div>
        <div className={styles['input-block']}>
          <label>Checkout</label>
          <div className={styles.input}>
            <InputText
              name='checkout'
              type="time"
              control={control}
              className={'primary'}
              rules={{}}
              placeholder={'Nom'}
            />
          </div>
        </div>
      </div>
      <div className={styles['file-section']}>
          {watch('plan') ? <div
            className={styles.file}>
            <a href={`${API_URL}/${watch('plan')?.path}`} target='_blank' rel="noreferrer">
              {watch('plan')?.name || 'fichier'}
            </a>
            <button
              type="button"
              onClick={() => handleChangePlan(watch('plan')._id)}
              >
              <AiFillDelete size={20} />
            </button>
          </div>
            : <div className={styles.file}>
          <InputFile
            activeFile={null}
            handleChange={(fileId) => handleChangePlan(fileId)}
            label="Ajouter un plan"
            name={'map'}
            />
        </div>}
      </div>
      <div className={styles.btns}>
        <Button
          className={styles.edit}
          type='button'
          handleClick={() => handleChange()}
        >
          Valider
        </Button>
        {hotel?.index >= 0
        && <button
          type="button"
          className={styles.delete}
          onClick={() => deleteHotel(hotels.filter((h, i) => i !== hotel?.index))}
        >
          Supprimer
        </button>
        }
      </div>
    </div>
  );
}
