import React, { useRef, useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { Textarea } from '../../../../lib/HooksFormFields';
// import SentenceList from '../../../SentenceList/SentenceList';
import FormList from '../../../FormList/FormList';
import SentenceList from '../../../SentenceList/SentenceList';
import TagsList from '../../../TagsList/TagsList';
import styles from './Composition.module.scss';

export default function Composition({
  control,
  // errors,
  setValue,
  watch,
  // submit,
  lists,
  activeForm,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <div className={`${styles.box}`}>
          <h3>COMPOSITION QUALITATIVE ET QUANTITATIVE</h3>
          <div className={styles.list}>
            {watch('composition')?.map((d, i) => <div
              key={d._id || i}
              className={styles.item}
              >
              <p className={styles.composition}>{d?.concentration}</p>
              <p className={styles.composition}>{d?.dosage}</p>
              <p className={styles.composition}>{d?.volume}</p>
              {/* <p className={styles.excipient}>{d?.excipient && d?.excipient?.map(
                (ex) => <span key={ex}>{ex} </span>,
              )}
              </p> */}
              <button
                type="button"
                onClick={() => setValue('composition', watch('composition').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('composition') || []}
            handleChange={(arr) => setValue('composition', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'Concentration',
                key: 'concentration',
                type: 'text',
              },
              {
                label: 'Dosage',
                key: 'dosage',
                type: 'text',
              },
              {
                label: 'Volume',
                key: 'volume',
                type: 'text',
              },
            ]}
            />
        </div>

        <div className={`${styles.box}`}>
          <h3>Excipients</h3>
          <SentenceList
            isEdit={true}
            list={watch('excipient') || []}
            handleChange={(arr) => setValue('excipient', arr)}
            label='Ajouter un excipient'
            btn='Ajouter'
          />
        </div>

        <div className={`${styles.box}`}>
          <h3>FORME PHARMACEUTIQUE</h3>
          <div className={styles['form-group']}>
            <TagsList
              isEdit={true}
              options={lists?.forms || []}
              isUnique={true}
              selected={watch('form') ? [watch('form')] : []}
              handleChange={(value) => setValue('form', value)}
              />
          </div>

          <div className={styles['form-group']}>
            <label>Description</label>
            <div className={styles.value}>
              <Textarea
                name='formDescription'
                control={control}
                className={'primary'}
                placeholder={'Description'}
              />
            </div>
          </div>
        </div>

      </main>
    </div>
  );
}
