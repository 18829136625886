import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Link, useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronBack, IoLocationSharp } from 'react-icons/io5';
import { GoPencil } from 'react-icons/go';

import { getCenterAction } from '../../../actions/centersActions';

import Button from '../../../lib/HooksFormFields/Button';

import styles from './CenterView.module.scss';
import { getDrugsAction } from '../../../actions/drugsActions';
import ServiceView from '../../../components/Centers/ServiceView/ServiceView';
import { getProjectsAction } from '../../../actions/projectsActions';
import { getPathologiesAction } from '../../../actions/pathologiesActions';

export default function CenterView() {
  const id = useParams()?.id;
  const { center } = useSelector((state) => state.centers);
  const { drugsList } = useSelector((state) => state.drugs);
  const { pathologiesList } = useSelector((state) => state.pathologies);
  const [products, setProducts] = useState();
  const [pathologies, setPathologies] = useState();
  const projectsOptions = useSelector((state) => state.projects)?.projectsList?.map(
    (p) => ({
      value: p,
      label: p.name,
      type: p.type,
      centers: p.centers,
    }),
  );
  const dispatch = useDispatch();

  const getCenter = useCallback(() => {
    getCenterAction(dispatch, id);
    getDrugsAction(dispatch);
    getProjectsAction(dispatch);
    getPathologiesAction(dispatch);
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getCenter();
    }
  }, []);

  useEffect(() => {
    if (drugsList && pathologiesList && center) {
      const drugs = [];
      const centerPathologies = [];
      center.services.forEach((s) => {
        s.drugs?.forEach((d) => {
          const found = drugsList.find((dr) => dr._id === d);
          if (found && !drugs.find((f) => f._id === d)) {
            drugs.push(found);
          }
        });
        s.pathologies?.forEach((p) => {
          const found = pathologiesList.find((pa) => pa._id === p.pathology);
          if (found && !centerPathologies.find((f) => f._id === p.pathology)) {
            centerPathologies.push(found);
          }
        });
      });
      setProducts(drugs);
      setPathologies(centerPathologies);
    }
  }, [center, drugsList, pathologiesList]);

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{center?.name
              && <span>{center.name}</span>
            }
          </h2>
          <Link to={'/center'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <Link to={`/center/edit/${id}?form=0`} className={styles.link}>
            <Button
              className={styles.edit}>
              <span>Editer la fiche </span>
              <GoPencil size={20}/>
            </Button>
          </Link>
        </div>
      </header>
      <main className={`${styles.content}`}>
        {center?._id
          && <>
            <div className={styles['col-center']}>
            <div className={styles.center}>
              <div className={styles.infos}>
                <h1>{center.name}</h1>
                <div className={styles.border}>
                  <h2>Adresse</h2>
                  <div className={styles.row}>
                    <div className={`${styles.col} ${styles.address}`}>
                      <IoLocationSharp size={20} />
                      <p>
                        {center?.location?.address}<br/>
                        {center?.location?.postcode} {center?.location?.city}
                      </p>
                    </div>
                    {center?.location?.lat && center?.location?.lon
                      && <div className={styles.col}>
                        <label>Latitude :</label> <span>{center?.location?.lat}</span><br/>
                        <label>Longitude :</label> <span>{center?.location?.lon}</span>
                      </div>
                    }
                  </div>
                </div>
                <div className={styles.border}>
                  <h2>RESPONSABLES</h2>
                  <div className={styles.row}>
                    {center?.msl
                      && <div className={styles.col}>
                        <label>Responsable MSL</label><br/>
                        <span>{center?.msl}</span>
                      </div>
                    }
                    {center?.kam
                      && <div className={styles.col}>
                        <label>Responsable KAM</label><br/>
                        <span>{center?.kam}</span>
                      </div>
                    }
                    {center?.rrh
                      && <div className={styles.col}>
                        <label>Responsable RRH</label><br/>
                        <span>{center?.rrh}</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {center?.gers?.length > 0
              && <div className={styles.center}>
                <div className={styles.infos}>
                <h2>GERS</h2>
                <div className={styles.gers}>
                  <div className={styles.label}>
                    <div className={styles.col}>
                      Produit
                    </div>
                    <div className={styles.col}>
                      cm3
                    </div>
                    <div className={styles.col}>
                      cma
                    </div>
                    <div className={styles.col}>
                      pdm
                    </div>
                  </div>
                  {center?.gers?.map((g) => <div key={g._id} className={styles.item}>
                    <div className={styles.col}>
                      {g.product}
                    </div>
                    <div className={styles.col}>
                      {g.cm3}
                    </div>
                    <div className={styles.col}>
                      {g.cma}
                    </div>
                    <div className={styles.col}>
                      {(parseFloat(g.pdm) * 100).toFixed(2)} %
                    </div>
                  </div>)}
                </div>
              </div>
            </div>}
            {center?.services.map((s) => <ServiceView
              key={s._id}
              service={s}
              pathologies={pathologiesList.filter(
                (p) => s.pathologies.find((pa) => pa.pathology === p._id),
              )}
              drugs={s.drugs.map((d) => drugsList.find((dr) => dr._id === d))}
              projects={projectsOptions?.filter(
                (p) => p?.centers?.find((c) => c.service === s?._id),
              )}
            />)}
          </div>
          <aside>
            <div className={styles.box}>
              <h2>PRODUITS</h2>
              <div className={styles.list}>
                {products?.map((p) => <Link to={['/product/', p._id].join('')} key={p._id}>{p.name}</Link>)}
              </div>
            </div>
            <div className={styles.box}>
              <h2>Pathologies</h2>
              <div className={styles.list}>
                {pathologies?.map((p) => <Link to={['/pathology/', p._id].join('')} key={p._id}>{p.name}</Link>)}
              </div>
            </div>
          </aside>
          </>
        }
      </main>
    </div>
  );
}
