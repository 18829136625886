import React, { useRef, useState } from 'react';
import {
  IoChevronDownOutline, IoMedkitOutline, IoBriefcaseOutline, IoClipboardOutline,
} from 'react-icons/io5';
import { RiHandCoinLine } from 'react-icons/ri';
import uniqid from 'uniqid';
import { FiBox } from 'react-icons/fi';

import { format } from 'date-fns';
import styles from './ServiceView.module.scss';

export default function ServiceView({
  service, projects, drugs, pathologies,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const height = contentRef?.current?.clientHeight || 0;
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>{service.name}</h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      {!isOpen
        && <div className={styles['icon-row']}>
          <div className={styles.icon}>
            <FiBox size={20}/> {service.drugs.length}
          </div>
          <div className={styles.icon}>
            <IoClipboardOutline size={22}/> {service.pathologies.length}
          </div>
          <div className={styles.icon}>
            <RiHandCoinLine size={24}/> {service.donations.length}
          </div>
          <div className={styles.icon}>
            <IoMedkitOutline size={22}/> {projects?.filter((p) => p?.type === 'medical').length}
          </div>
          <div className={styles.icon}>
            <IoBriefcaseOutline size={22}/> {projects?.filter((p) => p?.type === 'marketing').length}
          </div>
        </div>
      }
      <div
        style={{
          height: isOpen
            && height > 0 ? height : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          {drugs.length > 0
            && <section>
              <div className={styles.title}>
                <FiBox size={20}/> Produit associés
              </div>
              <div className={styles.list}>
                {drugs?.map((d) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.bold}>
                    <p>{d?.name && d.name}</p>
                  </div>
                </div>)}
              </div>
            </section>
          }
          {service.pathologies.length > 0
            && <section>
              <div className={styles.title}>
                <IoClipboardOutline size={20}/> Pathologies
              </div>
              <div className={styles.pathologies}>
                {service.pathologies?.map((d) => <div
                  key={uniqid()}
                  className={styles.pathology}
                  >
                  <div className={styles.name}>
                    {pathologies?.find((p) => p._id === d.pathology)
                    && pathologies?.find((p) => p._id === d.pathology)?.name}
                  </div>
                  <div className={styles.form}>
                    {d.form?.filter((f) => f.value && f.name).map((f) => (
                      <div
                        key={f._id}
                        className={styles.field}>
                        <label>{f.label}</label>
                        {f.value
                        && <p>{f.type === 'date' && new Date(f.value).getTime()
                          ? format(new Date(f.value), 'dd/MM/yyyy') : f.value}</p>
                        }
                      </div>
                    ))}
                  </div>
                </div>)}
              </div>
            </section>
          }
          {service?.donations?.length > 0
            && <section>
              <div className={styles.title}>
                <RiHandCoinLine size={20}/> Dons
              </div>
              <div className={styles.list}>
                {service.donations?.map((d) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.don}>
                  {d?.url
                    ? <a
                      href={d.url}
                      rel="noreferrer"
                      target='_blank' >
                      {d?.name && d.name}
                    </a>
                    : <p>{d?.name && d.name}</p>
                  }
                  <p>{d?.amount && d.amount} €</p>
                </div>
                </div>)}
              </div>
            </section>
          }
          {projects.filter((p) => p?.type === 'medical').length > 0
            && <section>
              <div className={styles.title}>
                <IoMedkitOutline size={18}/> Projets médicaux
              </div>
              <div className={styles.list}>
                {projects.filter((p) => p?.type === 'medical')?.map((d) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.bold}>
                    <p>{d.label}</p>
                  </div>
                </div>)}
              </div>
            </section>
          }
          {projects.filter((p) => p?.type === 'marketing').length > 0
            && <section>
              <div className={styles.title}>
                <IoBriefcaseOutline size={18}/> Projets marketing
              </div>
              <div className={styles.list}>
                {projects.filter((p) => p?.type === 'marketing')?.map((d) => <div
                  key={uniqid()}
                  className={styles.item}
                  >
                  <div className={styles.bold}>
                    <p>{d.label}</p>
                  </div>
                </div>)}
              </div>
            </section>
          }
        </div>
      </div>
    </div>
  );
}
