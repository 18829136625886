import React, {
  useRef, useEffect,
  useState,
} from 'react';
import { AiFillDelete } from 'react-icons/ai';

import { useDispatch } from 'react-redux';
import TagsList from '../../../TagsList/TagsList';
import { InputFormatNumber, InputText, Textarea } from '../../../../lib/HooksFormFields';
import InputFile from '../../../InputFile/InputFile';

import { API_URL } from '../../../../actions';
import { deleteFileAction } from '../../../../actions/filesActions';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import { generateKey } from '../../../../utils/utils';
import Modal from '../../../../lib/ModalComponents/Modal';

import pen from '../../../../assets/images/icons/pen.svg';
import styles from './Octa.module.scss';
import SymposiumModal from './SymposiumModal/SymposiumModal';
import FilesPreview from '../../../FilesPreview/FilesPreview';

export default function Octa({
  control,
  // errors,
  watch,
  setValue,
  submit,
  activeForm,
}) {
  const [editSyposium, setEditSyposium] = useState();
  const dispatch = useDispatch();
  const booleanOptions = [{ value: true, label: 'Oui' }, { value: false, label: 'Non' }];
  const symposiums = watch('symposium.symposiums');
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  function deleteDonationFile(id) {
    deleteFileAction(dispatch, id);
    setValue('dotation', null);
    submit({ dotation: null });
  }

  function deletePosterFile(id) {
    deleteFileAction(dispatch, id);
    setValue('poster', null);
    submit({ poster: null });
  }

  function deleteStandFile(id) {
    deleteFileAction(dispatch, id);
    const array = watch('participation.files')?.filter((f) => f._id !== id);
    setValue('participation.files', array);
    submit({
      ...watch(),
      participation: {
        ...watch('participation'),
        files: array,
      },
    });
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <BoxPanel label={'STAND'}>
            <div className={styles['input-boolean']} >
              <div className={styles['input-block']}>
                <label className={styles.bold}>Stand local</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={true}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter((opt) => opt.value === watch('participation.stand')).map((o) => o.value)}
                    handleChange={(value) => setValue('participation.stand', value)}
                    />
                </div>
              </div>
              {!watch('participation.stand')
                && <div className={styles['input-block']}>
                  <label className={styles.bold}>Stand Corporate</label>
                  <div className={styles.input}>
                    <TagsList
                      isEdit={true}
                      options={booleanOptions || []}
                      isUnique={true}
                      isBoolean={true}
                      selected={booleanOptions.filter(
                        (opt) => opt.value === watch('participation.standCorporate'),
                      ).map((o) => o.value)}
                      handleChange={(value) => setValue('participation.standCorporate', value)}
                      />
                  </div>
                </div>
              }
              {watch('participation.stand')
                && <div className={styles['input-block']}>
                <label className={styles.bold}>Standiste</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={true}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter(
                      (opt) => opt.value === watch('participation.standProvider'),
                    ).map((o) => o.value)}
                    handleChange={(value) => setValue('participation.standProvider', value)}
                    />
                </div>
              </div>
              }
            </div>
            {watch('participation.stand')
            && <div className={styles['input-row']}>
              <div className={styles['input-block']}>
                <label>Emplacement du stand</label>
                <div className={styles.input}>
                  <InputText
                    name='participation.location'
                    control={control}
                    className={'primary'}
                    rules={{}}
                    placeholder={'Emplacement du stand'}
                  />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label>Taille du stand </label>
                <div className={styles.input}>
                  <InputFormatNumber
                    name='participation.size'
                    control={control}
                    className={'primary'}
                    rules={{}}
                    placeholder={'taille du stand'}
                  />
                  <div className={styles.unit}>
                    m<sup>2</sup>
                  </div>
                </div>
              </div>
            </div>
            }
            <div className={styles['file-section']}>
              {watch('participation.files') && watch('participation.files').map((f, i) => (<div
              key={generateKey('files', i)}
              className={styles.file}>
              <a href={`${API_URL}/${f?.path}`} target='_blank' rel="noreferrer">
                {f?.name || 'fichier'}
              </a>
              <button
                type="button"
                onClick={() => deleteStandFile(f._id)}
                >
                <AiFillDelete size={20} />
              </button>
            </div>))}
            <div className={styles['input-file']}>
              <InputFile
                activeFile={null}
                handleChange={(fileId) => submit({
                  ...watch(),
                  participation: {
                    ...watch('participation'),
                    files: [...watch('participation.files'), fileId],
                  },
                })}
                className={styles.input}
                label="Ajouter une pièce jointe"
                name={'participation.files'}
              />
            </div>
            </div>
            <div className={styles['input-boolean']} >
              <div className={styles['input-block']}>
                <label className={styles.bold}>Invités</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={true}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter((opt) => opt.value === watch('participation.guests')).map((o) => o.value)}
                    handleChange={(value) => setValue('participation.guests', value)}
                    />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label className={styles.bold}>Participants</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={true}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter((opt) => opt.value === watch('participation.participants')).map((o) => o.value)}
                    handleChange={(value) => setValue('participation.participants', value)}
                    />
                </div>
              </div>
              <div className={styles['input-block']}>
              </div>
            </div>
            <div className={styles['input-block']}>
              <label>Instructions pour ouvrir la réserve</label>
              <div className={styles.input}>
                <Textarea
                  name='instruction'
                  control={control}
                  className={'primary'}
                  rules={{}}
                  placeholder={'Instruction'}
                />
              </div>
            </div>
            <div className={styles['input-block']}>
              <label>Installation et démontage du stand</label>
              <div className={styles.input}>
                <Textarea
                  name='installation'
                  control={control}
                  className={'primary'}
                  rules={{}}
                />
              </div>
            </div>
          </BoxPanel>
          <BoxPanel label={'SYMPOSIUM'}>
            <div className={styles['input-boolean']} >
              <div className={styles['input-block']}>
                <label className={styles.bold}>Symposium</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={true}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter((opt) => opt.value === watch('symposium.symposium')).map((o) => o.value)}
                    handleChange={(value) => setValue('symposium.symposium', value)}
                    />
                </div>
              </div>
              <div className={styles['input-block']}>
                <label className={styles.bold}>Symposium Corporate</label>
                <div className={styles.input}>
                  <TagsList
                    isEdit={true}
                    options={booleanOptions || []}
                    isUnique={true}
                    isBoolean={true}
                    selected={booleanOptions.filter((opt) => opt.value === watch('symposium.symposiumCorporate')).map((o) => o.value)}
                    handleChange={(value) => setValue('symposium.symposiumCorporate', value)}
                    />
                </div>
              </div>
              <div className={styles['input-block']}>
              </div>
            </div>
            <div className={styles['symposium-list']}>
              {symposiums?.map((h, i) => (
                <div key={generateKey('symposium', i)} className={styles.card}>
                  <p><b>{h.name}</b></p>
                  <div className={styles.address}>
                    <label>{h.location}</label>
                  </div>
                  <p>{h?.description}</p>
                  {h?.files.length > 0
                    && <FilesPreview key="symposium" files={h?.files} />
                  }

                  <button
                    type="button"
                    onClick={() => setEditSyposium({ ...h, index: i })}
                  >
                    <img src={pen} alt="edit" />
                  </button>
                </div>
              ))}
            </div>
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setEditSyposium({})}
            >
              Ajouter un symposium
            </button>
          </BoxPanel>
          <BoxPanel label={'PDF'}>
            <div className={styles['file-section']}>
              {watch('poster')
                ? <div className={styles.file}>
                    <a href={`${API_URL}/${watch('poster').path}`} target='_blank' rel="noreferrer">
                      {watch('poster').name || 'fichier'}
                    </a>
                    <button
                      type="button"
                      onClick={() => deletePosterFile(watch('poster')._id)}
                      >
                      <AiFillDelete size={20} />
                    </button>
                  </div>
                : <div className={styles['input-file']}>
                    <InputFile
                      activeFile={null}
                      handleChange={(fileId) => submit({ poster: fileId })}
                      name={'poster'}
                  />
                </div>
              }
            </div>
          </BoxPanel>
          <BoxPanel label={'Dotation'}>
            <div className={styles['file-section']}>
              {watch('dotation')
                ? <div className={styles.file}>
                    <a href={`${API_URL}/${watch('dotation').path}`} target='_blank' rel="noreferrer">
                      {watch('dotation').name || 'fichier'}
                    </a>
                    <button
                      type="button"
                      onClick={() => deleteDonationFile(watch('dotation')._id)}
                      >
                      <AiFillDelete size={20} />
                    </button>
                  </div>
                : <div className={styles['input-file']}>
                    <InputFile
                      activeFile={null}
                      handleChange={(fileId) => submit({ dotation: fileId })}
                      name={'dotation'}
                  />
                </div>
              }
            </div>
          </BoxPanel>
        </div>
      </div>
      <Modal toggle={() => setEditSyposium(false)} open={editSyposium}>
        {editSyposium
          && <SymposiumModal
            symposium={editSyposium}
            symposiums={symposiums}
            close={() => setEditSyposium(false)}
            submit={(array) => submit({
              ...watch(),
              symposium: {
                ...watch('symposium'),
                symposiums: array,
              },
            })}
            changeValue={(array) => setValue('symposium.symposiums', array)}
          />
        }
      </Modal>
    </div>
  );
}
