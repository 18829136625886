import React, { useRef, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { AiFillDelete } from 'react-icons/ai';
import {
  InputDate,
  InputText,
} from '../../../../lib/HooksFormFields';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import FormList from '../../../FormList/FormList';
import SentenceList from '../../../SentenceList/SentenceList';
import TagsList from '../../../TagsList/TagsList';
import { generateKey, isValidDate } from '../../../../utils/utils';

import styles from './Regulations.module.scss';
import CipModal from './CipModal/CipModal';
import Modal from '../../../../lib/ModalComponents/Modal';

export default function Regulations({
  setValue,
  watch,
  lists,
  control,
  activeForm,
}) {
  const containerRef = useRef();

  const [editCip, setEditCip] = useState();

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <BoxPanel
          label={'RéGLeMENTATION'}
          >
          <div className={styles['form-group']}>
            <label>DCI</label>
            <div className={styles.value}>
              <InputText
                name='regulations.dci'
                control={control}
                className={'primary'}
              />
            </div>
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>Date AMM</label>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.amm.date"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.url}`}>
                <div className={styles['form-group']}>
                  <label>Lien</label>
                  <div className={styles.value}>
                    <InputText
                      name='regulations.amm.url'
                      control={control}
                      className={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date renouvellement</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.amm.dateRenew"
                      control={control}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>Sunset Clause</label>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.sunset.date"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.url}`}>
                <div className={styles['form-group']}>
                  <label>Lien</label>
                  <div className={styles.value}>
                    <InputText
                      name='regulations.sunset.url'
                      control={control}
                      className={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>Sunset Clause prévisionelle</label>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.forecastSunset.date"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.url}`}>
                <div className={styles['form-group']}>
                  <label>Url</label>
                  <div className={styles.value}>
                    <InputText
                      name='regulations.forecastSunset.url'
                      control={control}
                      className={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BoxPanel>
        <BoxPanel
          label={'CIP'}
        >
          <div className={styles.cips}>
            {watch('regulations.cip') && watch('regulations.cip')?.length > 0 && watch('regulations.cip')?.map((c, i) => <div
              key={generateKey('cip', i)}
              className={styles.cip}
              onClick={() => setEditCip({ ...c, index: i })}
            >
            <div className={styles.row}>
              <div className={styles.col}>
                N° {c.label}
              </div>
              <div className={styles.col}>
                Libellé : {c.number}
              </div>
              <div className={styles.col}>
                Date 1er commerce : {isValidDate(c?.firstDate) && format(new Date(c?.firstDate), 'dd/MM/yyyy')}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                Date arrêt : {isValidDate(c?.stopDate) && format(new Date(c?.stopDate), 'dd/MM/yyyy')}
              </div>
              <div className={styles.url}>
                {c?.url && <a href={c.url} target="_blank" rel="noreferrer">{c.url}</a>}
              </div>
            </div>
          </div>)}
          <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setEditCip({})}
            >
              Ajouter un cip
            </button>
         </div>
        </BoxPanel>
        <BoxPanel
          label={'procédures'}
          >
          <label className={styles.bold}>Enregistrement</label>
          <div className={styles['form-group']}>
            <TagsList
              isEdit={true}
              options={lists?.registrations || []}
              isUnique={true}
              selected={watch('regulations.procedure.registration') ? [watch('regulations.procedure.registration')] : []}
              handleChange={(value) => setValue('regulations.procedure.registration', value)}
              />
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>RCP en vigueur</label>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.procedure.rcp.date"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.url}`}>
                <div className={styles['form-group']}>
                  <label>Lien</label>
                  <div className={styles.value}>
                    <InputText
                      name="regulations.procedure.rcp.url"
                      control={control}
                      className={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>Avis de transparence en vigueur</label>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.procedure.rcpNotice.date"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.url}`}>
                <div className={styles['form-group']}>
                  <label>Lien</label>
                  <div className={styles.value}>
                    <InputText
                      name="regulations.procedure.rcpNotice.url"
                      control={control}
                      className={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>JO en vigueur</label>
            <div className={styles.list}>
              {watch('regulations.procedure.jo')?.map((jo, i) => <div
                key={i}
                className={styles.item}
                >
                <p>{jo?.date && format(new Date(jo.date), 'dd/MM/yyyy')}</p>
                <div>
                  {jo?.url
                    && <a
                      href={jo.url}
                      rel="noreferrer"
                      target='_blank' >
                      {jo?.url}
                    </a>
                  }
                </div>
                <button
                  type="button"
                  onClick={() => setValue('regulations.procedure.jo', watch('regulations.procedure.jo').filter((d) => d._id !== jo._id))}
                  >
                  <AiFillDelete size={20} />
                </button>
              </div>)}
              </div>
            <FormList
              list={watch('regulations.procedure.jo') || []}
              handleChange={(arr) => setValue('regulations.procedure.jo', arr)}
              btn="Ajouter"
              model={[
                {
                  label: 'Date',
                  key: 'date',
                  type: 'date',
                },
                {
                  label: 'Lien',
                  key: 'url',
                  type: 'text',
                },
              ]}
            />
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>TR</label>
            <div className={styles.list}>
              {watch('regulations.procedure.tr')?.map((tr, i) => <div
                key={i}
                className={styles.item}
                >
                <p>{tr.name}</p>
                <p className={styles.value}>{tr.value} €</p>
                <button
                  type="button"
                  onClick={() => setValue('regulations.procedure.tr', watch('regulations.procedure.tr').filter((d) => d._id !== tr._id || d.name !== tr.name))}
                  >
                  <AiFillDelete size={20} />
                </button>
              </div>)}
            </div>
            <FormList
              list={watch('regulations.procedure.tr') || []}
              handleChange={(arr) => setValue('regulations.procedure.tr', arr)}
              btn="Ajouter"
              model={[
                {
                  label: 'Nom',
                  key: 'name',
                  type: 'text',
                },
                {
                  label: 'Montant',
                  key: 'value',
                  type: 'number',
                },
              ]}
            />
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>MITM</label>
            <div className={styles.check}>
              <button
                type="button"
                onClick={() => setValue('regulations.procedure.mitm', true)}
                className={watch('regulations.procedure.mitm') ? styles.active : ''}>
                OUI
              </button>
              <button
                type="button"
                onClick={() => setValue('regulations.procedure.mitm', false)}
                className={!watch('regulations.procedure.mitm') ? styles.active : ''}>
                NON
              </button>
            </div>
          </div>
          <div className={styles.border}>
            <label className={styles.bold}>PGP</label>
            <div className={styles.check}>
              <button
                type="button"
                onClick={() => setValue('regulations.procedure.pgp.isPgp', true)}
                className={watch('regulations.procedure.pgp.isPgp') ? styles.active : ''}>
                OUI
              </button>
              <button
                type="button"
                onClick={() => setValue('regulations.procedure.pgp.isPgp', false)}
                className={!watch('regulations.procedure.pgp.isPgp') ? styles.active : ''}>
                NON
              </button>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <div className={styles.value}>
                    <InputDate
                      name="regulations.procedure.pgp.date"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.url}`}>
                <div className={styles['form-group']}>
                  <label>Lien</label>
                  <div className={styles.value}>
                    <InputText
                      name="regulations.procedure.pgp.url"
                      control={control}
                      className={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BoxPanel>
        <BoxPanel
          label={'VARIATIONS par conditionnement'}
          >
          <SentenceList
            isEdit={true}
            list={watch('regulations.variations') || []}
            handleChange={(arr) => setValue('regulations.variations', arr)}
            label='Ajouter variation'
            btn='Ajouter'
          />
        </BoxPanel>
        <BoxPanel
          label={'Site de fabrication'}
          >
          <div className={styles['form-group']}>
            <label className={styles.bold}>Site de fabrication</label>
            <div className={styles.value}>
              <TagsList
                isEdit={true}
                options={lists?.locations || []}
                selected={watch('regulations.locations.fabrication') ? watch('regulations.locations.fabrication') : []}
                handleChange={(array) => setValue('regulations.locations.fabrication', array)}
                />
            </div>
          </div>
          {/* <div className={styles['form-group']}>
            <label className={styles.bold}>Site de controle echantillonthèque</label>
            <div className={styles.value}>
              <TagsList
                isEdit={true}
                options={lists?.locations || []}
                selected={watch('regulations.locations.control')
                  ? watch('regulations.locations.control') : []}
                handleChange={(array) => setValue('regulations.locations.control', array)}
                />
            </div>
          </div> */}
          {/* <div className={styles['form-group']}>
            <label className={styles.bold}>Site de libération</label>
            <div className={styles.value}>
              <TagsList
                isEdit={true}
                options={lists?.locations || []}
                selected={watch('regulations.locations.release')
                  ? watch('regulations.locations.release') : []}
                handleChange={(array) => setValue('regulations.locations.release', array)}
                />
            </div>
          </div> */}
        </BoxPanel>
      </main>
      <Modal toggle={() => setEditCip(false)} open={editCip}>
        {editCip
          && <CipModal
            cip={editCip}
            cips={watch('regulations.cip')}
            close={() => setEditCip(false)}
            changeValue={(array) => setValue('regulations.cip', array)}
          />
        }
      </Modal>
    </div>
  );
}
