import {
  ERROR_TOOL,
  MESSAGE_TOOL,
  SET_TOOLS_FILTERS,
  GET_TOOLS,
  GET_TOOL,
  PUT_TOOL,
  POST_TOOL,
  GET_TOOLS_DIVISIONS,
  GET_TOOLS_TYPES,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  toolsList: [],
  tool: null,
  divisions: null,
  types: null,
  subtypes: null,
  filters: {
    search: null,
    types: [],
    divisions: [],
  },
};

export default function toolsReducer(state = initialState, action) {
  const toolsList = state?.toolsList;
  const index = state?.toolsList?.findIndex((user) => user?._id === action?.payload?.user?._id);

  switch (action.type) {
    case POST_TOOL:
      return {
        ...state,
        toolsList: [...toolsList, action.payload],
        tool: action.payload,
        message: 'user created',
      };

    case PUT_TOOL:
      toolsList[index] = action.payload;
      return {
        ...state,
        toolsList,
        tool: action.payload,
      };

    case SET_TOOLS_FILTERS:
      return { ...state, filters: action.payload };

    case GET_TOOL:
      return { ...state, tool: action.payload };

    case GET_TOOLS:
      return { ...state, toolsList: action.payload };

      // case DELETE_TOOL:
      //   toolsList = state.toolsList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, toolsList, message: 'user removed' };
    case GET_TOOLS_DIVISIONS:
      return { ...state, divisions: action.payload };

    case GET_TOOLS_TYPES:
      return { ...state, types: action.payload.types, subtypes: action.payload.subtypes };

    case MESSAGE_TOOL:
      return { ...state, message: action.payload };

    case ERROR_TOOL:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
