import React, {
  useState, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { format } from 'date-fns';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronBack } from 'react-icons/io5';

import {
  InputText,
  InputDate,
  StyledSelect,
  ErrorField,
  TextEditor,
} from '../../../lib/HooksFormFields';
import Button from '../../../lib/HooksFormFields/Button';
import InputPicture from '../../../components/InputPicture/InputPicture';

import pen from '../../../assets/images/icons/pen-white.svg';
import styles from './EditNews.module.scss';
import {
  getNewAction, putNewAction, postNewAction, deleteNewsAction,
} from '../../../actions/newsActions';
import { getDivisionsAction, getOctapharamaUsers } from '../../../actions/usersActions';

export default function EditNews({
  isCreate = false,
}) {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    setValue,
    watch,
    formState: {
      errors,
    },
    handleSubmit,
  } = useForm();

  const { news } = useSelector((state) => state.news);
  const users = useSelector((state) => state.users);
  const { octapharmaUsers } = users;
  const divisions = users.divisions && [...users.divisions, { value: 'Bon plan', label: 'Bon plan' }];

  const [isEdit, setIsEdit] = useState(false);

  const getNews = useCallback(() => {
    getNewAction(dispatch, id);
    getOctapharamaUsers(dispatch);
  }, [dispatch, id]);

  const getData = useCallback(() => {
    getOctapharamaUsers(dispatch);
    getDivisionsAction(dispatch);
  }, [dispatch]);

  function submitNews(values) {
    if (news?._id) {
      putNewAction(dispatch, news._id, values);
    } else {
      postNewAction(dispatch, values);
    }
  }

  function deleteNews() {
    deleteNewsAction(dispatch, news._id);
    history.push('/news');
  }

  useEffect(() => {
    getData();
    if (isCreate) {
      setIsEdit(true);
      setValue('date', new Date());
    }
    if (id) {
      getNews();
    }
  }, []);

  useEffect(() => {
    if (news?.title) {
      setValue('title', news.title);
    }
    if (news?.texte) {
      setValue('texte', news.texte);
    }
    if (news?.author) {
      setValue('author', news.author);
    }
    if (news?.type) {
      setValue('type', news.type);
    }
    if (news?.date) {
      setValue('date', new Date(news.date));
    }
  }, [news]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{news?.title
            ? <span>ACTUALITÉS / {news.title}</span>
            : <span>Nouvelle ACTUALITÉ</span>
            }
          </h2>
          <Link to={'/news'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          {isEdit
            ? <div className={styles['btn-status']}>
              <Button
                handleClick={handleSubmit((values) => submitNews({ ...values, status: 'draft' }))}
                className={news?.status === 'draft' ? '' : styles['not-active']}>
                <span>Brouillon</span>
              </Button>
              <Button
                handleClick={handleSubmit((values) => submitNews({ ...values, status: 'published' }))}
                className={news?.status === 'published' ? '' : styles['not-active']}>
                <span>Publier</span>
              </Button>
            </div>

            : <Button
              handleClick={() => setIsEdit(!isEdit)}
              className={styles.edit}>
              <span>EDITER</span>
              <img src={pen} alt="edit-user"/>
            </Button>
          }
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={`${styles.col}`}>
          <section>
            <div className={`${styles['input-list']} ${styles.title}`}>
              <div className={styles.block}>
                <label>Titre</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='title'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <p className={styles.bold}>
                      {news?.title}
                    </p>
                    || <span className={styles['content-none']}>non complété</span>
                  }
                </div>
              </div>

            </div>

            <div className={styles['input-list']}>
              <div className={`${styles.block}`}>
                <label>Date</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputDate
                          name="date"
                          control={control}
                          widthLabel={'30%'}
                          type='datetime-local'
                          placeholder="Selectionner une date..."
                        />
                      </>
                    : <p className={styles.bold}>
                      {news?.date && format(new Date(news?.date), 'dd/MM/yyyy HH:mm')}
                    </p>
                    || <span className={styles['content-none']}>non complété</span>
                  }
                </div>
              </div>
              <div className={styles.block}>
                <label>Auteur</label>
                <div className={styles.value}>
                {isEdit
                  ? <>
                    <StyledSelect
                      name="author"
                      control={control}
                      value={octapharmaUsers?.find((o) => o.value === watch('author') || o.value === watch('author')?._id)}
                      rules={{ required: "l'auteur est requis" }}
                      onChange={(opt) => setValue('author', opt.value)}
                      placeholder="Selectionner un auteur..."
                      options={octapharmaUsers}
                    />
                    {errors?.author
                      && <ErrorField message={errors?.author?.message} />
                    }
                  </>
                  : <p className={styles.bold}>
                    {octapharmaUsers?.find((o) => o.value === watch('author')?._id)?.label}
                  </p> || <span className={styles['content-none']}>non complété</span>
                }
                </div>
              </div>
            </div>

            <div className={styles['input-list']}>
              <div className={styles.block}>
                <label>Type</label>
                <div className={styles.value}>
                {isEdit
                  ? <>
                    <StyledSelect
                      name="type"
                      control={control}
                      value={divisions?.find((o) => o.value === watch('type') || o.value === watch('type')?.value)}
                      rules={{ required: "l'auteur est requis" }}
                      onChange={(opt) => setValue('type', opt.value)}
                      placeholder="Selectionner un type..."
                      options={divisions || []}
                    />
                    {errors?.type
                      && <ErrorField message={errors?.type?.message} />
                    }
                  </>
                  : <p className={styles.bold}>
                    {divisions?.find((o) => o.value === watch('type'))?.value || <span className={styles['content-none']}>non complété</span>}
                  </p>
                }
                </div>
              </div>
            </div>

            <div className={`${styles['input-list']} ${styles.title}`}>

              <div className={styles.block}>
                <label>Texte</label>
                <div className={styles.text}>
                  {isEdit
                    ? <>
                        <TextEditor
                          name='texte'
                          defaultValue={news?.texte || ''}
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                        {news?.texte
                          ? <div dangerouslySetInnerHTML={{ __html: news?.texte }}>
                          </div>
                          : <span className={styles['content-none']}>non complété</span>
                        }
                      </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Illustration</label>
                <InputPicture
                  color={watch('color') || '#E3E0F5'}
                  isEdit={isEdit && news?._id}
                  picture={news?.image}
                  handleChange={(fileId) => submitNews({ image: fileId })}
                  name={'input-image'}
                  />
              </div>

            </div>

          {news?._id
            && <div className={styles.delete}>
              <button
                onClick={() => deleteNews()}
                >
                <AiFillDelete size={20} /> {"supprimer l'actualité"}
              </button>
            </div>
          }

          </section>
        </div>
      </main>
    </form>
  );
}
