import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring';
import styles from './modal.module.scss';

const Modal = ({
  toggle,
  open = false,
  children,
  domNode = 'modal',
}) => {
  const modalRoot = document.getElementById(domNode);
  const [toggleAnimation, setToggleAnimation] = useState(false);

  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  useEffect(() => {
    if (open) {
      setToggleAnimation(true);
    }
  }, [open]);

  function close() {
    setToggleAnimation(false);
  }

  return (
    <>
      { modalRoot
        && <>
          {createPortal(
            <>
              { open
                && <>
                  <animated.div
                    style={{ ...spring }}
                    className={styles['overlay-modal']}
                    onClick={close}
                  />
                  <animated.div
                    style={{ ...spring }}
                    className={styles.modal}
                  >
                     {children}
                     <button className={styles.close} onClick={close}><IoClose /></button>
                  </animated.div>
                </>

              }
            </>,
            modalRoot,
          )}
        </>
      }
    </>
  );
};

Modal.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
  domNode: PropTypes.string,
};

export default Modal;
