import {
  getData, putData, postData,
} from './index';

import {
  ERROR_CENTER,
  GET_CENTERS,
  GET_CENTER,
  PUT_CENTER,
  POST_CENTER,
  SET_TOAST,
} from './types';

export async function getCentersAction(dispatch) {
  const url = '/center';
  let centers;

  await getData(ERROR_CENTER, url, dispatch, true).then((response) => {
    centers = response.centers;
  });

  dispatch({
    type: GET_CENTERS,
    payload: centers,
  });
}

export async function getCenterAction(dispatch, id) {
  const url = `/center/${id}`;
  let center;

  await getData(ERROR_CENTER, url, dispatch, true).then((response) => {
    center = response.center;
  });

  dispatch({
    type: GET_CENTER,
    payload: center,
  });
}

export async function postCenterAction(dispatch, data) {
  const url = '/center';
  let center;

  await postData(POST_CENTER, ERROR_CENTER, url, dispatch, data, true).then((response) => {
    center = response.center;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le centre a été creé',
    },
  });

  dispatch({
    type: POST_CENTER,
    payload: center,
  });
}

export async function putCenterAction(dispatch, id, data) {
  const url = `/center/${id}`;
  let center;

  await putData(PUT_CENTER, ERROR_CENTER, url, dispatch, data, true).then((response) => {
    center = response.center;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le centre a été modifié',
    },
  });

  dispatch({
    type: PUT_CENTER,
    payload: center,
  });
}
