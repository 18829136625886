import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronBack, IoChevronDownOutline, IoSave } from 'react-icons/io5';
import uniqid from 'uniqid';
import { AiFillDelete } from 'react-icons/ai';

import { getCompanyAction, postCompanyAction, putCompanyAction } from '../../../actions/companyActions';
// import {
//   ErrorField,
//   InputText,
//   Textarea,
// } from '../../../lib/HooksFormFields';
import Button from '../../../lib/HooksFormFields/Button';

import styles from './EditCompany.module.scss';
import { InputFormatNumber, InputText, StyledSelect } from '../../../lib/HooksFormFields';
import FormList from '../../../components/FormList/FormList';
import SentenceList from '../../../components/SentenceList/SentenceList';

export default function EditCompany() {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    // formState: {
    //   errors,
    // },
  } = useForm();

  const { company, lists } = useSelector((state) => state.companies);
  const { therapeuticAreasList } = useSelector((state) => state.therapeuticAreas);
  const [isUpdated, setIsUpdated] = useState(false);

  const drugRef = useRef();

  const [drugIsOpen, setDrugIsOpen] = useState(false);
  const [therapeuticsAreasOptions, setTherapeuticsAreasOptions] = useState();

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch, id);
  }, [dispatch, id]);

  function submitCompany(values) {
    if (company?._id) {
      putCompanyAction(dispatch, company._id, values);
    } else {
      postCompanyAction(dispatch, values);
    }
  }

  useEffect(() => {
    if (id) {
      getCompany();
    }
  }, []);

  useEffect(() => {
    if (company) {
      const propreties = [
        'name',
        'type',
        'team',
        'therapeuticAreas',
      ];
      propreties.forEach((p) => {
        if (company[p]) {
          setValue(p, company[p]);
        }
      });
    }
  }, [company]);

  useEffect(() => {
    if (therapeuticAreasList.length) {
      const options = [];
      therapeuticAreasList.forEach((d) => {
        options.push({
          label: d.name,
          value: d._id,
        });
      });
      setTherapeuticsAreasOptions(options);
    }
  }, [therapeuticAreasList]);

  useEffect(() => {
    setIsUpdated(!isUpdated);
  }, [watch('therapeuticAreas'), watch('team.rrh.members'), watch('team.msl.members'), watch('team.kam.members')]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{company?.name
            ? <span>CONCURRENTS / {company.name}</span>
            : <span>Nouveau concurrent</span>
            }
          </h2>
          {id
            ? <Link to={`/company/${id}`} className={styles.link}>
              <IoChevronBack size={20}/> retour à la fiche
            </Link>
            : <Link to={'/company'} className={styles.link}>
              <IoChevronBack size={20}/> retour à la liste
            </Link>
          }
        </div>
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(submitCompany)}
            className={styles.edit}>
            <span>Enregistrer la fiche</span>
            <IoSave size={28} />
          </Button>
        </div>
      </header>
      <main className={`${styles.content} ${isUpdated ? '' : ''}`}>
        <div className={`${styles.col}`}>
          <section>
            <h3>Information</h3>
            <div className={styles['form-group']}>
              <label>Nom</label>
              <div className={styles.value}>
                <InputText
                  name='name'
                  control={control}
                  className={'primary'}
                  placeholder={'Nom'}
                />
              </div>
            </div>
            <div className={styles['form-group']}>
              <label>Type</label>
              <div className={styles.value}>
                <StyledSelect
                  name="type"
                  control={control}
                  value={lists?.types.find((o) => o.value === watch('type'))}
                  onChange={(opt) => setValue('type', opt.value)}
                  placeholder="Selectionner un type..."
                  options={lists?.types}
                />
              </div>
            </div>
          </section>
          <section>
            <div className={styles.title}>
              <h3>
                AIRE THERAPEUTIQUE {watch('therapeuticAreas')?.length > 0 && `(${watch('therapeuticAreas')?.length})`}
              </h3>
              <button
                type="button"
                onClick={() => setDrugIsOpen(!drugIsOpen)}
                className={`${styles.toggle} ${drugIsOpen ? styles.open : ''}`}>
                <IoChevronDownOutline size={20}/>
              </button>
            </div>
            <div
              className={`${styles['section-content']} ${drugIsOpen ? '' : styles.close }`}
              style={{
                height: drugIsOpen ? drugRef.current.clientHeight + 0 : 0,
              }}
              >
              <div ref={drugRef}>
                <div className={styles.list}>
                  {watch('therapeuticAreas')?.map((c) => <div
                    key={uniqid()}
                    className={styles.item}
                    >
                    <div className={styles.bold}>
                      <p>{therapeuticsAreasOptions?.find((d) => d.value === c)?.label}</p>
                    </div>
                    <button
                      type="button"
                      onClick={() => setValue('therapeuticAreas', watch('therapeuticAreas').filter((d) => d !== c))}
                      >
                      <AiFillDelete size={20} />
                    </button>
                  </div>)}
                </div>
                <FormList
                  list={watch('therapeuticAreas') || []}
                  handleChange={(arr) => setValue('therapeuticAreas', arr)}
                  btn="Ajouter"
                  control={control}
                  options={therapeuticsAreasOptions || []}
                  />
              </div>
            </div>
          </section>
        </div>
        <div className={`${styles.col}`}>
          <section>
            <div className={styles.title}>
              <h3>
                PARTIE RH
              </h3>
            </div>
            <Team
              label={'Equipe RRH'}
              watch={watch}
              setValue={setValue}
              control={control}
              members={'team.rrh.members'}
              number={'team.rrh.number'}
            />
            <Team
              label={'Equipe MSL'}
              watch={watch}
              setValue={setValue}
              control={control}
              members={'team.msl.members'}
              number={'team.msl.number'}
            />
            <Team
              label={'Equipe KAM'}
              watch={watch}
              setValue={setValue}
              control={control}
              members={'team.kam.members'}
              number={'team.kam.number'}
            />
          </section>
        </div>
      </main>
    </form>
  );
}

const Team = ({
  members,
  number,
  label,
  watch,
  setValue,
  control,
}) => {
  const teamRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.team}>
      <div className={styles.label}>
        <h4>{label} {watch(number) > 0 && `(${watch(number)})`}</h4>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={`${styles['section-content']} ${isOpen ? '' : styles.close }`}
        style={{
          height: isOpen ? teamRef?.current?.clientHeight + 0 : 0,
        }}
        >
        <div ref={teamRef}>
        <div className={styles['form-group']}>
          <label>Nombre</label>
          <div className={styles.value}>
            <InputFormatNumber
              name={number}
              control={control}
              className={'primary'}
              placeholder={'Nombre de membres'}
            />
          </div>
        </div>
          <SentenceList
            isEdit={true}
            list={watch(members) || []}
            handleChange={(arr) => setValue(members, arr)}
            label='Nouveau membre'
            btn='Ajouter'
            />
        </div>
      </div>
    </div>
  );
};
