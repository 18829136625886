import {
  getData, putData, postData,
} from './index';

import {
  ERROR_COMPANY,
  GET_COMPANIES,
  GET_COMPANY,
  PUT_COMPANY,
  POST_COMPANY,
  SET_TOAST,
} from './types';

export async function getCompaniesAction(dispatch) {
  const url = '/company';
  let companies;

  await getData(ERROR_COMPANY, url, dispatch, true).then((response) => {
    companies = response.companies;
  });

  dispatch({
    type: GET_COMPANIES,
    payload: companies,
  });
}

export async function getCompanyAction(dispatch, id) {
  const url = `/company/${id}`;
  let company;

  await getData(ERROR_COMPANY, url, dispatch, true).then((response) => {
    company = response.company;
  });

  dispatch({
    type: GET_COMPANY,
    payload: company,
  });
}

export async function postCompanyAction(dispatch, data) {
  const url = '/company';
  let company;

  await postData(POST_COMPANY, ERROR_COMPANY, url, dispatch, data, true).then((response) => {
    company = response.company;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le concurrent a été creé',
    },
  });

  dispatch({
    type: POST_COMPANY,
    payload: company,
  });
}

export async function putCompanyAction(dispatch, id, data) {
  const url = `/company/${id}`;
  let company;

  await putData(PUT_COMPANY, ERROR_COMPANY, url, dispatch, data, true).then((response) => {
    company = response.company;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le concurrent a été modifié',
    },
  });

  dispatch({
    type: PUT_COMPANY,
    payload: company,
  });
}
