import React, { useEffect, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import {
  Switch, Route, useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../actions/authActions';
import { setVhUnit } from '../utils/utils';

import Nav from '../components/Nav/Nav';
import Toaster from '../lib/Toaster/Toaster';

import Index from '../pages/Auth/SwitchIndex';

import Login from './Auth/Login';

import Dashboard from './Dashboard/Dashboard';

import UsersList from './Users/UserList/UsersList';
import EditUser from './Users/EditUser/EditUser';

import ToolsList from './Tools/ToolsList/ToolsList';
import EditTool from './Tools/EditTool/EditTool';

import DrugsList from './Drugs/DrugsList/DrugsList';
import EditDrug from './Drugs/EditDrug/EditDrug';

import CentersList from './Centers/CentersList/CentersList';
import CenterView from './Centers/CenterView/CenterView';
import EditCenter from './Centers/EditCenter/EditCenter';

import PathologiesList from './Pathologies/PathologiesList/PathologiesList';
import EditPathology from './Pathologies/EditPathology/EditPathology';

import TherapeuticAreasList from './TherapeuticArea/TherapeuticAreasList/TherapeuticAreasList';
import EditTherapeuticArea from './TherapeuticArea/EditTherapeuticArea/EditTherapeuticArea';

import ContactsList from './Contacts/ContactsList/ContactsList';
import EditContact from './Contacts/EditContact/EditContact';

import EditNews from './News/EditNews/EditNews';
import NewsList from './News/NewsList/NewsList';

import ProjectsList from './Projects/ProjectsList/ProjectsList';
import EditProject from './Projects/EditProject/EditProject';
import ProjectView from './Projects/ProjectView/ProjectView';

import CompaniesList from './Companies/CompaniesList/CompaniesList';
import EditCompany from './Companies/EditCompany/EditCompany';
import CompanyView from './Companies/CompanyView/CompanyView';
import DrugView from './Drugs/DrugView/DrugView';
import PathologyView from './Pathologies/PathologyView/PathologyView';
import CongressesList from './Congresses/CongressesList/CongressesList';
import CongressView from './Congresses/CongressView/CongressView';
import EditCongress from './Congresses/EditCongress/EditCongress';
import DataPolicy from './Legal/DataPolicy';
import Transparency from './Legal/Transparency';

export default function GlobalRouter() {
  const { error } = useSelector((state) => state.auth);
  const history = useHistory();
  const { pathname } = useLocation();
  const isLogin = pathname === '/login' || pathname === '/';
  const dispatch = useDispatch();
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 1, transform: 'translate3d(-120%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(120%, 0,0)' },
  });
  const { toast } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  function logout() {
    history.push('/login');
    localStorage.removeItem('token');
    dispatch({
      type: 'USER_AUTH',
      payload: null,
    });
  }

  useEffect(() => {
    if (token) {
      getUser();
    }
    setVhUnit();
  }, []);

  useEffect(() => {
    if (!isLogin && error === 401) {
      logout();
    }
  }, [error]);

  return (
    <>
      <Nav logout={logout}/>
      {transitions((props, item, key) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>

            <Route exact path='/login' render={
              () => <Login />
            }/>

            <Route exact path='/dashboard' render={
              () => <Dashboard />
            }/>

            <Route exact path='/' render={
              () => <Index />
            }/>

            <Route exact path='/user' render={
              () => <UsersList />
            }/>

            <Route exact path='/user/:id' render={
              () => <EditUser />
            }/>

            <Route exact path='/user/create' render={
              () => <EditUser isCreate={true}/>
            }/>

            <Route exact path='/tool' render={
              () => <ToolsList />
            }/>

            <Route exact path='/tool/create' render={
              () => <EditTool isCreate={true}/>
            }/>

            <Route exact path='/tool/:id' render={
              () => <EditTool />
            }/>

            <Route exact path='/product' render={
              () => <DrugsList />
            }/>

            <Route exact path='/product/create' render={
              () => <EditDrug isCreate={true}/>
            }/>

            <Route exact path='/product/:id' render={
              () => <DrugView />
            }/>

            <Route exact path='/product/edit/:id' render={
              () => <EditDrug />
            }/>

            <Route exact path='/center' render={
              () => <CentersList/>
            }/>

            <Route exact path='/center/create' render={
              () => <EditCenter isCreate={true}/>
            }/>

            <Route exact path='/center/:id' render={
              () => <CenterView />
            }/>

            <Route exact path='/center/edit/:id' render={
              () => <EditCenter />
            }/>

            <Route exact path='/pathology' render={
              () => <PathologiesList />
            }/>

            <Route exact path='/pathology/create' render={
              () => <EditPathology isCreate={true} />
            }/>

            <Route exact path='/pathology/:id' render={
              () => <PathologyView />
            }/>

            <Route exact path='/pathology/edit/:id' render={
              () => <EditPathology />
            }/>

            <Route exact path='/therapeuticarea' render={
              () => <TherapeuticAreasList />
            }/>

            <Route exact path='/therapeuticarea/create' render={
              () => <EditTherapeuticArea isCreate={true} />
            }/>

            <Route exact path='/therapeuticarea/:id' render={
              () => <EditTherapeuticArea />
            }/>

            <Route exact path='/contact' render={
              () => <ContactsList />
            }/>

            <Route exact path='/contact/create' render={
              () => <EditContact isCreate={true} />
            }/>

            <Route exact path='/contact/:id' render={
              () => <EditContact />
            }/>

            <Route exact path='/news' render={
              () => <NewsList />
            }/>

            <Route exact path='/news/create' render={
              () => <EditNews isCreate={true} />
            }/>

            <Route exact path='/news/:id' render={
              () => <EditNews />
            }/>

            <Route exact path='/project' render={
              () => <ProjectsList />
            }/>

            <Route exact path='/project/create' render={
              () => <EditProject />
            }/>

            <Route exact path='/project/:id' render={
              () => <ProjectView />
            }/>

            <Route exact path='/project/edit/:id' render={
              () => <EditProject />
            }/>

            <Route exact path='/congress' render={
              () => <CongressesList />
            }/>

            <Route exact path='/congress/create' render={
              () => <EditCongress />
            }/>

            <Route exact path='/congress/:id' render={
              () => <CongressView />
            }/>

            <Route exact path='/congress/edit/:id' render={
              () => <EditCongress />
            }/>

            <Route exact path='/company' render={
              () => <CompaniesList />
            }/>

            <Route exact path='/company/create' render={
              () => <EditCompany />
            }/>

            <Route exact path='/company/edit/:id' render={
              () => <EditCompany />
            }/>

            <Route exact path='/company/:id' render={
              () => <CompanyView />
            }/>

            <Route exact path="/data-policy" render={
              () => <DataPolicy />
            }/>

            <Route exact path="/transparency" render={
              () => <Transparency />

            }/>

            <Route path='*' render={
              () => <Index />
            }/>

          </Switch>
        </animated.div>
      ))}
      <Toaster toast={toast} dispatch={dispatch}/>
    </>
  );
}
