import {
  ERROR_PATHOLOGY,
  GET_PATHOLOGIES,
  GET_PATHOLOGY,
  PUT_PATHOLOGY,
  POST_PATHOLOGY,
  SET_PATHOLOGIES_FILTERS,
} from '../actions/types';

const initialState = {
  error: null,
  pathologiesList: [],
  pathology: null,
  filters: {
    search: null,
    cities: [],
  },
};

export default function pathologiesReducer(state = initialState, action) {
  const pathologiesList = state?.pathologiesList;
  const index = state?.pathologiesList?.findIndex(
    (pathology) => pathology?._id === action?.payload?._id,
  );

  switch (action.type) {
    case POST_PATHOLOGY:
      return {
        ...state,
        pathologiesList: [...pathologiesList, action.payload],
        pathology: action.payload,
      };

    case PUT_PATHOLOGY:
      pathologiesList[index] = action.payload;
      return {
        ...state,
        pathologiesList,
        pathology: action.payload,
      };

    case SET_PATHOLOGIES_FILTERS:
      return { ...state, filters: action.payload };

    case GET_PATHOLOGY:
      return { ...state, pathology: action.payload };

    case GET_PATHOLOGIES:
      return { ...state, pathologiesList: action.payload };

      // case DELETE_TOOL:
      //   pathologiesList = state.pathologiesList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, pathologiesList, message: 'user removed' };

    case ERROR_PATHOLOGY:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
