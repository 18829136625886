import { createStore, combineReducers } from 'redux';
import authReducer from '../reducers/authReducer';
import centersReducer from '../reducers/centersReducer';
import drugsReducer from '../reducers/drugsReducer';
import filesReducer from '../reducers/filesReducer';
import toolsReducer from '../reducers/toolsReducer';
import usersReducer from '../reducers/usersReducer';
import pathologiesReducer from '../reducers/pathologiesReducer';
import therapeuticAreasReducer from '../reducers/therapeuticAreasReducer';
import contactsReducer from '../reducers/contactsReducer';
import newsReducer from '../reducers/newsReducer';
import projectsReducer from '../reducers/projectsReducer';
import companiesReducer from '../reducers/companiesReducer';
import congressesReducer from '../reducers/congressesReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  tools: toolsReducer,
  files: filesReducer,
  drugs: drugsReducer,
  centers: centersReducer,
  pathologies: pathologiesReducer,
  therapeuticAreas: therapeuticAreasReducer,
  contacts: contactsReducer,
  news: newsReducer,
  projects: projectsReducer,
  companies: companiesReducer,
  congresses: congressesReducer,
});

export default createStore(rootReducer);
