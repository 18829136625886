/* eslint-disable prefer-template */
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import { BiLink } from 'react-icons/bi';
import { GoPencil } from 'react-icons/go';

import { getCompanyAction } from '../../../actions/companyActions';
// import {
//   ErrorField,
//   InputText,
//   Textarea,
// } from '../../../lib/HooksFormFields';
import Button from '../../../lib/HooksFormFields/Button';

import styles from './CompanyView.module.scss';
import TagsList from '../../../components/TagsList/TagsList';
import { getDrugsAction } from '../../../actions/drugsActions';
import { getTherapeuticAreasListAction } from '../../../actions/therapeuticAreasActions';
import { getProjectsAction } from '../../../actions/projectsActions';

export default function CompanyView() {
  const id = useParams()?.id;
  const dispatch = useDispatch();

  const { company, lists } = useSelector((state) => state.companies);
  const { therapeuticAreasList } = useSelector((state) => state.therapeuticAreas);
  const { drugsList } = useSelector((state) => state.drugs);
  const { projectsList } = useSelector((state) => state.projects);
  const companyProjects = projectsList.filter((p) => p?.company === id);

  const [filters, setFilters] = useState({
    therapeuticAreas: [],
    drugs: [],
  });
  const [filteredData, setFilteredData] = useState({
    therapeuticAreas: [],
    drugs: [],
    projects: [],
  });

  const marketingProjects = filteredData?.projects?.filter((p) => p.type === 'marketing');
  const medicalProjects = filteredData?.projects?.filter((p) => p.type === 'medical');

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch, id);
    getDrugsAction(dispatch);
    getTherapeuticAreasListAction(dispatch);
    getProjectsAction(dispatch);
  }, [dispatch, id]);

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (company) {
      const data = { ...filteredData };
      let projects = companyProjects;
      data.studies = [...company.studies];
      if (filters.drugs.length > 0) {
        data.drugs = filters.drugs;
      } else {
        data.drugs = drugsList.filter((d) => d?.company === id).map((d) => d._id);
      }
      if (filters.therapeuticAreas.length > 0) {
        data.therapeuticAreas = filters.therapeuticAreas;
        data.drugs = drugsList
          .filter((d) => d?.company === id)
          .filter((d) => {
            let isIn = false;
            d.therapeuticAreas.forEach((ta) => {
              if (filters.therapeuticAreas.find((t) => t === ta)) {
                isIn = true;
              }
            });
            return isIn ? d : null;
          })
          .map((d) => d._id);
      } else {
        data.therapeuticAreas = company.therapeuticAreas;
      }
      projects = projects.filter((p) => {
        const found = p.drugs.find((d) => data.drugs.find((fd) => fd === d));
        if (found) {
          return p;
        }
        return null;
      });
      data.studies = data.studies.filter((p) => {
        const found = p.drugs.find((d) => data.drugs.find((fd) => fd === d.drug));
        if (found) {
          return p;
        }
        return null;
      });
      data.projects = projects;
      setFilteredData(data);
    }
  }, [company, drugsList, projectsList, filters.drugs, filters.therapeuticAreas]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{company?.name
            ? <span>CONCURRENTS / {company.name}</span>
            : <span>Nouveau concurrent</span>
            }
          </h2>
          <Link to={'/company'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <Link to={`/company/edit/${id}`}>
            <Button
              className={styles.edit}>
              <span>Editer la fiche</span>
              <GoPencil size={20} />
            </Button>
          </Link>
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={styles.company}>
          <h1>{company?.name}</h1>
          <div className={`${styles.infos} ${styles.border}`}>
            <div className={styles.col}>
              <label>Type</label>
              <p>{lists.types.find((t) => t.value === company?.type)?.label}</p>
            </div>
          </div>
          <section className={styles.border}>
            <TagsList
              label={'Aire thérapeutique'}
              options={therapeuticAreasList?.map((t) => ({ value: t._id, label: t.name }))}
              selected={filteredData?.therapeuticAreas}
              isEdit={false}
              />
          </section>
          <section className={styles.border}>
            {drugsList?.length > 0
            && <TagsList
              label={'Produits'}
              options={drugsList?.map((t) => ({ value: t._id, label: t.name }))}
              selected={filteredData?.drugs}
              isEdit={false}
              />
            }
          </section>
          {filteredData?.studies?.length > 0
            && <section className={styles.border}>
            <label>Études cliniques</label>
            <div className={`${styles.list} ${styles.links}`}>
              {filteredData?.studies?.map((p) => {
                const url = process.env.REACT_APP_CLINOCTA_URL + '/edit-study/' + p._id;
                return (
                  <a key={p._id} href={url} target='_blank' rel="noreferrer" className={styles.link}>
                    <span>{p.name}</span><BiLink size={18}/>
                  </a>
                );
              })}
            </div>
          </section>
          }
          {marketingProjects.length > 0
            && <section className={styles.border}>
              <label>Projets marketing</label>
              <div className={styles.list}>
                {marketingProjects?.map((p) => {
                  const url = '/project/' + p._id;
                  return (
                    <a key={p._id} href={url} target='_blank' rel="noreferrer" className={styles.item}>
                      <span>{p.name}</span>
                    </a>
                  );
                })}
              </div>
            </section>
          }
          {medicalProjects.length > 0
            && <section className={styles.border}>
            <label>Projets médicaux</label>
            <div className={styles.list}>
              {medicalProjects?.map((p) => {
                const url = '/project/' + p._id;
                return (
                  <a key={p._id} href={url} target='_blank' rel="noreferrer" className={styles.item}>
                    <span>{p.name}</span>
                  </a>
                );
              })}
            </div>
          </section>
          }
          <section className={styles.border}>
            <label>Partie RH</label>
            {company?.team?.rrh?.number > 0
              && <div className={styles['team-container']}>
              <div className={styles['team-name']}>
                <p>Equipe RRH</p><p>{company.team.rrh.number}</p>
              </div>
              {company.team.rrh.members.length > 0
              && <div className={styles.members}>
                {company.team.rrh.members.map((m) => <p key={m}>{m}</p>)}
              </div>
              }
            </div>
            }
            {company?.team?.msl?.number > 0
              && <div className={styles['team-container']}>
              <div className={styles['team-name']}>
                <p>Equipe MSL</p><p>{company.team.msl.number}</p>
              </div>
              {company.team.msl.members.length > 0
              && <div className={styles.members}>
                {company.team.msl.members.map((m) => <p key={m}>{m}</p>)}
              </div>
              }
            </div>
            }
            {company?.team?.kam?.number > 0
              && <div className={styles['team-container']}>
              <div className={styles['team-name']}>
                <p>Equipe kam</p><p>{company.team.kam.number}</p>
              </div>
              {company.team.kam.members.length > 0
              && <div className={styles.members}>
                {company.team.kam.members.map((m) => <p key={m}>{m}</p>)}
              </div>
              }
            </div>
            }
          </section>
        </div>
        <aside>
          <div className={styles.filters}>
            <label>Aire thérapeutiques</label>
            <div className={styles.tags}>
              <TagsList
                options={therapeuticAreasList
                  .filter((t) => company?.therapeuticAreas.find((ct) => ct === t._id))
                  ?.map((t) => ({ value: t._id, label: t.name }))
                }
                selected={filters.therapeuticAreas}
                isFilter
                handleChange={(array) => setFilters({ ...filters, therapeuticAreas: array })}
                isEdit
                />
            </div>
            <div className={styles.tags}>
             <label>Produits</label>
              <TagsList
                options={drugsList.filter((d) => d?.company === id)
                  .map((d) => ({ value: d._id, label: d.name }))}
                selected={filters.drugs}
                isFilter
                handleChange={(array) => setFilters({ ...filters, drugs: array })}
                isEdit
                />
            </div>
          </div>
          <div className={styles.counter}>
            <label>Nombre de projets marketing</label>
            <p>{marketingProjects.length}</p>
          </div>
          <div className={styles.counter}>
            <label>Nombre de projets médicaux</label>
            <p>{medicalProjects.length}</p>
          </div>
        </aside>
      </main>
    </form>
  );
}
