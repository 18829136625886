import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronDownOutline } from 'react-icons/io5';
import { defaultSubtype, formKeys } from '../../../constants/constants';
import { ErrorField, InputText, Textarea } from '../../../lib/HooksFormFields';
import SubTypeForm from '../SubTypeForm/SubTypeForm';
import styles from './TypeForm.module.scss';

export default function TypeForm({
  type, types, handleChangePathologie, handleSubmitPathologie,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const {
    control,
    setValue,
    watch,
    formState: {
      errors,
    },
  } = useForm({
    defaultValues: {
      _id: type._id,
      name: type.name,
      clinic: type.clinic,
      diagnostic: type.diagnostic,
      etiology: type.etiology,
      epidemiology: type.epidemiology,
      treatment: type.treatment,
      other: type.other,
      mechanisms: type.mechanisms,
      subtypes: type.subtypes,
    },
  });

  const watchType = watch();

  function handleChangeType(form) {
    const typesArray = types || [];
    const index = types?.findIndex((t) => t._id === type._id);
    if (index !== -1) {
      typesArray[index] = { ...typesArray[index], ...form };
    }
    handleChangePathologie('types', typesArray);
  }

  function handleSubmitType(obj) {
    const typesArray = types;
    const index = types.findIndex((t) => t._id === type._id);
    typesArray[index] = { ...typesArray[index], ...obj };
    handleSubmitPathologie({ types: typesArray });
  }

  useEffect(() => {
    if (watchType) {
      handleChangeType(watchType);
    }
  }, [
    watchType.name,
    watchType.clinic,
    watchType.clinic.value,
    watchType.diagnostic,
    watchType.diagnostic.value,
    watchType.epidemiology,
    watchType.epidemiology.value,
    watchType.etiology,
    watchType.etiology.value,
    watchType.treatment,
    watchType.treatment.value,
    watchType.other,
    watchType.other.value,
    watchType.subtypes,
  ]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {type.name}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>
          <div className={styles['input-list']}>
            <div className={`${styles.block}`}>
              <label>Nom du type</label>
              <div className={styles.value}>
                <InputText
                  name='name'
                  control={control}
                  className={'primary'}
                  rules={{ required: 'Le nom est requis' }}
                  placeholder={'Nom'}
                />
                <ErrorField message={errors?.name?.message} />
              </div>
            </div>
          </div>
          <div className={styles['input-list']}>
            <label>Type de champs activés</label>
            <div className={`${styles['tags-list']}`}>
              {formKeys.map((k) => (
                  <button
                  type='button'
                  onClick={() => setValue(k.value, {
                    ...watchType[k.value],
                    isDisplayed: !watchType[k.value].isDisplayed,
                  })}
                  className={watchType[k.value].isDisplayed ? styles.selected : ''}
                  key={k.value}
                  >
                  {k.label}
                </button>
              ))}
            </div>
          </div>
          <div className={styles['input-list']}>
            {formKeys.filter((k) => watchType[k.value].isDisplayed).map((k) => (
                <div key={k.label} className={`${styles.block}`}>
                <label>{k.label}</label>
                <div className={styles.value}>
                  <Textarea
                    name={`${k.value}.value`}
                    control={control}
                    className={'primary'}
                    placeholder={k.label}
                  />
                </div>
              </div>
            ))}
            </div>
            <div className={styles.types}>
            <h3>SOUS-TYPES</h3>
            <button
              type="button"
              onClick={() => handleSubmitType({
                subtypes: [...watchType?.subtypes, defaultSubtype],
              })}
            >
              Ajouter un sous-type
            </button>
          </div>
        </div>

      </div>
      {watchType?.subtypes?.length > 0 && watchType?.subtypes?.map((s) => <SubTypeForm
        key={s._id}
        subtype={s}
        subtypes={watchType?.subtypes}
        handleChangeType={setValue}
      />)}
    </div>
  );
}
