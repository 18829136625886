import React from 'react';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function PathologyRow({
  styles,
  pathology,
  index,
}) {
  return (
    <Link to={`/pathology/${pathology._id}`}className={styles.row} style={{ animationDelay: `${0.08 * index}s` }}>
      <div className={styles.col}>
         <div
          className={styles.icon}
          style={{ backgroundColor: pathology?.color || '#CFD0DB' }}
          >
         </div>
         <p>{pathology?.name}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        <p>{pathology?.category}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{pathology?.patientsCount}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
