import {
  ERROR_THERAPEUTIC_AREA,
  GET_THERAPEUTIC_AREAS,
  GET_THERAPEUTIC_AREA,
  PUT_THERAPEUTIC_AREA,
  POST_THERAPEUTIC_AREA,
  SET_THERAPEUTIC_AREA_FILTERS,
} from '../actions/types';

const initialState = {
  error: null,
  therapeuticAreasList: [],
  therapeuticArea: null,
  filters: {
    search: null,
  },
};

export default function therapeuticAreasReducer(state = initialState, action) {
  const therapeuticAreasList = state?.therapeuticAreasList;
  const index = state?.therapeuticAreasList?.findIndex(
    (therapeuticArea) => therapeuticArea?._id === action?.payload?._id,
  );

  switch (action.type) {
    case POST_THERAPEUTIC_AREA:
      return {
        ...state,
        therapeuticAreasList: [...therapeuticAreasList, action.payload],
        therapeuticArea: action.payload,
      };

    case PUT_THERAPEUTIC_AREA:
      therapeuticAreasList[index] = action.payload;
      return {
        ...state,
        therapeuticAreasList,
        therapeuticArea: action.payload,
      };

    case SET_THERAPEUTIC_AREA_FILTERS:
      return { ...state, filters: action.payload };

    case GET_THERAPEUTIC_AREA:
      return { ...state, therapeuticArea: action.payload };

    case GET_THERAPEUTIC_AREAS:
      return { ...state, therapeuticAreasList: action.payload };

    case ERROR_THERAPEUTIC_AREA:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
