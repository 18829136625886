import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function NewsRow({
  styles,
  news,
  index,
}) {
  function renderStatus(status) {
    let label = 'Brouillon';
    if (status !== 'draft') {
      label = 'Publié';
    }
    return <p className={`${styles.status} ${status !== 'draft' ? styles.published : ''}`}>{label}</p>;
  }
  return (
    <Link to={`/news/${news._id}`}className={styles.row} style={{ animationDelay: `${0.08 * index}s` }}>
      <div className={styles.col}>
        <p className={styles.bold}>{news?.title}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        <p>{format(new Date(news?.date), 'dd/MM/yyyy HH:mm')}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        <p>{news?.author?.profile?.firstName} {news?.author?.profile?.lastName}</p>
      </div>
      <div className={`${styles.col} `}>
        {renderStatus(news?.status)}
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
