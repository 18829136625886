import { postData, getData, TOOL_ID } from './index';

import {
  ERROR_AUTH,
  GET_DASHBOARD,
  MESSAGE_AUTH,
  USER_AUTH,
  POST_SEARCH,
} from './types';

export async function loginAction(dispatch, obj) {
  const url = '/auth/login';
  let login;
  await postData(
    USER_AUTH,
    ERROR_AUTH,
    url,
    dispatch,
    { ...obj, tool: TOOL_ID },
    false,
  ).then((response) => {
    login = response;
  });

  if (login.user.isActive) {
    localStorage.setItem('token', login.token);
    dispatch({
      type: USER_AUTH,
      payload: login.user,
    });
  } else {
    dispatch({
      type: ERROR_AUTH,
      payload: 401,
    });
  }
}

export async function getUserAuthAction(dispatch) {
  const url = '/profile';
  let user;
  await getData(ERROR_AUTH, url, dispatch, true).then((response) => {
    user = response.user;
  });
  if (user.isActive) {
    dispatch({
      type: USER_AUTH,
      payload: user,
    });
  } else {
    dispatch({
      type: ERROR_AUTH,
      payload: 401,
    });
  }
}

export async function forgotPasswordAction(dispatch, obj) {
  const url = '/auth/forgot-password';
  let mailSend;

  await postData(MESSAGE_AUTH, ERROR_AUTH, url, dispatch, obj, false).then((response) => {
    mailSend = response;
  });

  dispatch({
    type: MESSAGE_AUTH,
    payload: mailSend.message,
  });
}

export async function resetPasswordAction(dispatch, token, obj) {
  const url = `/auth/reset-password/${token}`;
  let reset;

  await postData(MESSAGE_AUTH, ERROR_AUTH, url, dispatch, obj, false).then((response) => {
    reset = response.message;
  });
  dispatch({
    type: MESSAGE_AUTH,
    payload: reset,
  });
}

export async function getDashboardAction(dispatch) {
  const url = '/dataviz/octadmin/home';
  let dashboard;
  await getData(ERROR_AUTH, url, dispatch, true).then((response) => {
    dashboard = response;
  });
  dispatch({
    type: GET_DASHBOARD,
    payload: dashboard,
  });
}

export async function postSearchAction(dispatch, obj) {
  const url = '/dataviz/octadmin/search';
  let search;
  await postData(POST_SEARCH, ERROR_AUTH, url, dispatch, obj, true).then((response) => {
    search = response.list;
  });
  dispatch({
    type: POST_SEARCH,
    payload: search,
  });
}
