import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { IoChevronBack, IoSave } from 'react-icons/io5';
import { CgColorPicker } from 'react-icons/cg';

import pen from '../../../assets/images/icons/pen-white.svg';

import { API_URL } from '../../../actions';

import {
  getToolAction,
  putToolAction,
  postToolAction,
  getToolsDivisionsAction,
  getToolsTypesAction,
} from '../../../actions/toolsActions';
import { deleteFileAction } from '../../../actions/filesActions';

import {
  Textarea,
  InputText,
  StyledSelect,
  ErrorField,
  TextEditor,
  Checkbox,
} from '../../../lib/HooksFormFields';
import Button from '../../../lib/HooksFormFields/Button';
import TagsList from '../../../components/TagsList/TagsList';
import InputPicture from '../../../components/InputPicture/InputPicture';
import InputFile from '../../../components/InputFile/InputFile';

import styles from './EditTool.module.scss';

export default function EditTool({
  isCreate = false,
}) {
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const {
    divisions, types, subtypes, tool,
  } = useSelector((state) => state.tools);

  const [isEdit, setIsEdit] = useState(false);

  const getTool = useCallback(() => {
    getToolAction(dispatch, id);
  }, [dispatch, id]);

  function submitTool(values) {
    if (tool?._id) {
      putToolAction(dispatch, tool._id, values);
    } else {
      postToolAction(dispatch, values);
    }
  }

  function deleteFile(item) {
    submitTool({ files: watch('files').filter((f) => f._id !== item._id) });
    deleteFileAction(dispatch, item.file._id);
  }

  useEffect(() => {
    getToolsDivisionsAction(dispatch);
    getToolsTypesAction(dispatch);
    if (isCreate) {
      setIsEdit(true);
    }
    if (id) {
      getTool();
    }
  }, []);

  useEffect(() => {
    if (tool) {
      getToolsTypesAction(dispatch);
      const propreties = [
        'title',
        'description',
        'url',
        'division',
        'type',
        'subtype',
        'color',
        'codeVeeva',
        'inPractice',
        'goodToKnow',
        'withContactForm',
        'textContactForm',
        'files',
        'urlVideo',
        'urlAppStore',
        'urlGooglePlay',
      ];
      propreties.forEach((p) => {
        if (tool[p]) {
          setValue(p, tool[p]);
        }
      });
    }
  }, [tool]);

  useEffect(() => {
    if (watch('type') !== 'marketing') {
      setValue('subtype', null);
    }
  }, [watch('type')]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{tool?.title
            ? <span>Outils / {tool.title}</span>
            : <span>Nouvel outil</span>
            }
          </h2>
          <Link to={'/tool'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          {isEdit
            ? <Button
              handleClick={handleSubmit(submitTool)}
              className={styles.edit}>
              <span>Enregistrer la fiche</span>
              <IoSave size={28} />
            </Button>

            : <Button
              handleClick={() => setIsEdit(!isEdit)}
              className={styles.edit}>
              <span>EDITER LA FICHE</span>
              <img src={pen} alt="edit-user"/>
            </Button>
          }
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={`${styles.col}`}>
          <section>
            <h3>Information</h3>

            <div className={styles['input-list']}>

              <div className={styles.block}>
                <label>Code Veeva</label>
                <div className={`${styles.value} ${styles.code}`}>
                  {isEdit
                    ? <>
                        <InputText
                          name='codeVeeva'
                          control={control}
                          className={'primary'}
                          placeholder={'Code'}
                        />
                      </>
                    : <>
                      {tool?.codeVeeva
                        ? <span className={styles.bold}>
                          {tool?.codeVeeva}
                        </span>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Nom</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='title'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <span className={styles.bold}>
                      {tool?.title}
                    </span>
                    || <span className={styles['content-none']}>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Url</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='url'
                          control={control}
                          className={'primary'}
                          placeholder={'Nom'}
                        />
                      </>
                    : <>
                      {tool?.url
                        ? <a className={styles.bold} href={tool.url} rel="noreferrer" target='_blank'>
                          {tool?.url}
                        </a>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Description</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <Textarea
                          name='description'
                          control={control}
                          className={'primary'}
                          placeholder={'description'}
                        />
                      </>
                    : <span className={styles.bold}>
                      {tool?.description}
                    </span>
                    || <span className={styles['content-none']}>non complété</span>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Type</label>
                <div className={styles.value}>
                {isEdit
                  ? <>
                    <StyledSelect
                      name="type"
                      control={control}
                      value={types?.find((o) => o.value === watch('type'))}
                      rules={{ required: 'le type est requis' }}
                      onChange={(opt) => setValue('type', opt.value)}
                      placeholder="Selectionner un type..."
                      isCreatable={true}
                      formatCreateLabel={(inputValue) => (<p> Ajouter le type: {inputValue}</p>)}
                      options={types}
                    />
                    {errors?.type
                      && <ErrorField message={errors?.type?.message} />
                    }
                  </>
                  : <span className={styles['selected-option']}>{types?.find((o) => o.value === watch('type'))?.label}</span> || <span className={styles['content-none']}>non complété</span>
                }
                </div>
              </div>

              {watch('type') === 'marketing'
                && <div className={styles.block}>
                  <label>Sous-type</label>
                  <div className={styles.value}>
                  {isEdit
                    ? <>
                      <StyledSelect
                        name="subtype"
                        control={control}
                        value={subtypes?.find((o) => o.value === watch('subtype'))}
                        onChange={(opt) => setValue('subtype', opt.value)}
                        placeholder="Selectionner un sous-type..."
                        options={subtypes}
                      />
                      {errors?.subtype
                        && <ErrorField message={errors?.subtype?.message} />
                      }
                    </>
                    : <span className={styles['selected-option']}>{subtypes?.find((o) => o.value === watch('subtype'))?.label}</span> || <span className={styles['content-none']}>non complété</span>
                  }
                  </div>
                </div>
              }

              <div className={styles.block}>
                <label>Divisions</label>
                <div className={styles.value}>
                  <TagsList
                    isEdit={isEdit}
                    options={divisions || []}
                    selected={watch('division') || []}
                    handleChange={(array) => setValue('division', array)}
                    />
                </div>
              </div>

              <div className={styles.block}>
                <label>En pratique</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <TextEditor
                          name='inPractice'
                          defaultValue={tool?.inPractice || ''}
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                      {tool?.inPractice
                        ? <div
                          className={styles.bold}
                          dangerouslySetInnerHTML={{ __html: tool?.inPractice }}>
                        </div>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Bon à savoir</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <TextEditor
                          name='goodToKnow'
                          defaultValue={tool?.goodToKnow || ''}
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                      {tool?.goodToKnow
                        ? <div
                          className={styles.bold}
                          dangerouslySetInnerHTML={{ __html: tool?.goodToKnow }}>
                        </div>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              {<div className={styles.files}>
                <label>Fichers</label>
                {watch('files')?.length > 0
                  ? <div className={styles.list}>
                    {watch('files')?.map((f) => {
                      // eslint-disable-next-line prefer-template
                      const url = API_URL + '/' + f.file.path;
                      return <div key={f._id} className={styles.file}>
                        <a href={url} className={isEdit ? styles.edit : '' } target='_blank' rel="noreferrer">
                          {f.title}
                        </a>
                        {isEdit
                          && <button
                          type="button"
                          onClick={() => deleteFile(f)}
                          >
                          <AiFillDelete size={20} />
                        </button>
                        }
                      </div>;
                    })}
                  </div>
                  : <p className={styles['content-none']}>Aucun fichiers</p>
                }
                {isEdit
                  && <div className={styles.input}>
                  <InputText
                    name='fileName'
                    control={control}
                    className={'primary'}
                    placeholder={'Nom du fichier'}
                    />
                  {watch('fileName')
                    && <InputFile
                    activeFile={null}
                    handleChange={(fileId) => submitTool({ files: [...watch('files'), { title: watch('fileName'), file: fileId }] })}
                    name={'file'}
                    />
                  }
                </div>
              }
            </div>}

              {isEdit
                && <div className={styles.block}>
                <div className={`${styles.value} ${styles.check}`}>
                  <Checkbox
                    name='withContactForm'
                    control={control}
                    className={'primary'}
                    label={'Avec un formulaire de contact'}
                  />
                </div>
              </div>}

              {watch('withContactForm') && isEdit
                && <div className={styles.block}>
                <div className={`${styles.value} ${styles.check}`}>
                  <label>Text du formulaire de contact</label>
                  <TextEditor
                    name='textContactForm'
                    defaultValue={tool?.textContactForm || ''}
                    control={control}
                    className={'primary'}
                    placeholder={''}
                  />
                </div>
              </div>}

              {!isEdit
                && <div className={styles.block}>
                  <label>Formulaire</label>
                  <div className={`${styles.value}`}>
                  {tool?.textContactForm && tool?.withContactForm
                    ? <div
                      className={styles.bold}
                      dangerouslySetInnerHTML={{ __html: tool?.textContactForm }}>
                    </div>
                    : <span className={styles['content-none']}>non complété</span>
                  }
                  </div>
                </div>
              }

              <div className={styles.block}>
                <label>Url de la vidéo</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='urlVideo'
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                      {tool?.urlVideo
                        ? <a className={styles.bold} href={tool?.urlVideo} rel="noreferrer" target='_blank'>
                          {tool?.urlVideo}
                        </a>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Texte store</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <TextEditor
                          name='textStore'
                          defaultValue={tool?.textStore || ''}
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                      {tool?.textStore
                        ? <div
                          className={styles.bold}
                          dangerouslySetInnerHTML={{ __html: tool?.textStore }}>
                        </div>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>{"Url de l'application apple"}</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='urlAppStore'
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                      {tool?.urlAppStore
                        ? <a className={styles.bold} href={tool?.urlAppStore} rel="noreferrer" target='_blank'>
                          {tool?.urlAppStore}
                        </a>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Url sur goolge play</label>
                <div className={styles.value}>
                  {isEdit
                    ? <>
                        <InputText
                          name='urlGooglePlay'
                          control={control}
                          className={'primary'}
                          placeholder={''}
                        />
                      </>
                    : <>
                      {tool?.urlGooglePlay
                        ? <a className={styles.bold} href={tool?.urlGooglePlay} rel="noreferrer" target='_blank'>
                          {tool?.urlGooglePlay}
                        </a>
                        : <span className={styles['content-none']}>non complété</span>
                      }
                    </>
                  }
                </div>
              </div>

            </div>
          </section>
        </div>

        <div className={`${styles.col} ${styles.apercu}`}>
          <section>
            <h3>APERçU</h3>
            <div className={styles['input-list']}>

              <div className={styles.block}>
                <label>Icone</label><br/>
                {isEdit && <span className={styles.reco}>png 200 x 200px</span>}
                <InputPicture
                  color={watch('color') || '#E3E0F5'}
                  icon={true}
                  picture={tool?.icon}
                  isEdit={isEdit && tool?._id}
                  handleChange={(fileId) => submitTool({ icon: fileId })}
                  name={'input-icone'}
                  />
              </div>

              <div className={styles.block}>
                <label>Couleur</label>
                <div className={styles.icon} style={{ backgroundColor: watch('color') || '#E3E0F5' }}>
                  {isEdit && tool?._id
                    && <div className={styles.color}>
                      <input
                        type="color"
                        id="picker"
                        onChange={(e) => setValue('color', e.target.value)}
                        name="picker"
                        value="#E3E0F5"/>
                      <label htmlFor="picker"><CgColorPicker size={20}/></label>
                    </div>
                  }
                </div>
              </div>

              <div className={styles.block}>
                <label>Illustration</label>
                <InputPicture
                  color={watch('color') || '#E3E0F5'}
                  isEdit={isEdit && tool?._id}
                  picture={tool?.image}
                  handleChange={(fileId) => submitTool({ image: fileId })}
                  name={'input-image'}
                  />
              </div>

            </div>
          </section>
        </div>
      </main>
    </form>
  );
}
