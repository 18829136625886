import {
  ERROR_USERS,
  MESSAGE_USERS,
  SET_USERS_FILTERS,
  GET_FILTERED_USERS,
  GET_USER,
  PUT_USER,
  POST_USER,
  DELETE_USER,
  SET_USER_SCROLL,
  GET_USERS_PRODUCTS,
  GET_DIVISIONS,
  GET_SPECIALITIES,
  GET_PROVIDERS_TYPES,
  GET_OCTA_USER,
  GET_ALL_USER,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  usersList: [],
  user: null,
  divisions: null,
  products: null,
  specialities: null,
  octapharmaUsers: null,
  scrollY: 0,
  allUsers: null,
  filters: {
    search: null,
    roles: [],
    types: [],
    tools: [],
    page: 1,
    orderBy: { key: 'name', order: 'desc' },
    number: 15,
  },
};

export default function usersReducer(state = initialState, action) {
  let usersList = state?.usersList;
  const index = state?.usersList?.findIndex((user) => user?._id === action?.payload?.user?._id);

  switch (action.type) {
    case POST_USER:
      return {
        ...state,
        usersList: [...usersList, action.payload.user],
        user: action.payload.user,
        message: 'user created',
      };

    case PUT_USER:
      usersList[index] = action.payload.user;
      return {
        ...state,
        usersList,
        user: action.payload.user,
        message: action.payload.message,
      };

    case SET_USER_SCROLL:
      return { ...state, scrollY: action.payload };

    case SET_USERS_FILTERS:
      return { ...state, filters: action.payload };

    case GET_USERS_PRODUCTS:
      return { ...state, products: action.payload };

    case GET_DIVISIONS:
      return { ...state, divisions: action.payload };

    case GET_PROVIDERS_TYPES:
      return { ...state, providersTypes: action.payload };

    case GET_OCTA_USER:
      return { ...state, octapharmaUsers: action.payload };

    case GET_ALL_USER:
      return { ...state, allUsers: action.payload };

    case GET_SPECIALITIES:
      return { ...state, specialities: action.payload };

    case GET_USER:
      return { ...state, user: action.payload.user };

    case DELETE_USER:
      usersList = state.usersList.filter((user) => user?._id !== action?.payload);
      return { ...state, usersList, message: 'user removed' };

    case GET_FILTERED_USERS:
      if (state.filters.page === 1) {
        usersList = action.payload.users?.map((u, i) => ({ ...u, index: i }));
      } else {
        usersList = [...usersList, ...action.payload.users?.map((u, i) => ({ ...u, index: i }))];
        usersList = [...new Map(usersList.map((item) => [item._id, item])).values()];
      }
      return {
        ...state, usersList, count: action.payload.count, user: null,
      };

    case MESSAGE_USERS:
      return { ...state, message: action.payload };

    case ERROR_USERS:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
