import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronDownOutline } from 'react-icons/io5';
// import { defaultSubtype } from '../../../constants/constants';
import { Textarea } from '../../../lib/HooksFormFields';
import SentenceList from '../../SentenceList/SentenceList';
import SubtypeMutationForm from '../SubtypeMutationForm/SubtypeMutationForm';
import styles from './MutationForm.module.scss';

export default function MutationForm({ type, types, handleChangePathologie }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const {
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      mutationsDescription: type.mutationsDescription,
      symptomsDescription: type.symptomsDescription,
      symptoms: type.symptoms,
      subtypes: type.subtypes,
    },
  });

  const watchType = watch();

  function handleChangeType(form) {
    const typesArray = types || [];
    const index = types?.findIndex((t) => t._id === type._id);
    if (index !== -1) {
      typesArray[index] = { ...typesArray[index], ...form };
    }
    handleChangePathologie('types', typesArray);
  }

  useEffect(() => {
    handleChangeType(watchType);
  }, [
    watchType.mutationsDescription,
    watchType.symptomsDescription,
    watchType.symptoms,
    watchType.subtypes,
  ]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {type.name}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>

          <div className={styles['input-list']}>
          <p className={styles.label}>Mutations</p>
            <div className={`${styles.block}`}>
              <label>Description des mutations</label>
              <div className={styles.value}>
                <Textarea
                  name='mutationsDescription'
                  control={control}
                  className={'primary'}
                  placeholder={'Description des mutations'}
                />
              </div>
            </div>
          </div>

          <div className={styles['input-list']}>
            <p className={styles.label}>Symptomes</p>
            <SentenceList
              isEdit={true}
              list={watch('symptoms') || []}
              handleChange={(arr) => setValue('symptoms', arr)}
              btn='Ajouter'
            />
            <div className={`${styles.block}`}>
              <label>Description des symptomes</label>
              <div className={styles.value}>
                <Textarea
                  name='symptomsDescription'
                  control={control}
                  className={'primary'}
                  placeholder={'Description des symptomes'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {watchType?.subtypes.length > 0 && watchType?.subtypes?.map((s) => <SubtypeMutationForm
        key={s._id}
        subtype={s}
        subtypes={watchType?.subtypes}
        handleChangeType={setValue}
      />)}
    </div>
  );
}
