import React, { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { InputText, StyledSelect } from '../../../../../lib/HooksFormFields';
import styles from './GuestRow.module.scss';

export default function GuestRow({
  user,
  index,
  usersList,
  presenceOptions,
  handleChange,
}) {
  const {
    control,
    watch,
    setValue,
  } = useForm({ defaultValues: user });

  const form = watch();

  useEffect(() => {
    const guests = [...usersList];
    if (index !== -1) {
      guests[index] = { ...form };
      handleChange(guests);
    }
  }, [watch('firstName'), watch('lastName'), watch('presence'), watch('email'), watch('tel')]);

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <InputText
          name="firstName"
          placeholder="Prénom"
          control={control}
        />
      </div>
      <div className={styles.field}>
        <InputText
          name="lastName"
          placeholder="Nom"
          control={control}
        />
      </div>
      <div className={styles.field}>
        <InputText
          name="email"
          placeholder="Email"
          control={control}
        />
      </div>
      <div className={styles.field}>
        <InputText
          name="tel"
          placeholder="Téléphone"
          control={control}
        />
      </div>
      <div className={styles.select}>
        <StyledSelect
          name="presence"
          noRadius={false}
          control={control}
          value={presenceOptions?.find((o) => o.value === watch('presence'))}
          rules={{ }}
          onChange={(opt) => setValue('presence', opt.value)}
          placeholder="Sélectionner..."
          options={presenceOptions}
        />
      </div>
      <button
        type="button"
        onClick={() => handleChange(usersList.filter((
          (o, i) => i !== index)))}
      >
        <AiFillDelete size={20} />
      </button>
    </div>
  );
}
