import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';

import { getPathologyAction } from '../../../actions/pathologiesActions';

import Button from '../../../lib/HooksFormFields/Button';

import pen from '../../../assets/images/icons/pen-white.svg';
import styles from './PathologyView.module.scss';
import TypeView from '../../../components/Pathologies/PathologyView/TypeView/TypeView';

export default function PathologyView() {
  const id = useParams()?.id;
  const dispatch = useDispatch();

  const { pathology } = useSelector((state) => state.pathologies);
  const {
    centersList,
  } = useSelector((state) => state.centers);

  const centers = centersList.filter((c) => {
    let foundPathologie = false;
    c.services.forEach((s) => {
      if (s.pathologies.find((p) => p.pathology === pathology?._id)) {
        foundPathologie = true;
      }
    });
    if (foundPathologie) {
      return c;
    }
    return null;
  });

  const getPathology = useCallback(() => {
    getPathologyAction(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getPathology();
    }
  }, []);

  console.log(centers);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>
            {pathology?.name}
          </h2>
          <Link to={'/pathology'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['btn-container']}>
          <Link to={`/pathology/edit/${pathology?._id}?form=0`} className={styles.link}>
            <Button
              className={styles.edit}>
              <span>EDITER LA FICHE</span>
              <img src={pen} alt="edit-user"/>
            </Button>
          </Link>
        </div>
      </header>
      <main className={`${styles.content}`}>
        <div className={styles.col}>
          <div className={styles.infos}>
            <div className={styles.name}>
              <div
                className={styles.icon}
                style={{ backgroundColor: pathology?.color || '#CFD0DB' }}
                >
              </div>
              <h1>{pathology?.name}</h1>
            </div>
            <p><label>Catégorie</label><br/>
              {pathology?.category}
            </p>
          </div>
          {pathology?.types?.map((t) => <TypeView key={t._id} type={t} />)}
        </div>
        <div className={`${styles.col} ${styles.right}`}>
          <div className={styles.infos}>
            <h3>Centres</h3>
            <div className={styles.centers}>
              {centers.map((c) => <Link
                key={c._id}
                className={styles.item}
                to={`/center/${c._id}/?form=0`}
              >
                {c.name} - {c?.location?.city}
              </Link>)}
            </div>
          </div>
        </div>
      </main>
    </form>
  );
}
