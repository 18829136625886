import {
  ERROR_DRUG,
  GET_DRUGS,
  GET_DRUG,
  PUT_DRUG,
  POST_DRUG,
  SET_DRUGS_FILTERS,
  GET_DRUGS_LIST,
  SET_DRUG_SCROLL,
} from '../actions/types';

const initialState = {
  error: null,
  drugsList: [],
  drug: null,
  variations: null,
  lists: null,
  scrollY: 0,
  filters: {
    search: null,
  },
};

export default function drugsReducer(state = initialState, action) {
  const drugsList = state?.drugsList;
  const index = state?.drugsList?.findIndex((drug) => drug?._id === action?.payload?._id);

  switch (action.type) {
    case SET_DRUG_SCROLL:
      return {
        ...state,
        scrollY: action.payload,
      };
    case POST_DRUG:
      return {
        ...state,
        drugsList: [...drugsList, action.payload],
        drug: action.payload,
      };

    case PUT_DRUG:
      drugsList[index] = action.payload;
      return {
        ...state,
        drugsList,
        drug: action.payload,
      };

    case SET_DRUGS_FILTERS:
      return { ...state, filters: action.payload };

    case GET_DRUGS_LIST:
      return { ...state, lists: action.payload };

    case GET_DRUG:
      console.log(action.payload);
      return { ...state, drug: action.payload.drug, variations: action.payload.variations };

    case GET_DRUGS:
      return {
        ...state, drugsList: action.payload, drug: null, variations: null,
      };

      // case DELETE_TOOL:
      //   drugsList = state.drugsList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, drugsList, message: 'user removed' };

    case ERROR_DRUG:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
