import React, { useRef, useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import TaskCard from '../../../TaskCard/TaskCard';
import styles from './Tasks.module.scss';

export default function Tasks({ tasks }) {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   setIsOpen(true);
  // }, []);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h2>Tâches</h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && contentRef?.current?.clientHeight > 0 ? contentRef.current.clientHeight + 20 : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          <div className={styles.tasks}>
          {tasks.map((t) => <TaskCard
            key={t._id}
            task={t}
            isEdit={false}
          />)}
          </div>
        </div>
      </div>
    </section>
  );
}
