import React, { useRef, useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import FormList from '../../../FormList/FormList';
import SentenceList from '../../../SentenceList/SentenceList';

import styles from './Conditioning.module.scss';

export default function Conditioning({
  setValue,
  watch,
  activeForm,
}) {
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);
  return (
    <div ref={containerRef} className={styles.container}>
      <main>
        <div className={styles.box}>
          <h3>CONDITIONNEMENT</h3>
          <label className={styles.bold}>Flacon</label>
          <div className={styles.list}>
            {watch('conditioning.bottles')?.map((d) => <div
              key={d._id}
              className={styles.item}
              >
              <p className={styles.bold}>{d?.name}</p>
              <p>{d?.dose}</p>
              <button
                type="button"
                onClick={() => setValue('conditioning.bottles', watch('conditioning.bottles').filter((cd) => cd?._id !== d._id))}
                >
                <AiFillDelete size={20} />
              </button>
            </div>)}
          </div>
          <FormList
            list={watch('conditioning.bottles') || []}
            handleChange={(arr) => setValue('conditioning.bottles', arr)}
            btn="Ajouter"
            model={[
              {
                label: 'Nom',
                key: 'name',
                type: 'text',
              },
              {
                label: 'Dose',
                key: 'dose',
                type: 'text',
              },
            ]}
          />
          <div className={styles.border}>
            <label className={styles.bold}>Contenu boîte</label>
            <SentenceList
              isEdit={true}
              list={watch('conditioning.boxes') || []}
              handleChange={(arr) => setValue('conditioning.boxes', arr)}
              label='Ajouter contenu de boîte'
              btn='Ajouter'
            />
          </div>
        </div>
      </main>
    </div>
  );
}
