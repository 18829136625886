import React, { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { InputText, StyledSelect } from '../../../../../lib/HooksFormFields';
import styles from './PeopleRow.module.scss';

export default function PeopleRow({
  user,
  index,
  usersList,
  badgesOptions,
  usersOptions,
  handleChange,
}) {
  const {
    control,
    watch,
    setValue,
  } = useForm({ defaultValues: user });

  const form = watch();
  const divisionOptions = usersOptions
    ?.find((o) => o.value === watch('user') || o.value === watch('user')
      ?._id)
    ?.division.map((d) => ({ value: d, label: d }));

  useEffect(() => {
    const octapeoples = [...usersList];
    if (index !== -1) {
      octapeoples[index] = { ...form };
      handleChange(octapeoples);
    }
  }, [watch('user'), watch('division'), watch('badge'), watch('email'), watch('tel')]);

  return (
    <div className={styles.container}>
      <StyledSelect
        name="user"
        noRadius={false}
        control={control}
        value={usersOptions?.find((o) => o.value === watch('user') || o.value === watch('user')?._id)}
        rules={{ }}
        onChange={(opt) => setValue('user', opt.value)}
        placeholder="Selectionner..."
        options={usersOptions}
      />
      <div className={styles.select}>
        <StyledSelect
          name="division"
          noRadius={false}
          control={control}
          value={divisionOptions?.find((o) => o.value === watch('division'))}
          rules={{ }}
          onChange={(opt) => setValue('division', opt.value)}
          placeholder="Selectionner..."
          options={divisionOptions}
        />
      </div>
      <div className={styles.select}>
        <StyledSelect
          name="badge"
          noRadius={false}
          control={control}
          value={badgesOptions?.find((o) => o.value === watch('badge'))}
          rules={{ }}
          onChange={(opt) => setValue('badge', opt.value)}
          placeholder="Selectionner..."
          options={badgesOptions}
        />
      </div>
      <div className={styles.field}>
        <InputText
          name="email"
          placeholder="Email"
          control={control}
        />
      </div>
      <div className={styles.field}>
        <InputText
          name="tel"
          placeholder="Téléphone"
          control={control}
        />
      </div>
      <button
        type="button"
        onClick={() => handleChange(usersList.filter((
          (o, i) => i !== index)))}
      >
        <AiFillDelete size={20} />
      </button>
    </div>
  );
}
