import {
  ERROR_CENTER,
  GET_CENTERS,
  GET_CENTER,
  PUT_CENTER,
  POST_CENTER,
  SET_CENTERS_FILTERS,
  SET_CENTER_SCROLL,
} from '../actions/types';

const initialState = {
  error: null,
  centersList: [],
  center: null,
  scrollY: 0,
  filters: {
    search: null,
    cities: [],
  },
};

export default function centersReducer(state = initialState, action) {
  const centersList = state?.centersList;
  const index = state?.centersList?.findIndex((center) => center?._id === action?.payload?._id);

  switch (action.type) {
    case POST_CENTER:
      return {
        ...state,
        centersList: [...centersList, action.payload],
        center: action.payload,
      };

    case PUT_CENTER:
      centersList[index] = action.payload;
      return {
        ...state,
        centersList,
        center: action.payload,
      };

    case SET_CENTERS_FILTERS:
      return { ...state, filters: action.payload };

    case SET_CENTER_SCROLL:
      return { ...state, scrollY: action.payload };

    case GET_CENTER:
      return { ...state, center: action.payload };

    case GET_CENTERS:
      return { ...state, centersList: action.payload };

      // case DELETE_TOOL:
      //   centersList = state.centersList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, centersList, message: 'user removed' };

    case ERROR_CENTER:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
