/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import { IoChevronBack, IoChevronForward, IoSave } from 'react-icons/io5';
import { useForm } from 'react-hook-form';

import {
  getDrugAction, getDrugsListAction, postDrugAction, putDrugAction,
} from '../../../actions/drugsActions';

import Button from '../../../lib/HooksFormFields/Button';

import styles from './EditDrug.module.scss';
import Infos from '../../../components/Drugs/DrugForm/Infos/Infos';
import Composition from '../../../components/Drugs/DrugForm/Composition/Composition';
import ClinicalData from '../../../components/Drugs/DrugForm/ClinicalData/ClinicalData';
import Pharmaco from '../../../components/Drugs/DrugForm/Pharmaco/Pharmaco';
import Data from '../../../components/Drugs/DrugForm/Data/Data';
import Conditioning from '../../../components/Drugs/DrugForm/Conditioning/Conditioning';
import Reconstitution from '../../../components/Drugs/DrugForm/Reconstitution/Reconstitution';
import Studies from '../../../components/Drugs/DrugForm/Studies/Studies';
import Regulations from '../../../components/Drugs/DrugForm/Regulations/Regulations';
import { isValidDate } from '../../../utils/utils';

export default function EditDrug() {
  const id = useParams()?.id;
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm();
  const {
    drug,
    lists,
  } = useSelector((state) => state.drugs);
  const { companiesList } = useSelector((state) => state.companies);
  const { therapeuticAreasList } = useSelector((state) => state.therapeuticAreas);
  const navRef = useRef();
  const nav = [
    {
      value: 0,
      label: 'Informations',
    },
    {
      value: 1,
      label: 'Composition',
    },
    {
      value: 2,
      label: 'Données cliniques',
    },
    {
      value: 3,
      label: 'Propriétés pharmaco',
    },
    {
      value: 4,
      label: 'Données pharma',
    },
    {
      value: 5,
      label: 'Conditionnement',
    },
    {
      value: 6,
      label: 'Reconstitution',
    },
    {
      value: 7,
      label: 'Études',
    },
    {
      value: 8,
      label: 'Réglementation',
    },
  ];
  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).form);
  const [scrollNav, setScrollNav] = useState(queryString.parse(location.search).form);
  const [companiesOptions, setCompaniesOptions] = useState();

  function handleChangeForm(val) {
    setActiveForm(val);
    window.history.replaceState(null, null, `?form=${val}`);
  }

  function handleScrollNav(value) {
    setScrollNav(value);
    navRef.current.scrollTo({
      left: (170 * value),
      top: 0,
      behavior: 'smooth',
    });
  }

  const getDrug = useCallback(() => {
    getDrugAction(dispatch, id);
    getDrugsListAction(dispatch);
  }, [dispatch, id]);

  function submitDrug(values) {
    if (drug?._id) {
      putDrugAction(dispatch, drug._id, values);
    } else {
      postDrugAction(dispatch, values);
    }
  }

  useEffect(() => {
    if (id) {
      getDrug();
    }
  }, []);

  useEffect(() => {
    handleScrollNav(activeForm);
  }, [activeForm]);

  useEffect(() => {
    if (drug) {
      const propreties = [
        'name',
        'company',
        'logoDrug',
        'logoCompany',
        'studies',
        'therapeuticAreas',
        'reconstitution',
        'conditioning',
        'data',
        'property',
        'excipient',
        'clinicalData',
        'description',
        'administration',
        'form',
        'composition',
        'viralSecurity',
        'fabricationProcess',
        'formDescription',
      ];
      propreties.forEach((p) => {
        if (drug[p]) {
          setValue(p, drug[p]);
        }
        const { regulations } = drug;
        if (regulations) {
          if (regulations?.dci) {
            setValue('regulations.dci', regulations?.dci);
          }
          if (regulations?.amm?.date) {
            setValue('regulations.amm.date', new Date(regulations?.amm?.date));
          }
          if (regulations?.amm?.dateRenew) {
            setValue('regulations.amm.dateRenew', new Date(regulations?.amm?.dateRenew));
          }
          if (regulations?.amm?.url) {
            setValue('regulations.amm.url', regulations?.amm?.url);
          }
          if (regulations?.sunset?.date) {
            setValue('regulations.sunset.date', new Date(regulations?.sunset?.date));
          }
          if (regulations?.sunset?.url) {
            setValue('regulations.sunset.url', regulations?.sunset?.url);
          }
          if (regulations?.forecastSunset?.date && regulations?.forecastSunset?.date !== '') {
            setValue('regulations.forecastSunset.date', new Date(regulations?.forecastSunset?.date));
          }
          if (regulations?.forecastSunset?.url !== '') {
            setValue('regulations.forecastSunset.url', regulations?.forecastSunset?.url);
          }

          if (regulations?.cip) {
            setValue('regulations.cip', regulations?.cip.map((c) => ({
              ...c,
              firstDate: isValidDate(c.firstDate) ? new Date(c.firstDate) : new Date(),
              stopDate: isValidDate(c.firstDate) ? new Date(c.stopDate) : new Date(),
            })));
          }
          if (regulations?.procedure) {
            if (regulations?.procedure?.registration) {
              setValue('regulations.procedure.registration', regulations.procedure.registration);
            }
            if (regulations?.procedure?.rcp?.url) {
              setValue('regulations.procedure.rcp.url', regulations.procedure.rcp.url);
            }
            if (regulations?.procedure?.rcp?.date) {
              setValue('regulations.procedure.rcp.date', regulations.procedure.rcp.date ? new Date(regulations.procedure.rcp.date) : null);
            }
            if (regulations?.procedure?.rcpNotice?.url) {
              setValue('regulations.procedure.rcpNotice.url', regulations.procedure.rcpNotice.url);
            }
            if (regulations?.procedure?.rcpNotice?.date) {
              setValue('regulations.procedure.rcpNotice.date', new Date(regulations.procedure.rcpNotice.date));
            }
            if (regulations?.procedure?.jo) {
              setValue('regulations.procedure.jo', regulations.procedure.jo);
            }
            if (regulations?.procedure?.tr) {
              setValue('regulations.procedure.tr', regulations.procedure.tr);
            }
            if (regulations?.procedure?.pgr?.url) {
              setValue('regulations.procedure.pgr.url', regulations.procedure.pgr.url);
            }
            if (regulations?.procedure?.pgr?.date && regulations?.procedure?.pgr?.date !== '') {
              setValue('regulations.procedure.pgr.date', new Date(regulations.procedure.pgr.date));
            }
            if (regulations?.procedure?.psur?.url) {
              setValue('regulations.procedure.psur.url', regulations.procedure.psur.url);
            }
            if (regulations?.procedure?.psur?.date && regulations?.procedure?.psur?.date !== '') {
              setValue('regulations.procedure.psur.date', new Date(regulations.procedure.psur.date));
            }
            if (regulations?.procedure?.mitm !== null) {
              setValue('regulations.procedure.mitm', regulations.procedure.mitm);
            }
            if (regulations?.procedure?.pgp?.isPgp !== null) {
              setValue('regulations.procedure.pgp.isPgp', regulations.procedure.pgp.isPgp);
            }
            if (regulations?.procedure?.pgp?.url) {
              setValue('regulations.procedure.pgp.url', regulations.procedure.pgp.url);
            }
            if (regulations?.procedure?.pgp?.date && regulations?.procedure?.pgp?.date !== '') {
              setValue('regulations.procedure.pgp.date', new Date(regulations.procedure.pgp.date));
            }
            if (regulations?.variations) {
              setValue('regulations.variations', regulations.variations);
            }
            if (regulations?.locations) {
              setValue('regulations.locations', regulations.locations);
            }
          }
        }
      });
    }
  }, [drug]);

  useEffect(() => {
    if (companiesList.length) {
      const options = [];
      companiesList.forEach((d) => {
        options.push({
          label: d.name,
          value: d._id,
        });
      });
      setCompaniesOptions(options);
    }
  }, [companiesList]);

  return (
    <form className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{drug?.name
            ? <span>{drug.name}</span>
            : <span>Nouveau produit</span>
            }
          </h2>
          {drug
            ? <Link to={`/product/${id}`} className={styles.link}>
              <IoChevronBack size={20}/> retour à la fiche
            </Link>
            : <Link to={'/product'} className={styles.link}>
              <IoChevronBack size={20}/> retour à la liste
            </Link>
          }
        </div>
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(submitDrug)}
            className={styles.edit}>
            <span>Enregistrer</span>
            <IoSave size={28} />
          </Button>
        </div>
      </header>
      <nav className={styles.nav}>
        <button
          type='button'
          onClick={() => handleScrollNav(0)}>
          {scrollNav > 0 && <IoChevronBack size={20}/>}
        </button>
        <ul ref={navRef}>
          {nav.map((l) => <li
            key={l.label}
            className={`${Number(activeForm) === l.value ? styles['active-form'] : ''}`}
            onClick={() => handleChangeForm(l.value) }>
            {l.label}
          </li>)}
        </ul>
        <button
          type='button'
          onClick={() => handleScrollNav(nav.length)}>
          {scrollNav < 5 && <IoChevronForward size={20}/>}
        </button>
      </nav>
      <main className={`${styles.content}`}>
        <div className={styles['form-container']} style={{ transform: `translateX(-${(Number(activeForm) * 100) / nav.length}%)` }}>
          <Infos
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            submit={submitDrug}
            therapeuticAreasList={therapeuticAreasList?.map((t) => ({ label: t.name, value: t._id }))}
            companiesOptions={companiesOptions}
            control={control}
            />
          <Composition
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            lists={lists}
            control={control}
            />
          <ClinicalData
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            control={control}
            />
          <Pharmaco
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            control={control}
            />
          <Data
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            control={control}
            />
          <Conditioning
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            control={control}
            />
          <Reconstitution
            watch={watch}
            setValue={setValue}
            submit={submitDrug}
            activeForm={activeForm}
            control={control}
            />
          <Studies
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            control={control}
          />
          <Regulations
            watch={watch}
            setValue={setValue}
            activeForm={activeForm}
            control={control}
            lists={lists}
          />
        </div>
      </main>
    </form>
  );
}
