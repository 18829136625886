import React, {
  useCallback, useEffect,
} from 'react';
import {
  Link, useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronBack } from 'react-icons/io5';
import { GoPencil } from 'react-icons/go';

import { getProjectAction } from '../../../actions/projectsActions';

import Button from '../../../lib/HooksFormFields/Button';

import styles from './ProjectView.module.scss';
import Infos from '../../../components/Projects/ProjectViews/Infos/Infos';
import Contract from '../../../components/Projects/ProjectViews/Contract/Contract';
import Actors from '../../../components/Projects/ProjectViews/Actors/Actors';
import Meeting from '../../../components/Projects/ProjectViews/Meeting/Meeting';
import Tasks from '../../../components/Projects/ProjectViews/Tasks/Tasks';
import Com from '../../../components/Projects/ProjectViews/Com/Com';

export default function ProjectView() {
  const id = useParams()?.id;
  const { project } = useSelector((state) => state.projects);
  const { companiesList } = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  function formatDate(date) {
    const dayOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const hourOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    return `${new Intl.DateTimeFormat('fr', dayOptions).format(new Date(date))} à ${new Intl.DateTimeFormat('fr', hourOptions).format(new Date(date))}`;
  }

  const getProject = useCallback(() => {
    getProjectAction(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getProject();
    }
  }, []);

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.title}>
          <h2>{project?.name
              && <span>{project.name}</span>
            }
          </h2>
          <Link to={'/project'} className={styles.link}>
            <IoChevronBack size={20}/> retour à la liste
          </Link>
        </div>
        <div className={styles['update-date']}>
          {project?.updatedAt && <p>
            Dernière modification : <span>{formatDate(project.updatedAt)}</span>
          </p>}
        </div>
        <div className={styles['btn-container']}>
          <Link to={`/project/edit/${id}?form=0`} className={styles.link}>
            <Button
              className={styles.edit}>
              <span>Editer la fiche </span>
              <GoPencil size={20} />
            </Button>
          </Link>
        </div>
      </header>
      <main className={`${styles.content}`}>
        {project?._id
          && <>
            <Infos
              project={project}
              company={companiesList?.find((c) => c._id === project?.company)?.name}
              />
            <Contract project={project} />
            <Actors project={project} />
            {project.meetings.length > 0 && <Meeting meetings={project.meetings} />}
            {project.tasks.length > 0 && <Tasks tasks={project.tasks} />}
            <Com project={project} />
          </>
        }
      </main>
    </div>
  );
}
