import React, { useRef, useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { AiOutlineUser } from 'react-icons/ai';
import styles from './Actors.module.scss';
import { OCTAPHARMA_COMPANY_ID } from '../../../../actions';

export default function Actors({ project }) {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   setIsOpen(true);
  // }, []);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h2>PILOTAGE</h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        style={{
          height: isOpen
            && contentRef?.current?.clientHeight > 0 ? contentRef.current.clientHeight + 20 : 0,
        }}
        className={styles.content}>
        <div ref={contentRef}>
          <div className={styles.row}>
            {project?.coordinator
              && <div className={`${styles.col} ${styles.icon}`}>
                <AiOutlineUser size={20}/>
                <p>
                  <label>Coordinateur : </label>
                  <span className={styles.bold}>{project?.coordinator}</span>
                </p>
              </div>
            }
            {project?.manager
              && <div className={`${styles.col} ${styles.icon}`}>
                <AiOutlineUser size={20}/>
                <p>
                  <label>Chef de projet  : </label>
                  <span className={styles.bold}>{project?.manager}</span>
                </p>
              </div>
            }
          </div>
          <div className={styles.row}>
            <div className={`${styles.col}`}>
            </div>
            {project?.internManager && project.company === OCTAPHARMA_COMPANY_ID
              && <div className={`${styles.col} ${styles.icon}`}>
                <AiOutlineUser size={20}/>
                <p>
                  <label>Chef de projet interne  : </label>
                  <span className={styles.bold}>
                    {project?.internManager?.profile.firstName}
                    {' '}
                    {project?.internManager?.profile.lastName}
                  </span>
                </p>
              </div>
            }
          </div>
          {project?.steeringCommittee?.length > 0 && <div className={styles.border}>
            <h2>Commité de pilotage</h2>
            <div className={styles.list}>
              {project.steeringCommittee.map((m) => <p key={m._id} >
                {m.member}
              </p>)}
            </div>
          </div>
          }
          {project?.scientificCouncil?.length > 0 && <div className={styles.border}>
            <h2>Conseil Scientifique</h2>
            <div className={styles.list}>
              {project.scientificCouncil.map((d) => <p key={d._id} >
                {d?.profile?.firstName} {d?.profile?.lastName}
              </p>)}
            </div>
          </div>
          }
        </div>
      </div>
    </section>
  );
}
