import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { getPathologiesAction } from '../../../actions/pathologiesActions';
import { GET_PATHOLOGY } from '../../../actions/types';

import { Button } from '../../../lib/HooksFormFields';
import SortButton from '../../../lib/SortButton';

import PathologiesFilters from '../../../components/Pathologies/PathologiesFilters/PathologiesFilters';
import PathologyRow from '../../../components/Pathologies/PathologyRow/PathologyRow';

import styles from './PathologiesList.module.scss';

export default function PathologiesList() {
  const { pathologiesList, filters } = useSelector((state) => state.pathologies);

  const dispatch = useDispatch();
  const history = useHistory();

  const initSortState = {
    name: true,
    patientsCount: true,
    category: true,
  };

  const containerRef = useRef(null);

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    getPathologiesAction(dispatch);
    dispatch({
      type: GET_PATHOLOGY,
      payload: null,
    });
  }, [dispatch]);

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'patientsCount':
        list = list.sort((a, b) => {
          if (a?.patientsCount < b?.patientsCount) return -1;
          if (a?.patientsCount > b?.patientsCount) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      case 'category':
        list = list.sort((a, b) => {
          if (a?.category < b?.category) return -1;
          if (a?.category > b?.category) return 1;
          return 0;
        });
        setFilteredList(isReverse ? list.reverse() : list);
        break;
      default:
        break;
    }
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function countFilters() {
    let val = 0;
    if (filters.search) {
      val += 1;
    }
    if (filters?.cities?.length) {
      val += filters?.cities?.length;
    }
    return val;
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    let list = pathologiesList;
    if (filters.search) {
      list = list.filter((p) => {
        if (p.name.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        if (p?.descritption?.toLowerCase().includes(filters.search.toLowerCase())) {
          return p;
        }
        return null;
      });
    }
    setFilteredList(list);
  }, [pathologiesList, filters]);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${filterIsActive ? styles['filter-is-active'] : ''}`}>
        <header>
          <h2>PATHOLOGIES</h2>
          <div className={styles['btn-container']}>
            <Button handleClick={() => history.push('/pathology/create')}>
              NOUVELLE PATHOLOGIE
            </Button>
            <Button
              handleClick={() => setFilterIsActive(!filterIsActive)}
              className={styles.filter}>
              <BsFilterLeft size={30} color={'#FFFFFF'}/> <p>{countFilters()}</p>
            </Button>
          </div>
        </header>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name={'Nom'}
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}/>
            <SortButton
              name={'Catégorie'}
              handleClick={() => sortList('category', !sortByReverse.category)}
              state={sortByReverse.category}
              className={`${styles.col} ${styles.desktop}`}/>
            <SortButton
              name={'Nombre de patients'}
              handleClick={() => sortList('patientsCount', !sortByReverse.patientsCount)}
              state={sortByReverse.patientsCount}
              className={`${styles.col}`}/>
            <div className={`${styles.edit} ${styles.desktop}`}>
            </div>
          </div>
        </div>
        <div
          ref={containerRef}
          className={styles.list}
          >
          {!isLoading && filteredList.length > 0 && filteredList?.map((p, i) => <PathologyRow
            key={p._id}
            pathology={p}
            index={i}
            styles={styles}
          />)}
        </div>
      </div>
      <PathologiesFilters
        isActive={filterIsActive}
        close={() => setFilterIsActive(false)}
      />
    </div>
  );
}
