import React, {
  useRef, useEffect, useState,
} from 'react';
import TaskCard from '../../../TaskCard/TaskCard';
import Modal from '../../../../lib/ModalComponents/Modal';

import styles from './Tasks.module.scss';
import TaskForm from '../../../TaskForm/TaskForm';

export default function Tasks({
  // control,
  watch,
  submit,
  activeForm,
}) {
  const containerRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [task, setTask] = useState();

  const tasks = watch('tasks') || [];

  function handleEditModale(isOpen, obj) {
    setIsModalOpen(isOpen);
    setTask(obj);
  }

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.col}>
        <div className={styles.header}>
          <h3>Liste des tâches</h3>
          <button type="button" onClick={() => handleEditModale(true, {})}>
            Ajouter une tâche
          </button>
        </div>
        {tasks.map((t) => <TaskCard
          key={t._id}
          task={t}
          handleEditModale={(taskData) => handleEditModale(true, taskData)}
        />)}
      </div>
      <Modal toggle={() => handleEditModale(false, null)} open={isModalOpen}>
        {task && <TaskForm
          task={task}
          tasks={tasks}
          submit={submit}
        />}
      </Modal>
    </div>
  );
}
