import React, {
  useRef, useEffect,
} from 'react';
import MutationForm from '../../MutationForm/MutationForm';

// import {
//   ErrorField,
//   InputText,
// } from '../../../../lib/HooksFormFields';

import styles from './Mutations.module.scss';

export default function Mutations({
  // control,
  // errors,
  activeForm,
  watch,
  setValue,
}) {
  const containerRef = useRef();
  const watchTypes = watch('types');

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      {Number(activeForm) === 2
        && <div className={styles.row}>
          <div className={styles.col}>
            {watchTypes?.map((t) => <MutationForm
              key={t._id}
              types={watchTypes}
              type={t}
              handleChangePathologie={setValue}
            />)}
          </div>
        </div>
      }
    </div>
  );
}
