import React from 'react';
import { useForm } from 'react-hook-form';
import { FaCheckSquare } from 'react-icons/fa';
import { rolesOptions } from '../../constants/constants';
import { StyledSelect } from '../../lib/HooksFormFields';
import ProductIcon from '../ProductIcon/ProductIcon';
import styles from './ProductRole.module.scss';

export default function ProductRole({
  product,
  selectedProducts,
  handleChange,
  isEdit,
}) {
  const isActive = selectedProducts.find((s) => s.tool === product._id);
  const {
    control,
  } = useForm();

  function changeSelectedProducts(value) {
    let list = selectedProducts;
    if (isActive) {
      list = list.filter((s) => s.tool !== product._id);
    } else {
      list = [...list, { tool: value, role: 'Member' }];
    }
    handleChange(list);
  }

  function changeProductRole(role) {
    const list = selectedProducts;

    const index = selectedProducts.findIndex((s) => s.tool === product._id);
    list[index].role = role;
    handleChange(list);
  }

  return (
    <div className={`${styles.container} ${isActive ? styles['is-active'] : ''} ${isEdit ? styles['is-edit'] : ''}`}>
      <div className={styles.row}>
        <div className={styles.name}>
          <ProductIcon name={product.title} size={35} />
          <h4>{product?.title}</h4>
        </div>
        {isEdit
          && <button
            type='button'
            onClick={() => changeSelectedProducts(product._id)}
            >
            <FaCheckSquare size={20} />
          </button>
        }
      </div>
      {isActive && isEdit
        && <div className={styles.select}>
          <StyledSelect
            control={control}
            name={'name'}
            value={rolesOptions.find((o) => o.value === isActive.role)}
            rules={{ required: 'le rôle est requis' }}
            onChange={(opt) => changeProductRole(opt.value)}
            placeholder="Selectionner un rôle..."
            options={rolesOptions}
          />
        </div>
      }
    </div>
  );
}
