import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './SearchList.module.scss';

export default function SearchList({ searchList }) {
  const [listByType, setListByType] = useState(null);
  console.log(searchList);
  useEffect(() => {
    if (searchList) {
      const groupedList = [];
      const list = searchList.map((item) => {
        const s = item;
        if (s.type === 'drug') {
          s.type = 'product';
        }
        s.url = `/${item.type}/${item._id}`;
        return s;
      });
      list.forEach((item) => {
        let label = item.type;
        if (label === 'user') {
          label = 'Utilisateurs';
        }
        if (label === 'tool') {
          label = 'Outils';
        }
        if (label === 'product') {
          label = 'Produits';
        }
        if (label === 'center') {
          label = 'Centres et services';
        }
        if (label === 'therapeuticArea') {
          label = 'Aire thérapeutique';
        }
        if (!groupedList.find((g) => g.label === label)) {
          groupedList.push({
            label,
            list: list.filter((d) => d.type === item.type),
          });
        }
      });
      setListByType(groupedList);
    }
  }, [searchList]);
  console.log(listByType);
  return (
    <div className={styles.container}>
      {listByType?.length === 0 && <h2 className={styles['no-result']}>Aucun résultat ne correspond à votre recherche</h2>}
      {listByType?.map((t) => (
        <div key={t.label}>
          <h2>{t.label}</h2>
          <ul>
            {t.list?.map((link) => (
              <li key={link._id}>
                <Link to={link.url}>
                  <p>{link?.label}</p>
                </Link>
              </li>
            ))}
          </ul>

        </div>
      ))}
    </div>
  );
}
