import {
  ERROR_COMPANY,
  GET_COMPANIES,
  GET_COMPANY,
  PUT_COMPANY,
  POST_COMPANY,
  SET_COMPANIES_FILTERS,
} from '../actions/types';

const initialState = {
  error: null,
  companiesList: [],
  company: null,
  lists: {
    types: [{ value: 'laboratory', label: 'Laboratoire' }, { value: 'company', label: 'Entreprise' }],
  },
  filters: {
    search: null,
  },
};

export default function companiesReducer(state = initialState, action) {
  const companiesList = state?.companiesList;
  const index = state?.companiesList?.findIndex(
    (company) => company?._id === action?.payload?._id,
  );

  switch (action.type) {
    case POST_COMPANY:
      return {
        ...state,
        companiesList: [...companiesList, action.payload],
        company: action.payload,
      };

    case PUT_COMPANY:
      companiesList[index] = action.payload;
      return {
        ...state,
        companiesList,
        company: action.payload,
      };

    case SET_COMPANIES_FILTERS:
      return { ...state, filters: action.payload };

    case GET_COMPANY:
      return { ...state, company: action.payload };

    case GET_COMPANIES:
      return { ...state, companiesList: action.payload };

      // case DELETE_TOOL:
      //   companiesList = state.companiesList.filter((user) => user?._id !== action?.payload);
      //   return { ...state, companiesList, message: 'user removed' };

    case ERROR_COMPANY:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
