import React, {
  useRef, useEffect,
  useCallback,
  useState,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOctapharamaUsers } from '../../../../actions/usersActions';

import PeopleRow from './PeopleRow/PeopleRow';

import styles from './People.module.scss';
import GuestRow from './GuestRow/GuestRow';
import { getCongressAction, postCongressPeopleFileAction } from '../../../../actions/congressesActions';

export default function People({
  watch,
  setValue,
  activeForm,
}) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [synchroniseIsLoading, setSynchroniseIsLoading] = useState(false);
  const { octapharmaUsers } = useSelector((state) => state.users);
  const badgesOptions = ['exposant', 'congressiste', 'non'].map(
    (o) => ({ value: o, label: o.charAt(0).toUpperCase() + o.slice(1) }),
  );
  const presenceOptions = [{ value: true, label: 'oui' }, { value: false, label: 'non' }];
  const octapeoples = watch('octapeoples') || [];
  const guests = watch('guests') || [];
  const id = useParams()?.id;

  const getData = useCallback(() => {
    getOctapharamaUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  const handleChangeFile = async (fileData) => {
    setSynchroniseIsLoading(true);
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('name', fileData.name);
    formData.append('alt', fileData.name.split('.')[0]);
    const res = await postCongressPeopleFileAction(dispatch, id, formData);
    if (res) {
      setSynchroniseIsLoading(false);
      getCongressAction(dispatch, id);
    }
  };

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.box}>
            {!synchroniseIsLoading
              ? <div className={styles['input-file']}>
                <input
                  id="synchronise"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => handleChangeFile(e.target.files[0])}
                />
                <label htmlFor='synchronise'>Synchroniser les participants</label>
              </div>
              : <div className={styles.loading}>
              <div className={styles.loader}>

              </div>
               Synchronisation des participants en cours ...
            </div>
            }
            <section>
              <h3>Octapeople</h3>
              {octapeoples?.map((p, i) => <PeopleRow
                key={p._id}
                user={p}
                index={i}
                usersList={octapeoples}
                badgesOptions={badgesOptions}
                usersOptions={octapharmaUsers}
                handleChange={(users) => setValue('octapeoples', users)}
              />)}
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setValue('octapeoples', [...octapeoples, {
                user: null, division: null, badge: 'non', email: '', tel: '',
              }])}
            >
              Ajouter
            </button>
            </section>
            <section>
              <h3>Invités</h3>
              {guests?.map((p, i) => <GuestRow
                key={p._id}
                user={p}
                index={i}
                usersList={guests}
                presenceOptions={presenceOptions}
                handleChange={(users) => setValue('guests', users)}
              />)}
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setValue('guests', [...guests, {
                firstName: '', lastName: '', presence: false, email: '', tel: '',
              }])}
            >
              Ajouter
            </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
