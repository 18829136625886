import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronDownOutline } from 'react-icons/io5';
// import { defaultSubtype } from '../../../constants/constants';
import { Textarea } from '../../../lib/HooksFormFields';
import SentenceList from '../../SentenceList/SentenceList';
import styles from './SubtypeMutationForm.module.scss';

export default function SubtypeMutationForm({ subtype, subtypes, handleChangeType }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const {
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      _id: subtype._id,
      name: subtype.name,
      clinic: subtype.clinic,
      diagnostic: subtype.diagnostic,
      etiology: subtype.etiology,
      epidemiology: subtype.epidemiology,
      treatment: subtype.treatment,
      other: subtype.other,
      mechanisms: subtype.mechanisms,
      mutationsDescription: subtype.mutationsDescription,
      symptomsDescription: subtype.symptomsDescription,
      symptoms: subtype.symptoms,
    },
  });

  const watchSubtype = watch();

  function handleChangeSubtype(form) {
    const typesArray = subtypes || [];
    const index = subtypes?.findIndex((t) => t._id === subtype._id);
    if (index !== -1) {
      typesArray[index] = { ...typesArray[index], ...form };
    }
    handleChangeType('subtypes', typesArray);
  }

  useEffect(() => {
    handleChangeSubtype(watchSubtype);
  }, [
    watchSubtype.mutationsDescription,
    watchSubtype.symptomsDescription,
    watchSubtype.symptoms,
  ]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {subtype.name}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>

          <div className={styles['input-list']}>
          <p className={styles.label}>Mutations</p>
            <div className={`${styles.block}`}>
              <label>Description des mutations</label>
              <div className={styles.value}>
                <Textarea
                  name='mutationsDescription'
                  control={control}
                  className={'primary'}
                  placeholder={'Description des mutations'}
                />
              </div>
            </div>
          </div>

          <div className={styles['input-list']}>
            <p className={styles.label}>Symptomes</p>
            <SentenceList
              isEdit={true}
              list={watch('symptoms') || []}
              handleChange={(arr) => setValue('symptoms', arr)}
              btn='Ajouter'
            />
            <div className={`${styles.block}`}>
              <label>Description des symptomes</label>
              <div className={styles.value}>
                <Textarea
                  name='symptomsDescription'
                  control={control}
                  className={'primary'}
                  placeholder={'Description des symptomes'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
