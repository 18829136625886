import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { GrFormView, GrFormViewHide } from 'react-icons/gr';
import styles from './input-password.module.scss';
// At least 8 characters
// On digit
// On uppercase
// On special characters

const InputPassword = ({
  name,
  control,
  required,
  widthLabel = null,
  inline = false,
  label = null,
  requiredMessage = '',
  placeholder,
  className,
}) => {
  const [type, setType] = useState('password');

  function getRequiredMessage() {
    return requiredMessage || 'Le champs mot de password est obligatoire';
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? getRequiredMessage() : false,
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
          message: 'Le mot de passe doit avoir au moins 8 caractères, avec 1 majuscule, 1 minuscule et 1 chiffre',
        },
      }}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
          <div className={inline ? `${className ? styles[className] : ''}  ${styles['container-input-password']} ${styles.inline}` : `${className ? styles[className] : ''}  ${styles['container-input-password']}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <div className={styles['container-input']}>
              <input
                ref={ref}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.value)}
                className={styles.input}
                placeholder={placeholder}
                type={type}
                autoComplete={'on'}
              />
              <div
                className={styles['container-icon']}
                onClick={() => setType((t) => (t === 'password' ? 'text' : 'password'))}
              >
                { type === 'password'
                  ? <GrFormView size={30}/> : <GrFormViewHide size={30} />
                }
              </div>
            </div>
          </div>

      )}
    />

  );
};

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputPassword;
