import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function CongressRow({
  styles,
  congress,
}) {
  return (
    <Link to={`/congress/${congress._id}?form=0`} className={styles.row}>
      <div className={`${styles.col}`}>
         <p className={`${styles.name}`}>{congress?.name}</p>
      </div>
      <div className={`${styles.col}`}>
         <p>{congress?.city}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{congress?.startDate && format(new Date(congress?.startDate), 'dd/MM/yyyy')}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{congress?.endDate && format(new Date(congress?.endDate), 'dd/MM/yyyy')}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link>
  );
}
