import React from 'react';
import { Link } from 'react-router-dom';

import pen from '../../../assets/images/icons/pen.svg';

export default function ContactRow({
  styles,
  contact,
}) {
  return (
    <Link to={`/contact/${contact._id}`} className={styles.row}
      // style={{ animationDelay: `${0.08 * contact.index}s` }}
    >
      <div className={styles.col}>
        <p>{contact?.lastName && contact?.lastName.toUpperCase()} {contact?.firstName}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop} ${styles.type}`}>
        <p>{contact.qualification}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>
          {contact?.center?.name}
          {/* {contact?.service && <span> / {contact.service}</span>} */}
        </p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        <p>{contact.city}</p>
      </div>
      <div className={`${styles.edit} ${styles.desktop}`}>
        <img src={pen} alt='edit'/>
      </div>
    </Link >
  );
}
