import React, { useState, useRef } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import styles from './BoxPanel.module.scss';

export default function BoxPanel({ label, isNested = false, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  return (
    <div className={`${styles.container} ${isNested ? styles.nested : ''}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {label}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>
        {children}
        </div>
      </div>
    </div>
  );
}
