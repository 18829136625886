import React from 'react';
import { API_URL } from '../../actions';
import { generateKey, isImage } from '../../utils/utils';
import styles from './FilesPreview.module.scss';

export default function FilesPreview({ files, name }) {
  const imagesList = files.filter((f) => isImage(f?.name));
  const filesList = files.filter((f) => !isImage(f?.name));
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.list}`}>
        {imagesList?.map((f, i) => {
          // eslint-disable-next-line prefer-template
          const url = API_URL + '/' + f?.path;
          return <div key={f?._id || generateKey(name, i)} className={styles.image}>
            <a href={url} target='_blank' rel="noreferrer">
              <img src={[API_URL, '/', f?.path].join('')} alt={f?.name} />
            </a>
          </div>;
        })}
        {filesList?.map((f, i) => {
          // eslint-disable-next-line prefer-template
          const url = API_URL + '/' + f?.path;
          return <div key={f?._id || generateKey(name, i)} className={styles.file}>
            <a href={url} target='_blank' rel="noreferrer">
              {f?.name || 'fichier'}
            </a>
          </div>;
        })}
      </div>
    </div>
  );
}
