import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronDownOutline } from 'react-icons/io5';
import { formKeys } from '../../../constants/constants';
import { ErrorField, InputText, Textarea } from '../../../lib/HooksFormFields';
import styles from './SubTypeForm.module.scss';

export default function SubTypeForm({ subtype, subtypes, handleChangeType }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const {
    control,
    setValue,
    watch,
    formState: {
      errors,
    },
  } = useForm({
    defaultValues: {
      _id: subtype._id,
      name: subtype.name,
      clinic: subtype.clinic,
      diagnostic: subtype.diagnostic,
      etiology: subtype.etiology,
      epidemiology: subtype.epidemiology,
      treatment: subtype.treatment,
      other: subtype.other,
      mechanisms: subtype.mechanisms,
    },
  });

  const watchSubtype = watch();

  function handleChangeSubtype(form) {
    const subtypesArray = [...subtypes];
    const index = subtypes.findIndex((t) => t._id === subtype._id);
    subtypesArray[index] = { ...subtypesArray[index], ...form };
    handleChangeType('subtypes', subtypesArray);
  }

  useEffect(() => {
    handleChangeSubtype(watchSubtype);
  }, [
    watchSubtype.name,
    watchSubtype.clinic,
    watchSubtype.clinic.value,
    watchSubtype.diagnostic,
    watchSubtype.diagnostic.value,
    watchSubtype.epidemiology,
    watchSubtype.epidemiology.value,
    watchSubtype.etiology,
    watchSubtype.etiology.value,
    watchSubtype.treatment,
    watchSubtype.treatment.value,
    watchSubtype.other,
    watchSubtype.other.value,
  ]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {subtype.name}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          display: !isOpen ? 'none' : 'block',
        }}
        >
        <div ref={contentRef}>
          <div className={styles['input-list']}>
            <div className={`${styles.block}`}>
              <label>Nom du sous-type</label>
              <div className={styles.value}>
                <InputText
                  name='name'
                  control={control}
                  className={'primary'}
                  rules={{ required: 'Le nom est requis' }}
                  placeholder={'Nom'}
                />
                <ErrorField message={errors?.name?.message} />
              </div>
            </div>
          </div>
          <div className={styles['input-list']}>
            <label>Type de champs activés</label>
            <div className={`${styles['tags-list']}`}>
              {formKeys.map((k) => (
                  <button
                  type='button'
                  onClick={() => setValue(k.value, {
                    ...watchSubtype[k.value],
                    isDisplayed: !watchSubtype[k.value].isDisplayed,
                  })}
                  className={watchSubtype[k.value].isDisplayed ? styles.selected : ''}
                  key={k.value}
                  >
                  {k.label}
                </button>
              ))}
            </div>
          </div>
          <div className={styles['input-list']}>
            {formKeys.filter((k) => watchSubtype[k.value].isDisplayed).map((k) => (
                <div key={k.label} className={`${styles.block}`}>
                <label>{k.label}</label>
                <div className={styles.value}>
                  <Textarea
                    name={`${k.value}.value`}
                    control={control}
                    className={'primary'}
                    placeholder={k.label}
                  />
                </div>
              </div>
            ))}
            </div>

        </div>
      </div>
    </div>
  );
}
